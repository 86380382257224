.sb-posts-list-item {
  display: block;
  margin-bottom: var(--spacing-16);

  &:last-child {
    margin-bottom: var(--spacing-16);
  }

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: var(--spacing-base);
  }

  @media (min-width: $sb-breakpoint-xl) {
    margin-bottom: var(--spacing-3halfx);
  }
}

.sb-posts-list-item__title {
  @include responsive-text-size(var(--spacing-20), var(--spacing-26));
  color: var(--color--black);
  font-weight: var(--font-weight--medium);
  line-height: 1.3;
  margin: 0;
  margin-bottom: var(--spacing-9);
  text-decoration: none;
  transition: color $sb-base-transition;
}

.sb-posts-list-item__meta {
  font-size: var(--font-size--14);
  letter-spacing: 0.3em;
  text-transform: uppercase;
}

.sb-posts-list-item__excerpt {
  color: var(--color--gray-34);
  font-weight: var(--font-weight--light);
  line-height: 1.75;
}

.sb-posts-list-item__link {
  display: block;
  overflow: hidden;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    .sb-posts-list-item__title {
      color: var(--color--link);
    }
  }
}
