.pull-quote-image {

  @media (max-width: ($sb-breakpoint-lg - 1px)) {
    padding: 0;
  }

  @media (min-width: $sb-breakpoint-lg) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  &.media-position-left {
  
    @media (min-width: $sb-breakpoint-lg) {
      flex-direction: row;
    }
  }
}

.pull-quote-image__content {
  background: var(--color--starbucks-house-green);
  margin-top: calc(-1 * var(--spacing-24));
  margin-left: var(--spacing-24);
  margin-right: var(--spacing-24);
  padding: var(--spacing-24);
  position: relative;

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: var(--spacing-54);
    width: 39.5%;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.pull-quote-image__media {
  width: 100%;

  @media (min-width: $sb-breakpoint-lg) {
    width: calc(60.5% + var(--spacing-40));
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.pull-quote-image__content > * + * {
  margin-top: var(--spacing-24);
}

.pull-quote-image__content p {
  color: var(--color--white);
  font-size: var(--font-size--22);
  font-weight: normal;
  margin-bottom: 0;
}

.pull-quote-image__media img {
  display: block;
  margin: 0;
  width: 100%;
}

.pull-quote-image.media-position-left .pull-quote-image__media {

  @media (min-width: $sb-breakpoint-lg) {
    margin-right: calc(-1 * var(--spacing-40));
  }
}

.pull-quote-image.media-position-right .pull-quote-image__media {

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: calc(-1 * var(--spacing-40));
  }
}
