// Variables
$banner-enter-animation: postEnterAnim 500ms 100ms
  cubic-bezier(0.22, 0.89, 0.57, 1) forwards; // from legacy site

.banner {
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    padding-left: 13%;
    padding-right: 13%;
  }
}

// to do: restore the animation when we have a better solution
.banner__main {
  background: var(--color--white);
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.18);
  color: var(--color--black);
  // opacity: 0;
  padding: var(--spacing-60) var(--spacing-20) var(--spacing-70);
  position: relative;
  // transform: translateY(30px);
  will-change: transform;
  z-index: 10;

  .-viewed & {
    animation: $banner-enter-animation;
  }

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-60) var(--spacing-40) var(--spacing-70);
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-100) var(--spacing-60) var(--spacing-130);
  }

  P {
    font-weight: var(--font-weight--regular);
  }

  & > *:last-of-type {
    margin-bottom: 0;
  }
}

.banner__heading {
  color: var(--color--starbucks-accent-green);
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--regular);
  margin: 0;

  @media (min-width: $sb-breakpoint-lg) {
    font-size: 4.125rem;
    line-height: 7.312rem;
  }
}

.lang-ja {
  .banner__heading {
    font-family: var(--font-family--japan);
  }
}

.banner__content {
  font-weight: var(--font-weight--medium);
  margin: var(--spacing-12) 0 var(--spacing-50);

  a {
    text-decoration: underline;
  }

  p:last-of-type {
    margin: 0;
  }
}

.banner__cta {
  margin: 0;

  a {
    @include sb-button(large, white);
    padding: var(--spacing-8) var(--spacing-27);

    @media (min-width: $sb-breakpoint-md) {
      padding: var(--spacing-13) var(--spacing-57);
    }
  }
}

// to do: remove this when we have a better solution
.banner__cta > a > br {
  display: none;
}

.banner__image {
  bottom: var(--spacing-40);
  left: 0;
  position: absolute;
  right: 0;
  top: var(--spacing-40);
  z-index: 0;

  @media (min-width: $sb-breakpoint-md) {
    bottom: var(--spacing-60);
    top: var(--spacing-60);
  }

  img {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@keyframes postEnterAnim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
