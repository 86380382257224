body {
  font-family: var(--font-family--sodo);
}

body.lang-ja {
  font-family: var(--font-family--japan);
}

p {
  margin-bottom: var(--font-size--16);
  margin-top: 0;
  line-height: 1.5;
}

ol,
ul,
dl {
  margin-bottom: var(--font-size--16);
  margin-top: 0;
}

a {
  color: var(--color--link);
  text-decoration: none;
  transition: color $sb-base-transition;

  &:hover,
  &:active,
  &:focus {
    color: var(--color--link-hover);
    text-decoration: underline;
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }

  &[aria-disabled="true"] {
    pointer-events: none;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}

code {
  font-family: var(--font-family--mono);
}
