.small-caps-cta {
  display: inline-block;
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--semibold);
  letter-spacing: var(--spacing-4);
  line-height: 1;
  text-transform: uppercase;
}

.small-caps-cta svg {
  margin-left: var(--spacing-8);
}