.wp-block-columns {

  @media (min-width: $sb-breakpoint-lg) {
    column-gap: var(--spacing-20);
    display: flex;
    justify-content: space-between;
  }

}

.wp-block-column {

  @media (min-width: $sb-breakpoint-lg) {
    flex: 1 1 0;
    min-width: 0;
  }
}

.wp-block-columns.are-vertically-aligned-bottom {

  @media (min-width: $sb-breakpoint-lg) {
    align-items: flex-end;
  }
}

.wp-block-columns.are-vertically-aligned-center {

  @media (min-width: $sb-breakpoint-lg) {
    align-items: center;
  }
}