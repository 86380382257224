.leadership-single__content {
  margin-block-start: var(--spacing-64);
  padding-inline: var(--site-gutter);
}

.leadership-single__view-all {
  margin-top: var(--spacing-64);
}

.lead-hero {
  padding-inline: var(--site-gutter);
  background: linear-gradient(to bottom, var(--color--starbucks-house-green) 0px, var(--color--starbucks-house-green) 224px, var(--color--white) 224px);
  padding-block-start: var(--spacing-64);

  @media (min-width: $sb-breakpoint-md) {
    background: var(--color--starbucks-house-green);
    background: linear-gradient(to top, var(--color--white) 0px, var(--color--white) 64px, var(--color--starbucks-house-green) 64px);
  }
}

.lead-hero__inner {
  margin-inline: auto;
  max-width: var(--w-content-wide);
  display: grid;
  grid-row-gap: var(--spacing-24);
  grid-template-columns: 100%;
  align-items: center;
  
  @media (min-width: $sb-breakpoint-md) {
    grid-column-gap: var(--spacing-40);
    grid-template-columns: 320px 1fr;
  }
}

.lead-hero__media {
  width: 320px;
  max-width: 100%;
  margin-inline: auto;
}

.lead-hero__copy {

  @media (min-width: $sb-breakpoint-md) {
    padding-block-end: var(--spacing-80);
  }
}

.lead-hero__copy > * {
  margin: 0;
}

.lead-hero__copy > * + * {
  margin-block-start: var(--spacing-16);
}

.lead-hero__name {
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--bold);
  text-align: center;
  
  @media (min-width: $sb-breakpoint-md) {
    color: var(--color--white);
    margin-block-start: 0;
    text-align: left;
  }
}

.lead-hero__title {
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    color: var(--color--white);
    text-align: left;
  }
}

.lead-hero__meta {
  text-align: center;
  
  @media (min-width: $sb-breakpoint-md) {
    text-align: left;
  }
}

.lead-hero__meta :is(a, button) {
  color: var(--color--gray-49);

  @media (min-width: $sb-breakpoint-md) {
    color: var(--color--white);
  }
}