.animated-icons {
  margin: 0 calc(50% - 50vw) var(--spacing-40);
  padding: var(--spacing-20) var(--spacing-10) var(--spacing-40);
  position: relative;
  width: 100vw;

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom:  var(--spacing-130);
    padding: var(--spacing-130) var(--spacing-10);
  }

  .split-text {
    display: inline-block;
    line-height: 1.2;
    overflow: hidden;
  }

  .split-text__inner {
    display: inline-block;
    position: relative;
  }
}

.animated-icons__bg {
  background: var(--color--light-peach);
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    width: 65%;
  }
}

.animated-icons__outer {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 var(--spacing-10);
}

.animated-icons__wrap {

  @media (min-width: $sb-breakpoint-md) {
    display: flex;
    padding: 0 var(--spacing-7);
    position: relative;
    z-index: 1;
  }
}

.animated-icons__content {
  flex-basis: 37%;
  flex-shrink: 0;

  @media (min-width: $sb-breakpoint-md) {
    margin-right: var(--spacing-100);
    padding: var(--spacing-30) 0 0 0;
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-30) 0 0 var(--spacing-100);
  }
}

.animated-icons__title {

  @include responsive-text-size(var(--spacing-24), var(--spacing-30));
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--regular);
  line-height: 1.38;
  margin-bottom: var(--spacing-20);
  text-align: left;
}

.animated-icons__list {
  flex-basis: 50%;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  @media (min-width: $sb-breakpoint-md) {
    padding-left: var(--spacing-40);
  }
}

.animated-icons__item {
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-20);

  &:last-child {
    margin-bottom: 0;
  }
}

.animated-icons__icon-wrap {
  flex-basis: 80px;
  flex-shrink: 0;
  margin-right: var(--spacing-20);
}

.animated-icons__icon-text {
  max-width: 350px;
}
