.multimedia-item-featured {
  align-items: center;
  background: var(--color--gray-97);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-bottom: var(--spacing-base);
  padding: var(--spacing-10);
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: var(--spacing-base);
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-1halfx) var(--spacing-50);
  }

  .sb-button--download {
    width: 100%;

    @media (min-width: $sb-breakpoint-md) {
      min-width: 12.5rem;
      width: auto;
    }

    @media (min-width: $sb-breakpoint-lg) {
      margin: 0;
    }
  }
}

.multimedia-item-featured--single {
  padding: var(--spacing-24);
  position: relative;

  .multimedia-item-featured__image-contain {
    margin-top: var(--spacing-16);
    margin-bottom: 0;
    order: 2;

    @media (min-width: $sb-breakpoint-md) {
      flex: 1 0 40%;
      margin-top: 0;
      max-width: none;
      order: 0;
      width: 40%;
    }

    .single--image-container {
      width: 100%;

      > img {
        width: 100%;
      }
    }

    img {
      max-width: 100%;
    }

    video {
      width: 100%;
      margin: 0;
    }

    .wistia_embed {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .multimedia-item-featured__details {
    @media (min-width: $sb-breakpoint-md) {
      flex: 1 0 calc(60% - 4.625rem);
      max-width: 60%;
      order: 2;
      overflow: hidden;
      padding-left: 4.625rem;
      width: calc(60% - 4.625rem);
    }

    & > * {
      margin-bottom: var(--spacing-14);
    }

    .sb-button--download {
      margin-bottom: 0;
      margin-top: var(--spacing-20);
    }
  }

  .multimedia-item-featured__title {
    @include responsive-text-size(var(--spacing-18), var(--spacing-28));
    padding: 0;
  }

  .description {
    @include responsive-text-size(var(--spacing-16), var(--spacing-22));
    font-weight: var(--font-weight--light);
  }
}

.image-resolution {
  color: var(--color--gray-25);
  font-size: var(--font-size--12);
  font-style: italic;
  font-weight: var(--font-weight--light);
  margin-bottom: var(--spacing-10);
}

.multimedia-item-featured--single {
  .description {
    p {
      color: var(--color--gray-25);
      font-size: var(--font-size--16);
      line-height: 1.5;
    }
  }

  .image-resolution {
    margin-bottom: var(--spacing-5);
  }
}

.multimedia-item-featured__title {
  @include responsive-text-size(var(--spacing-18), var(--spacing-20));
  color: var(--color--black);
  font-weight: var(--font-weight--regular);
  line-height: 1.33;
  margin-bottom: var(--spacing-16);
  margin-top: 0;
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    margin: 0;
    padding: var(--spacing-base);
  }

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-base) var(--spacing-3halfx) var(--spacing-base) var(--spacing-2x);
  }
}

.multimedia-item-featured__image-contain {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: var(--spacing-16);
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    flex: 1 0 9.375rem;
    margin: 0;
    max-width: 9.375rem;
    width: 9.375rem;
  }

  img {
    display: block;
    margin: 0;
    max-width: 9.375rem;
  }
}

.multimedia-item-featured__image-contain--video {
  display: block;
}
