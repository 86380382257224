.wp-block-separator {
  background: var(--color--black);
  border: 0;
  height: 6px;
  width: var(--spacing-32);
}

.wp-block-separator.is-style-wide {
  background: var(--color--starbucks-accent-green);
  height: 4px;
  width: auto
}

.wp-block-separator.is-style-pagebreak {
  background-color: var(--color--white);
  height: var(--spacing-30);
  max-width: none;
  width: auto;
}

.wp-block-separator.is-style-thingrey {
  background: var(--color--gray-74);
  height: 2px;
  width: auto
}

// To do: remove this style. This seems to be out of use.
.wp-block-separator.is-style-narrow-green {
  background: var(--color--starbucks-accent-green);
  height: 3px;
  margin: var(--spacing-28) auto;
  width: var(--spacing-48);
}

.wp-block-separator.is-style-dots {
  background: none;
  border-top: 6px dotted var(--color--starbucks-accent-green);
  height: 0px;
  width: auto;
}
