.post-grid {
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: $sb-breakpoint-sm) {
    flex-flow: row wrap;
  }

  .archive-page & {
    margin-left: calc(-1 * var(--spacing-20));
    margin-right: calc(-1 * var(--spacing-20));
    position: relative;
    width: calc(100% + #{var(--spacing-40)});

    @media (min-width: $sb-breakpoint-sm) {
      width: calc(100% + #{var(--spacing-40)});
    }
  }

  &.is-style-1-col-page-header {
    background: var(--color--gray-97);
    margin-top: calc(-1 * var(--spacing-40));
    position: relative;
    padding-left: 0;
    padding-right: 0;

    & > .sb-card-grid-item {
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      max-width: $sb-article-content-max-width;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.post-grid--originals {
  flex-direction: column;
  justify-content: center;

  @media (min-width: $sb-breakpoint-sm) {
    flex-flow: row wrap;
    justify-content: start;
  }
}

.post-grid--gutter-sm {
  margin-left: calc(-1 * var(--spacing-6));
  margin-right: calc(-1 * var(--spacing-6));
  width: calc(100% + #{var(--spacing-12)});
}
