@use "sass:math";

// Styles derived from legacy css
// See .hero-video-wrap on https://starbuckschannel.com/09-homes-for-everyone/

// Variables
$video-modal-transition: opacity 400ms cubic-bezier(0.22, 0.89, 0.57, 1);
$video-modal-height-md: calc(100% - var(--spacing-100));
$video-modal-width-md: calc(100% - 160px);
$video-modal-toggle-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
$video-modal-toggle-size: 3.438rem;
$video-modal-toggle-size-l: 5.625rem;
$video-modal-toggle-transition: background-color 400ms
    cubic-bezier(0.8, 0, 0.4, 1),
  transform 750ms cubic-bezier(0.6, 0, 0.6, 1);
$video-modal-toggle-triangle-transition: transform 550ms
  cubic-bezier(0.8, 0, 0.4, 1);

.video-modal {
  height: 100%;
  left: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.video-modal__video-wrapper {
  background: var(--color--black);
  display: block;
  height: 100%;
  margin: 0 auto;
  opacity: 0;
  transition: $video-modal-transition;
  width: 100%;
  .video-modal--open & {
    opacity: 1;
  }

  @media (min-width: $sb-breakpoint-md) {
    margin: auto;
  }
}

.video-modal__video-inner {
  display: block;
  height: 100%;
  margin: 0 auto;
  max-height: 100%;
  padding-top: 0;
  position: relative;
  visibility: hidden;
  width: auto;

  .video-modal--open & {
    visibility: visible;
  }
}

.video-modal__video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  iframe {
    background-color: var(--color--black);
    height: 100%;
    max-width: none;
    width: 100%;
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--starbucks-green);
  }
}

// Toggle
.video-modal__toggle {
  background-color: var(--color--starbucks-green);
  border: $sb-border-width-focus inset transparent;
  border-radius: 50%;
  box-shadow: $video-modal-toggle-box-shadow;
  color: var(--color--white);
  cursor: pointer;
  height: $video-modal-toggle-size;
  left: 50%;
  line-height: 1;
  margin-left: calc(#{$video-modal-toggle-size} * -0.5);
  margin-top: math.div(-$video-modal-toggle-size, 2);
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  transition: $video-modal-toggle-transition;
  width: $video-modal-toggle-size;
  z-index: 5;

  .video-modal--open & {
    display: none;
  }

  &:hover,
  &:active {
    background-color: var(--color--white);
    transform: scale(1.05);
  }

  &:focus {
    border: $sb-border-width-focus inset var(--color--gray-25);
    transform: scale(1.05);
  }

  @media (min-width: $sb-breakpoint-lg) {
    height: $video-modal-toggle-size-l;
    margin-left: calc(#{$video-modal-toggle-size-l} * -0.5);
    margin-top: math.div(-$video-modal-toggle-size-l, 2);
    width: $video-modal-toggle-size-l;
  }
}

.video-modal__toggle-triangle {
  display: block;
  height: inherit;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(0);
  transition: $video-modal-toggle-triangle-transition;
  width: inherit;

  will-change: transform;

  &:after {
    border-bottom: var(--spacing-10) solid transparent;
    border-left: var(--spacing-18) solid var(--color--white);
    border-top: var(--spacing-10) solid transparent;
    content: '';
    display: inline-block;
    height: 0;
    left: calc(54% - 1px);
    position: absolute;
    top: calc(50% - 1px);
    transform: translate(-50%, -50%);
    width: 0;
  }

  @media (min-width: $sb-breakpoint-lg) {
    &:after {
      border-bottom: var(--spacing-14) solid transparent;
      border-left: var(--spacing-24) solid var(--color--white);
      border-top: var(--spacing-14) solid transparent;
    }
  }
}

.video-modal__toggle-triangle--init {
  transform: translateX(0);

  .video-modal__toggle:hover &,
  .video-modal__toggle:active & {
    transform: translateX(100%);
  }
}

.video-modal__toggle-triangle--hovered {
  transform: translateX(-100%);

  .video-modal__toggle:hover &,
  .video-modal__toggle:active & {
    transform: translateX(0);
  }

  &:after {
    border-left: var(--spacing-20) solid var(--color--starbucks-green);
  }

  @media (min-width: $sb-breakpoint-lg) {
    &:after {
      border-left: var(--spacing-24) solid var(--color--starbucks-green);
    }
  }
}
