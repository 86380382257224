.article-sticky-social {
  opacity: 0;
  pointer-events: none;

  &.-viewed {
    opacity: 1;
    pointer-events: auto;
    position: relative;
    z-index: 15;
  }
}
