// variables
$sb-legal-nav-letter-spacing: 0.1818em;
$sb-legal-nav-link-padding: var(--spacing-10) var(--spacing-16);
$sb-signup-max-width-footer: 28.125rem;

.sb-legal-nav {
  display: block;
  margin: 0 auto;

  .sb-site-header__container--desktop & {
    @media (min-width: $sb-breakpoint-md) {
      display: none;
    }
  }
}

.sb-legal-nav__ul {
  display: flex;
  flex-flow: row wrap;
  font-size: 0.688rem;
  justify-content: center;
  letter-spacing: $sb-legal-nav-letter-spacing;
  list-style: none;
  margin: var(--spacing-1halfx) 0 var(--spacing-16);
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-md) {
    font-size: 0.812rem;
  }

  .site-navigation__drawer & {
    margin-top: 0;
  }
}

.sb-legal-nav__li {
  display: block;
  margin-left: var(--spacing-10);
  margin-right: var(--spacing-10);
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    &:after {
      display: none;
    }
  }

  &:after {
    background: currentColor;
    content: '';
    display: block;
    height: 1.562rem;
    position: absolute;
    right: calc(-1 * var(--spacing-10));
    top: var(--spacing-6);
    width: 1px;
  }
}

.sb-legal-nav__link,
.truste_cursor_pointer {
  display: block;
  padding: $sb-legal-nav-link-padding;
}

.sb-legal-nav__ul--footer {
  .sb-legal-nav__ul {
    @media (min-width: $sb-breakpoint-md) {
      font-size: var(--font-size--14);
      margin-bottom: var(--spacing-base);
    }
  }
}
.sb-legal-nav__ul--navigation {
  .sb-legal-nav__li {
    &:after {
      background: var(--color--gray-89);
    }
  }

  .sb-legal-nav__link {
    color: var(--color--black);
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
