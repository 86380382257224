.social-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $sb-breakpoint-xl) {
    flex-direction: row;
  }
}

.social-block .social-nav {
  margin: var(--spacing-halfx) var(--spacing-2halfx);
  width: auto;
}

.social-block .social-nav__li {
  margin: var(--spacing-halfx);
}

.social-block .social-nav__link {
  color: var(--color--black);

  &:hover,
  &:focus {
    color: var(--color--starbucks-house-green);
  }
}

.social-block__title {
  @include responsive-text-size(var(--spacing-18), var(--spacing-22));
  color: var(--color--black);
  font-weight: var(--font-weight--regular);
  letter-spacing: 0.18em;
  line-height: 1.68;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}

.social-block__subtitle {
  color: var(--color--black);
  font-size: 0.938rem;
  font-weight: var(--font-weight--medium);
  letter-spacing: 0.28em;
  line-height: 1.68;
  margin-bottom: var(--spacing-2halfx);
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}
