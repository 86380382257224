// Styles taken from https://10up.github.io/wp-component-library/component/tabs/index.html
// Variables
$tab-list-border-offset: var(--spacing-6);

.sb-tab-list-contain {
  border-bottom: 1px solid var(--color--black);
  border-top: 1px solid var(--color--black);
  margin: 0;
  margin-left: calc(-1 * var(--spacing-10));
  margin-right: calc(-1 * var(--spacing-10));
  overflow: auto;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: calc(100% + #{var(--spacing-base)});

  @media (min-width: $sb-breakpoint-md) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.sb-tab-list {
  @include responsive-text-size(var(--spacing-14), var(--spacing-15));
  display: block;
  letter-spacing: 0.28em;
  line-height: 1.6666;
  list-style: none;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-lg) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
}

.sb-tab-item {
  display: inline-block;

  > .sb-tab-item-link {
    color: var(--color--black);
    display: block;
    padding: var(--spacing-10);
    text-decoration: none;

    span {
      display: block;
      position: relative;

      &:after {
        background-color: transparent;
        bottom: -5px;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transition: background-color $sb-base-transition;
        width: 100%;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--color--link);
      outline: none;

      span {
        &:after {
          background-color: var(--color--link);
        }
      }
    }

    @media (min-width: $sb-breakpoint-md) {
      padding: var(--spacing-base);
    }
  }

  &.is-active {
    .sb-tab-item-link {
      color: var(--color--link);
      span {
        &:after {
          background-color: var(--color--link);
        }
      }
    }
  }

  @media (min-width: $sb-breakpoint-lg) {
    display: block;
  }
}

.sb-tab-group {
  display: block;
}

.sb-tab-content {
  display: block;
  padding: 20px 0;
  width: 100%;

  &[aria-hidden='true'] {
    left: -999em;
    position: absolute;
    top: -999em;
  }

  &.is-active {
    position: static;
  }
}

// Animation
@mixin cardsAnimateDelay($cards: 9) {
  @for $i from 1 through $cards {
    &:nth-child(#{$i}) {
      animation-delay: #{($i - 1) * 200}ms;
    }
  }
}

.sb-tabs {
  .sb-card-grid-item {
    opacity: 0;
    transform: translateY(30px);
  }
}

.sb-tabs.-viewed {
  .sb-card-grid-item {
    @include cardsAnimateDelay();
    animation: postEnterAnim 500ms forwards;
  }
}

@keyframes postEnterAnim {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
