.sbx-leadership-grid .wp-block-post-template {
  display: grid;
  gap: var(--spacing-48);
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;
  margin-inline: auto;
  max-width: var(--w-content-max);

  @media (min-width: 460px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-md) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-lg) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.sbx-leadership-grid .wp-block-post {
  max-width: 160px;
  position: relative;

  &:hover .sbx-leadership-card__image {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  }
}

.sbx-leadership-card__image {
  aspect-ratio: 1;
  border-radius: 50%;
  margin: 0 auto;
  width: 120px;
}

.sbx-leadership-card__image img {
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  transition: box-shadow 0.2s ease-out;
  width: 120px;
}

h2.sbx-leadership-card__name {
  color: var(--color--black);
  font-size: var(--font-size--18);
  font-style: normal;
  margin: var(--spacing-16) 0 0;
  text-align: center;

  a {
    color: var(--color--black);
    background-color: transparent;
    border-bottom: none;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: transparent;
      border-bottom: none;
      text-decoration: underline;
    }

    &::after {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.sbx-leadership-card__position {
  font-size: var(--font-size--12);
  margin-block: var(--spacing-8) 0;
  text-align: center;
}

.lang-ja :is(.sbx-leadership-card__name, .sbx-leadership-card__name a, .sbx-leadership-card__position) {
  font-family: var(--font-family--japan);
}
