.sb-select-control__input-wrap {
  position: relative;
}

.sb-select-control__label {
  display: inline-block;
  margin-bottom: var(--spacing-8);
  font-weight: var(--font-weight--regular);
}

.sb-select-control__input {
  appearance: none;
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid var(--color--starbucks-green);
  color: var(--color--starbucks-green);
  cursor: pointer;
  max-width: 100%;
  padding: var(--spacing-16) var(--spacing-40) var(--spacing-16) var(--spacing-24);
  position: relative;
  width: 100%;
}

.sb-select-control__caret-icon {
  color: var(--color--starbucks-green);
  position: absolute;
  bottom: 50%;
  right: var(--spacing-16);
  transform: translateY(50%);
}


.sb-select-control__caret-icon,
.sb-select-control__caret-icon svg {
  display: block;
  height: var(--spacing-12);
  width: var(--spacing-16);
}