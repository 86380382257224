.sb-search-filters-legacy {
  display: flex;
  justify-content: flex-end;
}

.sb-search-filters-legacy__filters {
  display: flex;
  align-items: flex-end;
}

.sb-search-filters-legacy__select {
  display: flex;
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;
  padding-left: 0;
  text-align: right;
}

.sb-search-filters-legacy__item {
  font-size: 12px;
  padding: 0 var(--spacing-20) 0;
  text-transform: uppercase;
  white-space: nowrap;
}

.sb-search-filters-legacy__item:first-of-type {
  line-height: 1.3;
  padding-left: 0;
}

.sb-search-filters-legacy__item:last-of-type {
  padding-right: 0;
}

.sb-search-filters-legacy__label {
  color: var(--color--gray-49);
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--regular);
  letter-spacing: 0;
  text-transform: capitalize;
}

.sb-search-filters-legacy__item button {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  cursor: pointer;
  font-size: 110%;
  text-transform: capitalize;
}

.sb-search-filters-legacy__button {
  font-size: 0.625rem;
  padding: 0;
  padding-bottom: var(--spacing-15);
  &:disabled {
    cursor: default;
    color: var(--color--starbucks-green);
    border-bottom: 2px solid var(--color--starbucks-green);
  }
}