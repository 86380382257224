.not-found__title {
  font-size: 2rem;
  margin-top: var(--spacing-2halfx);
  font-weight: var(--font-weight--semibold);
  margin-bottom: var(--spacing-2x);
}

.not-found__h2 {
  text-align: left;
  color: var(--color--text);
  font-size: 1.5rem;
  font-weight: var(--font-weight--semibold);
  margin-bottom: var(--spacing-16);
}
