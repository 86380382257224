.wp-block-group {
  position: relative;
}

.wp-block-group.alignfull,
.wp-block-group.alignwide {
  padding-inline: var(--site-gutter);
}

.wp-block-group.has-background-img {
  background-image: var(--background-image);
  background-position: top center;
  background-size: cover;
}

.wp-block-group__background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.wp-block-group__background-video video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

.wp-block-group__inner-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.wp-block-group.is-layout-unconstrained .wp-block-group__inner-container.article__content > *,
.wp-block-group.has-custom-content-size .wp-block-group__inner-container.article__content > * {
  max-width: none;
}

// To do: remobe when we add support for padding controls.
.wp-block-group.has-block-padding {
  padding-block: var(--spacing-40);

  @media (min-width: $sb-breakpoint-md) {
    padding-block: var(--spacing-80);
  }

  > *:first-of-type,
  .wp-block-group__inner-container > *:first-of-type {
    margin-top: 0 !important;
  }
  
  > *:last-of-type,
  .wp-block-group__inner-container > *:last-of-type {
    margin-bottom: 0 !important;
  }
}

.wp-block-group.has-narrow-block-padding {
  padding-block: var(--spacing-20);

  > *:first-of-type,
  .wp-block-group__inner-container > *:first-of-type {
    margin-top: 0 !important;
  }
  
  > *:last-of-type,
  .wp-block-group__inner-container > *:last-of-type {
    margin-bottom: 0 !important;
  }
}

// Legacy styles. To do: remove when we know they aren't used
.wp-block-group {

  &.is-style-hasoffset {
    &::before {
      background-color: var(--color--gray-97);
      background-image: var(--background-image);
      background-position: top center;
      background-size: cover;
      content: '';
      height: 100%;
      left: calc(50% - 50vw);
      position: absolute;
      right: calc(50% - 50vw);
      top: 0;
    }

    padding-bottom: 0;

    &::before {
      height: 90%;
    }
  }

  &.is-style-green-bg {
    &::before {
      background-color: var(--color--gray-97);
      background-image: var(--background-image);
      background-position: top center;
      background-size: cover;
      content: '';
      height: 100%;
      left: calc(50% - 50vw);
      position: absolute;
      right: calc(50% - 50vw);
      top: 0;
    }

    color: var(--color--white);

    h2,
    h3 {
      @include responsive-text-size(var(--spacing-22), var(--spacing-28));

      color: var(--color--white);
      font-weight: var(--font-weight--light);
      margin-bottom: var(--spacing-14);
    }

    p {
      @include responsive-text-size(var(--spacing-14), var(--spacing-16));
      line-height: var(--spacing-20);
    }

    a {
      color: var(--color--white);
      text-decoration: underline;

      &:hover,
      &:focus {
        color: var(--color--starbucks-light-green);
      }
    }

    &::before {
      background-color: var(--color--starbucks-house-green);
    }
  }

  &.is-style-light-green-bg {
    &::before {
      background-color: var(--color--gray-97);
      background-image: var(--background-image);
      background-position: top center;
      background-size: cover;
      content: '';
      height: 100%;
      left: calc(50% - 50vw);
      position: absolute;
      right: calc(50% - 50vw);
      top: 0;
    }

    &::before {
      background-color: var(--color--starbucks-light-green);
    }
  }

  &.is-style-green-pattern {

    &::before {
      background-color: var(--color--gray-97);
      background-image: var(--background-image);
      background-position: top center;
      background-size: cover;
      content: '';
      height: 100%;
      left: calc(50% - 50vw);
      position: absolute;
      right: calc(50% - 50vw);
      top: 0;
    }

    background: none;
    margin-bottom: 0;
    padding: var(--spacing-40) 0;

    @media (min-width: $sb-breakpoint-md) {
      padding: var(--spacing-100) 0;
    }

    &::before {
      background: var(--color--starbucks-light-green) url("/images/em-background-green.jpeg");
      background-position: top center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}