.sb-photo-carousel__item {
  margin-right: var(--spacing-50);
}

.sb-photo-carousel-item {
  width: 100%;

  @media (min-width: $sb-breakpoint-lg) {
    display: flex;
    align-items: flex-end;
    column-gap: var(--spacing-36);
  }
}

.sb-photo-carousel-item__media {

  @media (min-width: $sb-breakpoint-lg) {
    order: 1;
    width: 65%;
    flex-shrink: 0;
  }
}

.sb-photo-carousel-item__media img {
  display: block;
  margin: 0;
  // aspect-ratio: 3 / 4;
  object-fit: cover;

  @media (min-width: $sb-breakpoint-lg) {
    // aspect-ratio: 4 / 3;
  }
}

.sb-photo-carousel-item__content {
  margin-top: var(--spacing-24);
  color: var(--color--starbucks-house-green);

  @media (min-width: $sb-breakpoint-lg) {
    flex-grow: 1;
    margin-top: 0;
    order: 0;
  }
}

.sb-photo-carousel-item__content > * + * {
  margin-top: var(--spacing-32);
}

.sb-photo-carousel-item__content > *:last-child {
  margin-bottom: 0;
}

.sb-photo-carousel-item__nav {
  list-style: none !important;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: var(--spacing-16);
}

.sb-photo-carousel-item__nav-item {
  width: var(--spacing-40);
  height: var(--spacing-40);

  &::before {
    display: none !important;
  }
}

.sb-photo-carousel-item__nav-button {
  align-items: center;
  appearance: none;
  background-color: var(--color--starbucks-light-green);
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  display: flex;
  height: var(--spacing-42);
  justify-content: center;
  padding: 0;
  width: var(--spacing-42);
}

.sb-photo-carousel-item__nav-button svg {
  display: block;
  width: var(--spacing-20);
  height: var(--spacing-20);
}

.sb-photo-carousel-item__nav-button--prev svg {
  transform: rotate(180deg);
}

.sb-photo-carousel-item__title {
  margin-bottom: 0;
  font-size: var(--font-size--22);
  font-weight: var(--font-weight--medium);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color--starbucks-house-green);
}

.sb-photo-carousel-item__excerpt {
  font-size: var(--font-size--16);
}
