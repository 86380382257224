.is-layout-flex {
  display: flex;
}

.is-content-justification-left {
  justify-content: flex-start;
}

.is-content-justification-center {
  justify-content: center;
}

.is-content-justification-right {
  justify-content: flex-end;
}

.is-content-justification-space-between {
  justify-content: space-between;
}

.is-vertically-aligned-center {
  align-items: center;
}

.is-vertically-aligned-top {
  align-items: flex-start;
}

.is-vertically-aligned-bottom {
  align-items: flex-end;
}
