:where(h1, h2, h3, h4, h5, h6) {
  color: var(--color--heading);
  font-family: var(--font-family--sodo);
  font-weight: var(--font-weight--medium);
  letter-spacing: -0.14px;
  line-height: 1.1;
  margin-bottom: var(--spacing-halfx);
  margin-top: var(--spacing-4halfx);
}

.lang-ja {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family--japan);
  }
}

h1,
.sb-h1,
.is-style-h1 {
  @include responsive-text-size(var(--spacing-38), var(--spacing-48));
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--semibold);
  margin-bottom: 0.775em;
  margin-top: 0.5em;
}

.lang-ja {
  h1,
  .sb-h1,
  .is-style-h1 {
    font-family: var(--font-family--japan);
  }
}

h2,
.sb-h2,
.is-style-h2 {
  @include responsive-text-size(var(--font-size--42), var(--font-size--52));
  line-height: 1.18;
  margin-bottom: 1.4em;
  margin-top: 1em;
  text-align: center;
}

h3,
.sb-h3,
.is-style-h3 {
  @include responsive-text-size(var(--font-size--36), var(--font-size--40));
  font-weight: var(--font-weight--semibold);
  line-height: 1.22;
}

h4,
.sb-h4,
.is-style-h4 {
  font-size: var(--font-size--28);
  font-weight: var(--font-weight--semibold);
  line-height: 1.33;
}

h5,
.sb-h5,
.is-style-h5 {
  font-size: var(--font-size--22);
  font-weight: var(--font-weight--regular);
  line-height: 1.69;
}

h6,
.sb-h6,
.is-style-h6 {
  font-size: var(--font-size--16);
  font-weight: var(--font-weight--regular);
}

// Display Headings
.sb-display-1 {
  @include responsive-text-size(var(--font-size--52), var(--font-size--48));
  font-weight: var(--font-weight--semibold);
  letter-spacing: 0.075em;
  line-height: 1.32;
  margin-bottom: var(--spacing-base);
  margin-top: var(--spacing-base);
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-sm) {
    margin-bottom: var(--spacing-2halfx);
  }
}

.sb-display-2 {
  font-size: var(--font-size--28);
  font-weight: var(--font-weight--semibold);
  letter-spacing: var(--spacing-10);
  line-height: 1.32;
  margin-bottom: var(--spacing-2halfx);
  margin-top: var(--spacing-5x);
  padding-bottom: var(--spacing-14);
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-sm) {
    font-size: var(--font-size--42);
  }

  span {
    display: inline-block;
    padding-bottom: var(--spacing-10);
    position: relative;

    @media (min-width: $sb-breakpoint-sm) {
      padding-left: var(--spacing-10);
    }

    &:after {
      border-bottom: 1px solid var(--color--black);
      bottom: 0;
      content: '';
      height: 1px;
      left: var(--spacing-16);
      position: absolute;
      right: var(--spacing-16);
    }
  }
}

.sb-headline--section {
  @include responsive-text-size(var(--spacing-18), var(--spacing-22));
  color: var(--color--black);
  font-weight: var(--font-weight--medium);
  letter-spacing: 0.27em;
  line-height: 1.68;
  margin-bottom: var(--spacing-base);
  margin-top: var(--spacing-2halfx);
  text-align: left;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-lg) {
    margin-top: 0;
  }
}

.sb-headline--section-standard-case {
  font-weight: var(--font-weight--regular);
  letter-spacing: 4px;
  text-transform: uppercase;
}

.sb-headline--section-sm {
  @include responsive-text-size(var(--spacing-18), var(--spacing-20));
  border-bottom: 1px solid var(--color--black);
  color: var(--color--black);
  font-weight: var(--font-weight--semibold);
  line-height: 1.68;
  margin-bottom: var(--spacing-2x);
  margin-top: var(--spacing-base);
  padding-bottom: var(--spacing-10);
  text-align: left;
  text-transform: capitalize;
}

.sb-headline--section-lg {
  @include responsive-text-size(var(--spacing-22), var(--spacing-22));
  color: var(--color--black);
  font-weight: var(--font-weight--semibold);
  letter-spacing: 0.27em;
  line-height: 1.68;
  margin-bottom: var(--spacing-base);
  margin-top: var(--spacing-2halfx);
  text-align: left;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-lg) {
    margin-top: 0;
  }
}

.sb-subhead--summary {
  color: var(--color--black);
  font-size: var(--font-size--22);
  font-weight: var(--font-weight--regular);
  line-height: 1.556;
  margin: 0 auto var(--spacing-16);
  text-align: left;
}

.sb-subhead--section {
  font-size: var(--font-size--18);
  font-weight: var(--font-weight--light);
  line-height: 1.556;
  margin: 0 auto var(--spacing-16);
  max-width: 52.5rem;
  text-align: center;
}

.sb-title-plus-link-wrap {
  display: flex;
  margin-bottom: var(--spacing-base);
  margin-top: var(--spacing-2halfx);
  width: 100%;

  @media (min-width: $sb-breakpoint-lg) {
    display: block;
    margin-top: 0;
  }
}

.sb-title-plus-link {
  display: block;
  margin-bottom: var(--spacing-base);
  margin-left: auto;
  margin-right: auto;
  width: auto;

  > * {
    display: block;
    margin: 0;
    width: 100%;
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  @media (min-width: $sb-breakpoint-xl) {
    display: flex;
    flex-flow: row nowrap;

    align-content: center;

    > *:first-child {
      flex: 1;
    }

    > *:last-child {
      margin-bottom: auto;
      margin-left: auto;
      margin-top: auto;
      width: auto;
    }
  }
}
