.multimedia-section {
  margin-bottom: var(--spacing-40);

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: var(--spacing-80);
  }
}

.multimedia-section__headline {
  margin-bottom: var(--spacing-16);

  @media (min-width: $sb-breakpoint-sm) {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  h2 {
    @media (min-width: $sb-breakpoint-sm) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  a {
    text-transform: uppercase;
    font-size: var(--font-size--14);

    @media (min-width: $sb-breakpoint-sm) {
      margin-bottom: var(--spacing-5);
    }
  }
}

.multimedia-section-recent {
  margin-left: auto;
  margin-right: auto;
  max-width: 53.75rem;
}
