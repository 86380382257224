.wp-block-media-text {
  @media (min-width: $sb-breakpoint-lg) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media (min-width: $sb-breakpoint-xl) {
    align-items: center;
  }

  p {
    font-size: var(--font-size--16);
  }

  &.has-media-on-the-right {
    > .wp-block-media-text__media {
      @media (min-width: $sb-breakpoint-lg) {
        order: 2;
      }
    }
  }

  & > * {
    @media (min-width: $sb-breakpoint-lg) {
      width: 47%;
    }
  }

  figure {
    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.wp-block-media-text__media {
  margin-left: 0;
  margin-right: 0;

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    @media (min-width: $sb-breakpoint-lg) {
      width: 100%;
    }
  }
}

[class^='wp-block'] figure.wp-block-media-text__media {
  margin-bottom: var(--spacing-base);

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: 0;
  }
}

.wp-block-media-text__content {
  @media (min-width: $sb-breakpoint-lg) {
    margin-left: 0;
    margin-right: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--spacing-18);
    margin-bottom: var(--spacing-14);

    @media (min-width: $sb-breakpoint-lg) {
      text-align: left;
      margin-top: var(--spacing-12);
    }
  }
}

.wp-block-media-text.is-image-fill {
  align-items: stretch;

  .wp-block-media-text__media {
    img {
      @media (min-width: $sb-breakpoint-lg) {
        display: none;
      }
    }
  }

  .downloadable-image-wrapper {
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
    }

    .download-button {
      position: absolute;
      bottom: 4px;
      left: 0;
    }
  }
}

.wp-block-media-text.is-style-greybackground {
  background: #f7f7f7;
  padding-left: 0;
  padding-right: 0;

  .wp-block-media-text__content {
    padding: var(--spacing-10) var(--spacing-40) var(--spacing-40) var(--spacing-40);

    @media (min-width: $sb-breakpoint-lg) {
      padding: var(--spacing-40) var(--spacing-40) var(--spacing-40) 0;
    }
  }
}

.wp-block-media-text.is-style-greybackground.has-media-on-the-right {
  .wp-block-media-text__content {
    @media (min-width: $sb-breakpoint-lg) {
      padding: var(--spacing-40) 0 var(--spacing-40) var(--spacing-40);
    }
  }
}

.wp-block-media-text {
  .wp-block-media-text__content {
    margin-bottom: 0;

    :last-child {
      margin-bottom: 0;
    }
  }
}
