.sb-article-hero {
  background-color: var(--color--starbucks-light-green);
  padding: var(--spacing-50) var(--spacing-20);
  position: relative;
  margin-top: 0;

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-80) var(--spacing-2x);
  }
}

.sb-article-hero__inner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-20);
  margin: 0 auto;
  max-width: $sb-article-content-max-width;
  position: relative;

  @media (min-width: $sb-breakpoint-lg) {
    flex-direction: row;
  }
}

.sb-article-hero__content {
  flex-shrink: 0;

  @media (min-width: $sb-breakpoint-lg) {
    width: 50%;
  }
}

.sb-article-hero__title {
  @include responsive-text-size(var(--spacing-38), var(--spacing-48));
  color: var(--color--starbucks-green);
  font-family: var(--font-family--lander);
  line-height: 1.18;
  margin: 0 auto var(--spacing-30);
  text-align: center;
  text-wrap: pretty;
  
  @media (min-width: $sb-breakpoint-lg) {
    text-align: left;
  }
}

.sb-article-hero__meta {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size--16);
  font-weight: var(--font-weight--regular);
  color: var(--color--starbucks-house-green);
  margin-top: var(--spacing-30);
  
  @media (min-width: $sb-breakpoint-lg) {
    flex-wrap: wrap;
    row-gap: var(--spacing-10);
    flex-direction: row;
    column-gap: var(--spacing-30);
    margin-top: var(--spacing-50);
  }

}

.sb-article-hero__meta > * + *::before {

  @media (min-width: $sb-breakpoint-lg) {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: currentColor;
    position: relative;
    left: -16px;
    top: -4px;
  }
}

.sb-article-hero__byline {
  margin-bottom: 0;
}

.sb-article-hero__date {
  margin-bottom: 0;
}

.sb-article-hero__read-time {
  margin-bottom: 0;
}

.sb-article-hero__excerpt {
	text-align: center;
  max-width: 536px;
  margin-inline: auto;
  margin-top: var(--spacing-30);
  font-weight: var(--font-weight--regular);
  color: var(--color--starbucks-house-green);
  text-wrap: pretty;

  @media (min-width: $sb-breakpoint-lg) {
    text-align: left;
    margin-top: var(--spacing-40);
    font-size: var(--font-size--22);
    margin-inline: 0
  }
}

.sb-article-hero__social {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-30);

  @media (min-width: $sb-breakpoint-lg) {
    margin-top: var(--spacing-40);
    justify-content: flex-start;
  }
}

.sb-article-hero__images {
  display: grid;
  gap: var(--spacing-16);
  grid-template-columns: 1.7fr 1fr;
  margin-inline: auto;
  max-width: 536px;

  @media (min-width: $sb-breakpoint-lg) {
    gap: var(--spacing-36);
    height: calc(100% + var(--spacing-3x));
    margin-bottom: calc(-1.5 * var(--spacing-2x));
    margin-right: calc(-1 * var(--spacing-2x));
    max-width: none;
    width: calc(50% + var(--spacing-2x));
  }

  @media (min-width: $sb-breakpoint-xl) {
    width: calc(100% + var(--spacing-4x));
    height: calc(100% + var(--spacing-6x));
    margin-right: calc(-1 * var(--spacing-4x));
    margin-bottom: calc(-1.5 * var(--spacing-4x));
  }
}

.sb-article-hero__images img {
  display: block;
  margin: 0;
}

.sb-article-hero__image-1 img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 54%;
  margin-left: auto;
}

.sb-article-hero__image-2 {
  grid-row: span 2;
  align-self: center;
}

.sb-article-hero__image-2 img {
  aspect-ratio: 6 / 9;
  object-fit: cover;
}

.sb-article-hero__image-3 img {
  aspect-ratio: 6 / 4;
  object-fit: cover;
}

// Color themes
.sb-article-hero--dark-green {
  background-color: var(--color--starbucks-green);
}

.sb-article-hero--dark-green .sb-article-hero__title,
.sb-article-hero--dark-green .sb-article-hero__meta,

.sb-article-hero--dark-green .sb-article-hero__excerpt,
.sb-article-hero--dark-green .sb-article-hero__social {
  color: var(--color--white);
}

.sb-article-hero--max-green-yellow {
  background-color: var(--color--max-green-yellow);
}

.sb-article-hero--max-green-yellow .sb-article-hero__title,
.sb-article-hero--max-green-yellow .sb-article-hero__meta,
.sb-article-hero--max-green-yellow .sb-article-hero__excerpt,
.sb-article-hero--max-green-yellow .sb-article-hero__social {
  color: var(--color--starbucks-house-green);
}

// Vertical spacing override for the article hero
.article__content .sb-article-hero {

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: var(--spacing-4x);
  }
}

// Social share buttons override
.sb-article-hero__social {
  color: currentColor;
  font-size: var(--font-size--14);
}

.sb-article-hero__social .article-header__social .sb-social-share__link {
  color: currentColor;
}

.sb-article-hero__social .article-header__social-label {
  font-weight: 400;
}

