$progress-bar-height: var(--spacing-5);

.progress-bar {
  background: transparent;
  display: block;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
}

.progress-bar__bar {
  background: var(--color--starbucks-green);
  display: block;
  height: $progress-bar-height;
  position: relative;
  right: 100%;
  transform: translateX(0%);
  width: 100%;
  will-change: transform;
  z-index: 1;
}
