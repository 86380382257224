.leadership-grid-wrap {
  padding-inline: var(--site-gutter);
}

.leadership-grid {
  display: grid;
  gap: var(--spacing-48);
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;
  margin-inline: auto;
  max-width: var(--w-content-max);

  @media (min-width: 460px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-md) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-lg) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.leadership-member-card {
  max-width: 160px;
  position: relative;
}

.leadership-member-card__image {
  aspect-ratio: 1;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  transition: box-shadow 0.2s ease-out;
  width: 120px;
}

.leadership-member-card:hover .leadership-member-card__image {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.leadership-member-card__name {
  color: var(--color--black);
  font-size: var(--font-size--18);
  font-style: normal;
  margin: var(--spacing-16) 0 0;
  text-align: center;
}

.leadership-member-card__name a {
  color: var(--color--black);

  &::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.leadership-member-card__position {
  font-size: var(--font-size--12);
  margin-block: var(--spacing-8) 0;
  text-align: center;
}

.lang-ja :is(.leadership-member-card__name, .leadership-member-card__link, .leadership-member-card__position) {
  font-family: var(--font-family--japan);
}
