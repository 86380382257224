.wp-block-audio audio {
  width: 100%;
  display: block;
}

.wp-block-audio audio::-webkit-media-controls-enclosure {
  background: var(--color--starbucks-accent-green);
  border-radius: 0;
  color: #fff;
}

.wp-block-audio audio::-webkit-media-controls-play-button,
.wp-block-audio video::-webkit-media-controls-play-button {
  color: var(--color--white);
}

.wp-block-audio audio::-webkit-media-controls-current-time-display,
.wp-block-audio video::-webkit-media-controls-current-time-display,
.wp-block-audio audio::-webkit-media-controls-time-remaining-display,
.wp-block-audio video::-webkit-media-controls-time-remaining-display {
  color: var(--color--white);
}
