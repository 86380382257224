@use "sass:math";

.article-header {
  display: block;
  margin-top: var(--spacing-3x);
  margin-bottom: var(--spacing-3x);
  margin-inline: calc(-1 * var(--site-gutter));

  > * {
    margin-bottom: var(--spacing-1halfx);
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--spacing-base);
    max-width: 50rem;
    padding-left: math.div(var(--spacing-base), 2);
    padding-right: math.div(var(--spacing-base), 2);
  }

  &.article-header-dark {
    background: var(--color--starbucks-house-green);
    color: var(--color--white);
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 0;
    padding: 8.75rem var(--spacing-10);
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .leadership-archive & {
    margin-bottom: calc(-1 * var(--spacing-80));
  }

  .leadership-single & {
    margin-bottom: -9.375rem;
    padding-bottom: 13.75rem;
    padding-top: var(--spacing-100);
  }

  .leadership-single & {
    margin-bottom: -9.375rem;
    padding-bottom: 13.75rem;
    padding-top: var(--spacing-100);
  }

  .multimedia-single &,
  .multimedia-results &,
  .search-results & {
    margin-bottom: calc(-1 * var(--spacing-35));
    padding: var(--spacing-100) 0;
  }

  .multimedia-archive & {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-100) 0;
  }

  .archive-page &,
  .story-category & {
    margin-bottom: calc(-1 * var(--spacing-80));
    padding: 5.625rem 0 10rem;

    &.term-hero--image {
      padding-top: var(--spacing-36);
    }
  }

  [class^='header-background--']:not(.header-with--video) &,
  [class*=' header-background--']:not(.header-with--video) & {
    margin-top: 0;
    padding: var(--spacing-70) 0 var(--spacing-20);
    position: relative;

    &.has-sticky-nav {
      padding-bottom: 0;
    }
  }

  /* Single post header style with green background */
  .header-background--green:not(.header-with--video) & {
    background: var(--color--starbucks-house-green);
    color: var(--color--white);

    > * {
      color: var(--color--gray-97);
    }

    .sb-social-share__link,
    .article-header__text {
      color: var(--color--white);

      &:hover {
        color: var(--color--white);
      }
    }
  }

  .header-background--max-green-yellow:not(.header-with--video) & {
    background: var(--color--max-green-yellow);
    color: var(--color--starbucks-house-green);

    > * {
      color: var(--color--starbucks-house-green);
    }

    .sb-social-share__link,
    .article-header__text {
      color: var(--color--starbucks-house-green);

      &:hover {
        color: var(--color--starbucks-house-green);
      }
    }
  }

  /* Single post header style with red background */
  .header-background--red:not(.header-with--video) & {
    background: var(--color--holiday-red);
    color: var(--color--white);

    > * {
      color: var(--color--gray-97);
    }

    .sb-social-share__link,
    .article-header__text {
      color: var(--color--white);

      &:hover {
        color: var(--color--white);
      }
    }
  }

  /* Single post header style with custome color background and image */
  [class^='header-background--'].header-with--image &,
  [class*=' header-background--'].header-with--image & {
    margin-bottom: 0;
    padding-bottom: 8.75rem;
  }

  .article-header__back-link {
    color: var(--color--white);
    font-size: var(--font-size--12);
    margin: var(--spacing-20) auto 0;
    text-align: center;
    text-transform: none;

    &:focus,
    &:hover {
      color: var(--color--white);
    }
  }
}

.article-header__text {
  @include responsive-text-size(var(--spacing-38), var(--spacing-48));
  font-family: var(--font-family--lander);
  line-height: 1.18;
  margin: 0 auto var(--spacing-30) auto;
  text-align: center;

  .article-header-dark & {
    @include responsive-text-size(var(--spacing-38), var(--spacing-48));
    color: var(--color--white);
  }

  &.is-long-title {
    @media (max-width: $sb-breakpoint-lg) {
      font-size: var(--font-size--28);
      line-height: 1.46;
    }
  }
}

.lang-ja {
  .article-header__text {
    font-family: var(--font-family--japan);
  }
}

.article-header__description {
  @include responsive-text-size(var(--font-size--16), var(--spacing-22));
  color: rgba(255, 255, 255, 0.56) !important;
  display: block;
  font-weight: var(--font-weight--light);
  line-height: 1.4;
  margin-top: var(--spacing-30);
  text-align: center;
  width: 100%;
}

.article-header__subtext {
  color: var(--color--white);
  font-family: var(--font-family--lander);
  font-size: 1.5rem;
  font-weight: var(--font-weight--light);
  line-height: 1.18;
  margin: 0 auto;
  text-align: center;
}

.lang-ja {
  .article-header__subtext {
    font-family: var(--font-family--japan);
  }
}

.article-header__tools {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size--14);
  justify-content: center;
  margin-bottom: var(--spacing-1halfx);

  @media (min-width: $sb-breakpoint-md) {
    flex-direction: row;
  }

  .story-category & {
    margin: var(--spacing-30) 0;
  }
}

.article-header-separator {
  background: var(--color--black);
  border: 0;
  height: var(--spacing-6);
  margin-bottom: var(--spacing-2x);
  margin-top: var(--spacing-2x);
  padding: 0;
  width: var(--spacing-32);
}

.article-header__divider {
  display: none;
  font-size: var(--font-size--18);
  margin: 0 var(--spacing-30);

  @media (min-width: $sb-breakpoint-md) {
    display: block;
  }
}

.article-header__social {
  display: flex;

  .sb-social-share__list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  .sb-social-share__item {
    display: inline-block;
    margin-left: var(--spacing-30);

    &:first-child {
      margin-left: var(--spacing-30);

      .story-category & {
        margin-left: var(--spacing-15);
      }
    }
  }

  .sb-social-share__link {
    background: none;
    border: none;
    color: var(--color--text);
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: var(--color--link-hover);
    }

    .sb-icon {
      height: 1em;
      width: 1em;
    }
  }
}

.article-header__social-label {
  letter-spacing: 4px;
  text-transform: uppercase;
}

.article-header__download {
  color: inherit;
}

// To do: refactor the article header to be its own component. Current implementation is very hard to maintain.
.header-layout--vertical {
  display: grid;
  grid-template-columns: minmax(0, 1fr);  

  .article-header {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    max-width: $sb-article-content-max-width;
    position: relative;
    width: 100%;

    @media (min-width: $sb-breakpoint-xl) {
      align-self: center;
      grid-column: 1;
      grid-row: 1;
    }

    .article-header__text,
    .article-meta,
    .article-header__tools {
      padding-inline: 0;
      
      @media (min-width: $sb-breakpoint-xl) {
        margin-left: 0;
        text-align: left;
        justify-content: start;
      }
    }
  }

  .article-hero--single-bottom {
    width: 100%;
    margin-top: 0;

    @media (min-width: $sb-breakpoint-xl) {
      margin-bottom: -80px;
      grid-row: 1;
      grid-column: 1;
    }
  }

  .article-hero__image-contain {
    width: 100%;
    max-width: 560px;
    aspect-ratio: 9 / 13;
    width: 100%;
    padding-top: 0;
    margin-inline: auto;
    
    @media (min-width: $sb-breakpoint-xl) {
      max-width: min(560px, calc(100% - 800px));
      margin-right: 0;
    }
  }

  .article-header-separator {
    max-width: $sb-article-content-max-width;
    margin-inline: auto;
    width: 100%;
  }

}