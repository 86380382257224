@use "sass:math";

$story-map-circle-horizontal-size: 5.952%;
$story-map-circle-vertical-size: 8.931%;
$story-map-content-indent: var(--spacing-20);
$story-map-circle-border-width: 4px;

@mixin sbCircle($horizontal-size) {
  clip-path: circle(
    /* Circle size as radius */ $horizontal-size /* Horizontal position */ at
      calc(#{$story-map-circle-horizontal-size} + #{$story-map-content-indent})
      /* Vertical position */
      calc(
        100% - #{$story-map-circle-vertical-size} - #{$story-map-content-indent}
      )
  );
}

.story-map {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  position: relative;
}

.story-map__background {
  position: relative;
}

.story-map__reveal {
  &:after {
    @include sbCircle(
      calc(
        #{$story-map-circle-horizontal-size} + #{$story-map-circle-border-width}
      )
    );
    background: var(--color--white);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    @media (min-width: $sb-breakpoint-lg) {
      @include sbCircle(
        calc(
          #{math.div($story-map-circle-horizontal-size, 2)} + #{$story-map-circle-border-width}
        )
      );
    }
  }
}

.story-map__embed {
  @include sbCircle(#{$story-map-circle-horizontal-size});
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  transition: clip-path 0.75s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 100%;
  z-index: 2;

  @media (min-width: $sb-breakpoint-lg) {
    @include sbCircle(#{math.div($story-map-circle-horizontal-size, 2)});
  }

  &:hover {
    clip-path: circle(100% at center);
  }
}

.story-map__image {
  margin: 0;
  width: 100%;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 30%, rgba(0, 0, 0, 0.6));
    position: absolute;
    bottom: 0;
  }
}

.story-map__image img {
  width: 100%;
}

.story-map__headings {
  bottom: calc(#{math.div($story-map-circle-horizontal-size, 4)} + #{$story-map-content-indent});
  justify-content: center;
  left: calc(#{$story-map-circle-horizontal-size * 1.5} + (#{$story-map-content-indent} * 2) + #{$story-map-circle-border-width});
  position: absolute;
  z-index: 1;
  @media (min-width: $sb-breakpoint-sm) {
    display: flex;
    flex-flow: column wrap;
    height: calc(#{$story-map-circle-vertical-size * 2} - #{var(--spacing-base)});
  }
}

.article__content .story-map__title {
  color: var(--color--limegreen);
  text-transform: uppercase;
  letter-spacing: 3.5px;
  margin: 0 var(--spacing-base) 0 0;
  font-family: var(--font-family--sodo);
  font-weight: 100;
  font-size: var(--font-size--22);
  text-align: left;
}

.article__content .story-map__subtitle {
  color: var(--color--white);
  font-size: var(--font-size--22);
  font-weight: 100;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
  text-transform: none;
}
