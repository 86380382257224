.article__content-wrap {
  padding-inline: var(--site-gutter);
}

.article__content {
  @include responsive-text-size;
  @include responsive-font-weight;

  > * {
    margin: var(--spacing-base) auto var(--spacing-1halfx);
    max-width: $sb-article-content-normal-width;

    @media (min-width: $sb-breakpoint-md) {
      margin-bottom: var(--spacing-3x);
    }
  }

  > .alignwide{
    max-width: $sb-article-content-wider-width !important;
  }

  > .alignfull {
    max-width: none !important;
    margin-left: calc(-1 * var(--site-gutter));
    margin-right: calc(-1 * var(--site-gutter));
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin-bottom: 1.938rem;
    margin-top: 2.812rem;
    text-align: center;

    a {
      @include sb_link;
    }
  }

  > h4,
  > h5,
  > .is-style-h4,
  > .is-style-h5 {
    font-weight: var(--font-weight--semibold);
    line-height: 1.797rem;
  }

  > p {
    margin-bottom: var(--spacing-40);
  }

  > p.alignleft {
    @include contentNormal;

    @media (min-width: $sb-article-content-wide-width) {
      padding-right: 0;
    }
  }

  > p.alignright {
    @include contentNormal;
    @media (min-width: $sb-article-content-wide-width) {
      padding-left: 0;
    }
  }

  > p a,
  > ul.wp-block-list > li a,
  > ul:not([class]) > li a,
  > ul[class=""] > li a {
    @include sb_link;
  }

  > ul:not(.wp-block-gallery),
  > ol {
    margin-bottom: var(--spacing-40);
    padding-left: 30px;

    > li {
      margin-bottom: var(--spacing-10);
    }
  }

  ul.wp-block-list {
    list-style: none;

    li:before {
      content: '\2022';
      color: var(--color--starbucks-accent-green);
      display: inline-block;
      font-size: var(--font-size--18);
      font-weight: bold;
      margin-left: -1.406rem;
      width: 1.406rem;

      @media (min-width: $sb-breakpoint-lg) {
        font-size: 1.562rem;
        line-height: var(--spacing-14);
        width: 1.562rem;
      }
    }

    ul {
      margin-top: var(--spacing-10);
    }
  }

  ol {
    list-style: none;

    & li {
      counter-increment: number-counter;
      position: relative;

      &:before {
        color: var(--color--starbucks-accent-green);
        content: counter(number-counter) ' ';
        display: inline-block;
        font-size: var(--font-size--16);
        height: 1.328rem;
        left: calc(-1 * var(--spacing-20));
        position: absolute;

        @media (min-width: $sb-breakpoint-lg) {
          font-size: var(--font-size--20);
        }
      }
    }
  }

  ul.wp-block-list.has-text-color li:before,
  ol.wp-block-list.has-text-color li:before {
    color: inherit;
  }
 
  iframe {
    border: none;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  // Elements with an id.
  > [id] {
    scroll-margin-top: calc(var(--h-header) + var(--spacing-24));
  }

  // Blocks with Width Exceptions

  // wide blocks
  > .product-recommend,
  > .pull-quote-image,
  > .recipe,
  > .sb-card-grid,
  > .article-gallery,
  > .starbucks-collage,
  > .press-photos,
  > .story-map,
  > .sbx-legacy-block {
    max-width: $sb-article-content-wider-width;
  }
  
  // max width blocks
  > .alignsitewide,
  > .banner,
  > .newsletter-press-center,
  > .sbx-cs-archive,
  > .sbx-cs-featured-content,
  > .wp-block-separator.is-style-thingrey,
  > .wp-block-separator.is-style-wide {
    max-width: $sb-article-content-max-width;
  }

  // full width blocks
  > .sb-card-grid.is-style-1-col-page-header,
  > .sb-xscroller,
  > .earth-month-footer {
    max-width: none;
    margin-left: calc(-1 * var(--site-gutter));
    margin-right: calc(-1 * var(--site-gutter));
  }

}

.article__content + .article__content--more {
  margin-top: var(--spacing-40);
}

.is-homepage .article__content > * {
  max-width: $sb-article-content-max-width;
}
