.wp-block-post-template.is-entire-card-clickable {

  .wp-block-post {
    position: relative;
  }

  .wp-block-post-title a::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}