.sbx-expandable-content__header {
  margin-bottom: var(--spacing-24);
}

.sbx-expandable-content__header--center {
  display: flex;
  justify-content: center;
}

.sbx-expandable-content__header[aria-hidden="true"],
.sbx-expandable-content__inner[aria-hidden="true"] {
  display: none;
}