.blog-archive {
  padding-inline: var(--site-gutter);
}

.blog-archive > * {
  max-width: var(--w-content-max);
  margin-inline: auto;
}

.blog-archive > * + * {
  margin-top: var(--spacing-48);
}

.blog-archive-item {
  background: var(--color--white);
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.18);
  margin: 0 auto;
  padding-bottom: var(--spacing-48);
  padding-top: var(--spacing-48);
  z-index: 5;

  .article-header__tools {
    margin: var(--spacing-32) 0;
  }

  .article-header__social {
    padding-right: var(--spacing-32);
  }
}

.blog-archive-item__title {
  font-family: var(--font-family--lander);
  margin-bottom: var(--spacing-32);
  margin-left: auto;
  margin-right: auto;
  padding-inline: var(--spacing-24);
  max-width: var(--w-content-normal);
  text-align: center;

  a {
    color: var(--color--black);

    &:hover {
      color: var(--color--link);
      text-decoration: none;
    }
  }
}

.blog-archive-item__date-posted {
  color: var(--color--text);
  margin-bottom: var(--spacing-32);
  text-align: center;
}

.blog-archive-item__button {
  margin-top: var(--spacing-48);
}

.blog-archive-item__content {
  padding-inline: var(--spacing-24);
}

.lang-ja {
  .blog-archive {
    .blog-archive-item {
      .blog-archive-item__title {
        font-family: var(--font-family--japan);
      }
    }
  }
}
