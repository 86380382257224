.sbx-cs-hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - var(--h-header));
  justify-content: center;
  padding-block: var(--spacing-24);
  position: relative;
}

// To do: remove after refactoring the gropu block to not have inner container.
.sbx-cs-hero .wp-block-group__inner-container {
  position: static;
  z-index: 1;
}

.sbx-cs-hero__caption {
  position: absolute;
  margin-top: 0;
  bottom: var(--spacing-24);
  left: var(--spacing-24);

  @media (min-width: $sb-breakpoint-lg) {
    bottom: var(--spacing-48);
    left: var(--spacing-48);
  }
}

.sbx-cs-hero__location {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--semibold);
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  &::before {
    content: '';
    display: inline-block;
    width: var(--spacing-12);
    height: var(--spacing-12);
    margin-right: var(--spacing-8);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' viewBox='0 0 12 12'%3E%3Cpath fill='%23fff' d='M6 1C4 1 2.5 2.6 2.5 4.5 2.5 7 6 11 6 11s3.5-4 3.5-6.5C9.5 2.5 7.9 1 6 1Zm0 4.8a1.2 1.2 0 1 1 0-2.5 1.2 1.2 0 0 1 0 2.5Z'/%3E%3C/svg%3E");
  }
}

.sbx-cs-hero__description {
  margin-bottom: 0 !important;
  margin-top: var(--spacing-8) !important;
  font-family: Lander Grande;
  font-size: var(--font-size--18);
  letter-spacing: normal;
}