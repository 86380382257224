.sbx-featured-content-columns {

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(auto-fit, auto);
    column-gap: var(--spacing-24);
  }

  & > .wp-block-column {
    padding-block: var(--spacing-32);
    background-color: var(--color--gray-97);
    padding-inline: var(--spacing-24);
  }

  & > .wp-block-column,
  & > .wp-block-column .column-content {
    @media screen and (min-width: 992px) {
      grid-row: span 3;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: subgrid;
    }
  }

  & > .wp-block-column :is(.wp-block-query, .wp-block-post-template) {

    @media screen and (min-width: 992px) {
      grid-row: span 2;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: subgrid;
    }
  }

  & > .wp-block-column h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-16);
  }

  & > .wp-block-column h2 a {
    background-color: transparent !important;
    border-bottom: none;
    transition: none;

    &:hover {
      text-decoration: underline;
    }
  }
  
  & > .wp-block-column p {
    margin-top: 0;
    margin-bottom: var(--spacing-16);
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--regular);
  }

  .wp-block-post + .wp-block-post {
    margin-top: var(--spacing-32);
  }

  .wp-block-post > .article__content > * {
    max-width: none;
  }

  .wp-block-post-featured-image {
    margin: 0;
    width: 100%;
  }

  .wp-block-post img {
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s ease-out;
  }

  .wp-block-post:hover img {
    transform: scale3d(1.05, 1.05, 1) !important;
  }

  // To do: remove after <Image> is refactored to version 13
  .wp-block-post-featured-image span {
    display: block !important;
  }

  .wp-block-post-title {
    font-size: var(--font-size--18);
    font-weight: var(--font-weight--regular);
    margin-block-start: var(--spacing-16);
  }

  .wp-block-post-title a {
    background-color: transparent !important;
    border-bottom: none;
    transition: none;
    color: var(--color--black);
  }
}
