.sb-input-control {
  position: relative;
}

.sb-input-control__label {
  display: inline-block;
  margin-bottom: var(--spacing-8);
}

.sb-input-control__input {
  appearance: none;
  background-color: var(--color--white);
  background-repeat: no-repeat;
  border-radius: 100px;
  border: 1px solid var(--color--starbucks-green);
  color: var(--color--starbucks-green);
  font-size: var(--font-size--16);
  line-height: 1.5;
  max-width: 100%;
  padding: var(--spacing-12) var(--spacing-18);
  position: relative;
  width: 100%;
}
