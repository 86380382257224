.wp-block-file {
  text-align: center;

  @media (min-width: $sb-breakpoint-sm) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  &.aligncenter {
    justify-content: center;
  }

  .wp-block-media-text & {
    @media (min-width: $sb-breakpoint-sm) {
      display: block;
      text-align: center;
    }

    @media (min-width: $sb-breakpoint-md) {
      display: flex;
      text-align: right;
    }

    a:first-of-type {
      @media (min-width: $sb-breakpoint-sm) {
        display: block;
      }

      @media (min-width: $sb-breakpoint-lg) {
        display: inline-block;
        padding-left: 0;
      }
    }
  }

  a {
    font-size: var(--font-size--14);
  }

  a:first-of-type {
    border-bottom: 0;
    color: var(--color--gray-34);
    display: block;
    font-size: var(--font-size--14);
    margin-bottom: var(--spacing-6);
    text-decoration: none;
    padding-left: var(--spacing-18);
    padding-right: var(--spacing-18);

    @media (min-width: $sb-breakpoint-sm) {
      display: inline-block;
      padding-left: 0;
      margin-bottom: 0;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.wp-block-file__button {
  @include sb-button;
  font-size: var(--font-size--14);
  margin-bottom: 0;
  padding: var(--spacing-8) var(--spacing-27);
}
