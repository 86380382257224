.animated-horizontal-scroll {
  background: var(--color--starbucks-house-green);
  margin: 0 calc(50% - 50vw);
  padding: var(--spacing-20) var(--spacing-10) var(--spacing-40);
  position: relative;
  width: 100vw;

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-100) var(--spacing-10) 0;
  }

  .split-text {
    display: inline-block;
    line-height: 1.2;
    overflow: hidden;
  }

  .split-text__inner {
    display: inline-block;
    position: relative;
  }

  h2,
  h3 {
    @include responsive-text-size(var(--spacing-24), var(--spacing-30));
    color: var(--color--white);
    font-family: var(--font-family--lander);
    font-weight: var(--font-weight--regular);
    line-height: 1.38;
    margin-bottom: var(--spacing-20);
    margin-top: 0;
  }

  p,
  li {
    color: var(--color--white);
  }

}

.animated-horizontal-scroll__outer {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 var(--spacing-10);
}

.animated-horizontal-scroll__intro {
  text-align: center;
}

.animated-horizontal-scroll__intro-image {
  margin: 0 auto var(--spacing-20);
  max-width: 246px;

}

.animated-horizontal-scroll__title {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.animated-horizontal-scroll__scroll-wrap {
  padding-top: var(--spacing-60);
  position: relative;

  &:before {
    border-top: 1px solid #00BB7E;
    content: "";
    left: 0;
    position: absolute;
    top: 20px;
    width: 100%;
  }
}

.animated-horizontal-scroll__items {
  margin: 0;
  padding: 0;

  @media (min-width: $sb-breakpoint-md) {
    display: flex;
  }
}

.animated-horizontal-scroll__item {
  list-style: none;
  margin: 0 var(--spacing-40) 0 0;
  padding: var(--spacing-20) 0;
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    display: flex;
    flex-shrink: 0;
    margin: 0 var(--spacing-60) 0 0;
    padding: var(--spacing-40) 0;
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin: 0 var(--spacing-100) 0 0;
    padding: var(--spacing-60) 0;
  }
}

.animated-horizontal-scroll__text {

  @media (min-width: $sb-breakpoint-md) {
    flex-basis: 50%;
    flex-shrink: 0;
    margin-right: var(--spacing-20);
    padding-right: var(--spacing-20);
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-right: var(--spacing-100);
    padding-right: var(--spacing-100);
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0 0 var(--spacing-10);
    padding: 0;
  }

  .icon-wrap {
    flex-shrink: 0;
    margin-right: var(--spacing-20);
    width: 80px;
  }

}

.animated-horizontal-scroll__image-container {
  margin-bottom: var(--spacing-30);

  @media (min-width: $sb-breakpoint-md) {
    flex-basis: 50%;
    order: 2;
  }
}

.animated-horizontal-scroll__image-wrap {
  background: var(--color--starbucks-light-green);
  overflow: hidden;
}
