.lead-hero-alt {
  background: linear-gradient(to bottom, var(--color--starbucks-house-green) 0px, var(--color--starbucks-house-green) 224px, var(--color--white) 224px);
  padding-block-start: var(--spacing-64);
  padding-inline: var(--site-gutter);

  @media (min-width: $sb-breakpoint-md) {
    background: linear-gradient(to top, var(--color--white) 0px, var(--color--white) 64px, var(--color--starbucks-house-green) 64px);
  }
}

.lead-hero-alt__inner {
  display: grid;
  grid-column-gap: var(--spacing-40);
  grid-row-gap: var(--spacing-24);
  grid-template-columns: 100%;
  margin-inline: auto;
  max-width: var(--w-content-max);

  @media (min-width: $sb-breakpoint-md) {
    grid-template-columns: minmax(0, 7fr) minmax(0, 4fr);
  }
}

.lead-hero-alt__media {
  margin-inline: auto;
  width: 100%;
  max-width: 320px;

  @media (min-width: $sb-breakpoint-md) {
    max-width: 480px;
    grid-column: 2;
  }
}

.lead-hero-alt__copy {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
  justify-content: center;

  @media (min-width: $sb-breakpoint-md) {
    gap: var(--spacing-64);
    grid-column: 1;
    grid-row: 1;
    padding-block-end: var(--spacing-128);
  }
}

.lead-hero-alt__head > * {
  margin: 0;
}

.lead-hero-alt__head > * + * {
  margin-block-start: var(--spacing-16);
}

.lead-hero-alt__eyebrow {
  font-family: var(--font-family--lander);
  font-size: var(--font-size--16);
  font-weight: var(--font-weight--normal);
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    text-align: left;
    color: var(--color--white);
  }
}

.lead-hero-alt__name {
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--bold);
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    text-align: left;
    color: var(--color--white);
  }
}

.lead-hero-alt__title {
  color: var(--color--black);
  font-family: var(--font-family--sodo);
  font-size: var(--font-size--16);
  font-style: normal;
  font-weight: var(--font-weight--normal);
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    text-align: left;
    color: var(--color--white);
  }
}

.lead-hero-alt__press-title {
  color: var(--color--black);
  font-family: var(--font-family--lander);
  font-size: var(--font-size--20);
  font-style: normal;
  font-weight: var(--font-weight--semibold);
  line-height: 1.2;
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    color: var(--color--white);
  }
}

.lead-hero-alt__press {
  column-gap: var(--spacing-32);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: var(--spacing-16);

  @media (min-width: $sb-breakpoint-md) {
    justify-content: flex-start;
  }
}

.lead-hero-alt__press-title {
  margin: 0;
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    text-align: left;
  }
}

.lead-hero-alt__press-item {
  font-size: var(--font-size--16);
  margin: 0;
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    text-align: left;
  }
}

.lead-hero-alt__press-item a {
  color: var(--color--black);

  @media (min-width: $sb-breakpoint-md) {
    color: var(--color--white);
  }
}

.lead-hero-alt__meta :is(a, button) {
  color: var(--color--gray-49);

  @media (min-width: $sb-breakpoint-md) {
    color: var(--color--white);
  }
}

.lead-hero-alt__meta {
  align-items: center;
  column-gap: var(--spacing-32);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: var(--spacing-16);

  @media (min-width: $sb-breakpoint-md) {
    justify-content: flex-start;
  }
}

.lead-hero-alt__icon-link {
  height: var(--spacing-16);
  width: var(--spacing-16);
}

.lead-hero-alt__icon-link svg {
  display: inline-block;
  height: var(--spacing-16);
  width: var(--spacing-16);
}