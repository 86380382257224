.earth-month-footer {
  position: relative;

  .split-text {
    display: inline-block;
    line-height: 1.2;
    overflow: hidden;
  }

  .split-text__inner {
    display: inline-block;
    position: relative;
  }
}

.earth-month-footer__background {
  background: var(--color--starbucks-house-green);
  height: 100%;
  padding: var(--spacing-100) 0 0;
  position: relative;

  svg {
    display: block;
  }
}

.earth-month-footer__content {
  background: var(--color--white);
  padding-bottom: var(--spacing-100);
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    background: none;
    left: 50%;
    margin: 0 auto;
    max-width: 56.25rem;
    position: absolute;
    top: 40%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1;
  }
}

.earth-month-footer__title {
  @include responsive-text-size(var(--spacing-40), var(--spacing-48));
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--regular);
  line-height: 1.38;
  margin-bottom: var(--spacing-20);
  margin-top: 0;
}

.earth-month-footer__flowers-left {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 200px;
}

.earth-month-footer__flowers-right {
  bottom: 0;
  position: absolute;
  right: 0;
  width: 150px;
}
