.region-select {
  align-items: center;
  border: 0.062rem solid transparent;
  border-radius: var(--spacing-30);
  font-size: var(--font-size--14);
  margin-bottom: var(--spacing-10);
  margin-left: calc(-1 * var(--spacing-16));
  padding: 0.4rem 0.469rem 0.413rem var(--spacing-16);

  @media (min-width: $is-desktop) {
    width: 49.5%;
  }

  .sb-site-footer & {
    color: var(--color--black);
    margin-bottom: var(--spacing-15);
    width: auto;

    @media (min-width: $sb-breakpoint-md) {
      padding: 0.406rem 1.406rem;
    }
  }
}

.region-select__sites {
  margin-right: 10px;
}

.region-select__name {
  margin-right: var(--spacing-8);
  text-transform: uppercase;
}

.region-select__site_sep {
  margin: 0 var(--spacing-8);
}

.region-select__site_link--active {
  align-items: center;
  color: var(--color--black);
  display: flex;

  .sb-site-footer & {
    color: var(--color--starbucks-accent-green);
  }
}

.region-select__selected {
  background: var(--color--starbucks-green);
  border-radius: 50%;
  content: '';
  display: block;
  fill: var(--color--white);
  height: 20px;
  margin-left: 10px;
  padding: 1px 4px;
  width: 20px;

  svg {
    height: 12px;
    width: 12px;
  }
}

.region-flag {
  margin-right: var(--spacing-8);

  img {
    margin-bottom: 0;
    width: var(--spacing-18);
  }
}
