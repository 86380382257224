.sbx-sticky-header-audio {
  background-color: var(--color--starbucks-light-green);
  padding-inline: var(--site-gutter);
  height: var(--h-header-sticky-audio);
}

.sbx-sticky-header-audio__inner {
  max-width: 1360px;
  margin-inline: auto;
  padding-block-start: var(--spacing-8);

  @media (min-width: 768px) {
    padding-block-start: 0;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--site-gutter);
  }
}

.sbx-sticky-header-audio__title {
  margin: 0;
  font-family: var(--font-family--lander);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 1.25;
}

.sbx-sticky-header-audio__player {
  background-color: transparent;
  color: var(--color--white);
  display: block;
  width: 100%;
}

.sbx-sticky-header-audio__player::-webkit-media-controls-enclosure {
  background: transparent;
  border-radius: 0;
}
