.sb-image-fit__image-contain {
  padding-top: 100%;
  position: relative;
}

.sb-image-fit__image {
  @include object-fit(cover, center);
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
