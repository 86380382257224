.icon-list__list {
  list-style: none;
  padding-left: 0;
}

.icon-list__item {
  align-items: center;
  display: flex;
  gap: var(--spacing-20);
}

.icon-list__item + .icon-list__item {
  margin-top: var(--spacing-20);
}

.icon-list__icon-wrap {
  border-radius: 100%;
  flex-shrink: 0;
  height: var(--spacing-80);
  width: var(--spacing-80);
}

.icon-list__icon-wrap img {
  display: block;
  height: var(--spacing-80);
  object-fit: cover;
  width: var(--spacing-80);
}

.icon-list__icon-text {
  font-size:var(--spacing-18);
  font-weight: var(--font-weight--regular);
  margin-bottom: 0;
}