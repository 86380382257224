.sbx-factoid {
  padding: var(--spacing-64) 0;
}

.sbx-factoid::after {
  background-color: transparent;
}

.sbx-factoid__heading {
  margin-top: var(--spacing-48) !important;
}

.sbx-factoid__value {
  color: var(--color--starbucks-accent-green);
  font-family: var(--font-family--lander);
  font-size: 4rem;
  font-weight: var(--font-weight--regular);
  line-height: 1;
}

.sbx-factoid__title {
  font-size: var(--font-size--22);
  font-weight: var(--font-weight--medium);
  line-height: 1;
  text-transform: uppercase;
}

.sbx-image-factoid {
  padding: var(--spacing-64) 0;
}

.sbx-image-factoid::after {
  padding: var(--spacing-64) 0;
  background-color: transparent;
}

.sbx-image-factoid__heading {
  margin-top: var(--spacing-48) !important;
}

.sbx-image-factoid__image {
  margin-bottom: var(--spacing-24) !important;
}

.sbx-image-factoid__value {
  font-family: var(--font-family--lander);
  font-size: 4rem;
  font-weight: var(--font-weight--regular);
  line-height: 1;
}

.sbx-image-factoid__title {
  color: var(--color--starbucks-accent-green);
  font-size: var(--font-size--22);
  font-weight: var(--font-weight--medium);
  line-height: 1;
  text-transform: uppercase;
}

