.asset-download {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
}

.asset-download__modal {
  background: var(--color--white);
  bottom: auto;
  box-shadow: 0 var(--spacing-4) var(--spacing-20) rgba(0, 0, 0, 0.1);
  left: 0;
  max-height: 75vh;
  right: 0;
  margin: 3.875rem auto var(--spacing-30);
  max-width: 960px;
  overflow-y: auto;
  position: fixed;
  top: var(--spacing-70);
  transition: visibility 0s linear 0.3s;
  width: 100%;
  will-change: transform;
  z-index: 3000;

  @media (min-width: $sb-breakpoint-md) {
    padding: 2.812rem 4.25rem;
  }

  &.asset-download__modal--open {
    overflow-x: scroll;
    transform: translateX(0);
  }
}

.asset-download__modal_body {
  padding-left: var(--spacing-52);
  padding-right: 2.812rem;
  padding-top: var(--spacing-30);

  @media (min-width: $sb-breakpoint-md) {
    padding: 0;
    position: relative;
  }
}

.asset-download__close {
  align-items: center;
  background: transparent;
  border: 0;
  color: var(--color--starbucks-green);
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size--14);
  justify-content: flex-start;
  margin: 0 0 var(--spacing-15) auto;
  padding: var(--spacing-10) 0;
  width: auto;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }

  svg {
    border: 0.062rem solid var(--color--starbucks-green);
    border-radius: 50%;
    height: var(--spacing-24);
    margin-left: var(--spacing-14);
    transform: rotate(45deg);
    width: var(--spacing-24);
  }
}

.asset-download__all {
  margin-bottom: var(--spacing-30);

  @media (min-width: $sb-breakpoint-sm) {
    align-items: center;
    display: flex;
  }
}

.asset-download__all-label {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size--14);
  letter-spacing: 2px;
  margin-bottom: var(--spacing-10);
  padding: 0;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-sm) {
    margin-bottom: 0;
  }
}

.asset-download__all--icon {
  height: var(--spacing-18);
  margin-right: var(--spacing-10);
  width: var(--spacing-18);
}

.asset-download__count {
  margin-left: 4px;
}

.asset-download__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.asset-download__item {
  flex: 0 0 calc(50% - var(--spacing-10));
  margin-bottom: var(--spacing-30);
  margin-right: var(--spacing-20);
  text-align: center;
  width: calc(50% - var(--spacing-10));

  @media (min-width: $sb-breakpoint-md) {
    flex: 0 0 calc(25% - var(--spacing-30));
    margin-bottom: var(--spacing-50);
    margin-right: var(--spacing-40);
    width: calc(25% - var(--spacing-30));
  }

  &:nth-child(even) {
    margin-right: 0;

    @media (min-width: $sb-breakpoint-md) {
      margin-right: var(--spacing-40);
    }
  }

  &:nth-child(4n) {
    @media (min-width: $sb-breakpoint-md) {
      margin-right: 0;
    }
  }
}

.asset-download__item-links {
  .asset-download__all & {
    margin-bottom: var(--spacing-30);

    @media (min-width: $sb-breakpoint-sm) {
      margin-bottom: 0;
      margin-left: var(--spacing-36);
    }
  }
}

.asset-download__item-link {
  background: none;
  border: none;
  color: var(--color--link);
  cursor: pointer;
  font-size: var(--font-size--14);
  padding: 0;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    color: var(--color--link-hover);
    text-decoration: underline;
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }
}

.asset-download__item-divider {
  margin: 0 var(--spacing-15);
}

.asset-download__item-image {
  margin: 0 auto var(--spacing-16);
}

.asset-download__terms {
  color: var(--color--gray-49);
  font-size: var(--font-size--12);
  font-style: italic;
  margin-top: var(--spacing-40);
  text-align: center;
}
