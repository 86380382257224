// Variables
$series-hero-image-width: 12.312rem;
$series-hero-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.16),
  0 4px 4px rgba(0, 0, 0, 0.1);

.series-hero {
  background: var(--color--white);
  box-shadow: $series-hero-box-shadow;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: var(--spacing-base);
  padding-bottom: var(--spacing-base);
  padding-top: var(--spacing-base);
  position: relative;
  width: 100%;
  z-index: 10;

  @media (min-width: $sb-breakpoint-sm) {
    flex-flow: row wrap;
    margin-bottom: var(--spacing-2x);
    padding-bottom: 0;
    padding-top: 0;
  }
}

.series-hero__image-wrap {
  display: block;
  margin-bottom: var(--spacing-base);
  margin-left: auto;
  margin-right: auto;
  width: $series-hero-image-width;

  @media (min-width: $sb-breakpoint-sm) {
    flex: 0 0 $series-hero-image-width;
    margin: 0;
  }
}

.series-hero__image {
  margin: 0;
  padding: 0;
}

.series-hero__image-fit {
  margin: 0;
  width: 100%;
}

.series-hero__image-contain {
  padding-top: 152.29%;
}

.series-hero__content {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: var(--spacing-base);
  width: 100%;

  @media (min-width: $sb-breakpoint-sm) {
    flex: 1 1 $series-hero-image-width;
    width: calc(100% - #{$series-hero-image-width});
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-base) var(--spacing-2x);
  }
}

.series-hero__title {
  color: var(--color--black);
  display: block;
  font-weight: var(--font-weight--medium);
  line-height: 1.4;
  margin: 0;
  margin-bottom: var(--spacing-16);
  text-align: center;
  width: 100%;

  img,
  svg {
    display: block;
    margin: 0 auto;
    max-height: 3.438rem;
    max-width: 12.5rem;
    width: 100%;
  }

  img {
    filter: brightness(0%);
  }

  .image-to-svg--replaced,
  svg {
    @include svg-fill-override(var(--color--black));
  }
}

.series-hero__desc {
  @include responsive-text-size(var(--font-size--16), var(--spacing-20));
  display: block;
  line-height: 1.8;
  text-align: center;
  width: 100%;
}
