.diversity-embed {
  padding: 0;
  width: 100%;

  iframe {
    border: 1px solid #999 !important;
    margin: 0 auto !important;
    max-width: 660px;
    height: 300px!important;
    width: 100%;

    @media (min-width: $sb-breakpoint-sm) {
      height: 350px!important;
    }

    @media (min-width: $sb-breakpoint-md) {
      height: 400px!important;
    }

    @media (min-width: $sb-breakpoint-lg) {
      height: 650px!important;
    }
  }
}

.tableauPlaceholder {
  margin: auto;
  height: auto!important;
}
