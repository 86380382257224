$sb-quote-cite-color: var(--color--starbucks-green);

.wp-block-pullquote {
  margin-top: var(--spacing-3x);
  margin-bottom: var(--spacing-3x);
  // max-width: $sb-breakpoint-md;
}

.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  @media (min-width: $sb-breakpoint-md) {
    max-width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.5em;
    padding-left: var(--spacing-2x);
    padding-right: var(--spacing-2x);
  }
}

.wp-block-pullquote.is-style-large.alignright,
.wp-block-pullquote.is-style-large.alignleft {
  font-size: var(--font-size--20);
}

.wp-block-pullquote.is-style-large {
  margin-left: auto;
  margin-right: auto;
}

.wp-block-pullquote blockquote {
  margin: 0;
}

.wp-block-pullquote blockquote:before {
  background: var(--color--starbucks-accent-green);
  content: '';
  display: block;
  height: 3px;
  margin-bottom: var(--spacing-1halfx);
  width: var(--spacing-1halfx);
}

.wp-block-pullquote.alignleft blockquote:before {
  @media (min-width: $sb-breakpoint-md) {
    margin-left: auto;
  }
}

.wp-block-pullquote p {
  @include responsive-text-size(
    $base-size: var(--spacing-16),
    $large-size: var(--spacing-20)
  );
  font-style: normal;
  font-weight: var(--font-weight--medium);
  line-height: 1.5;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}

.wp-block-pullquote.alignleft p {
  @media (min-width: $sb-breakpoint-md) {
    text-align: right;
  }
}

.wp-block-pullquote.is-style-large p {
  @include responsive-text-size(
    $base-size: var(--spacing-20),
    $large-size: var(--spacing-28)
  );
  text-align: center;
}

.wp-block-pullquote cite {
  color: var(--color--starbucks-accent-green);
  display: block;
  font-family: var(--font-family--sodo);
  font-size: var(--font-size--12);
  font-style: normal;
  font-weight: var(--font-weight--regular);
  letter-spacing: 2px;
  line-height: 1.5;
  margin-top: var(--spacing-1halfx);

  @media (min-width: $sb-breakpoint-md) {
    font-size: var(--font-size--16);
  }

  &:before {
    content: '-';
  }
}

.wp-block-pullquote.is-style-large cite {
  text-align: center;
}

.wp-block-column .wp-block-pullquote {
  @media (min-width: $sb-breakpoint-md) {
    max-width: 75%;
  }
}

.wp-block-column figure.wp-block-pullquote.alignright {
  text-align: right;

  blockquote {
    &:before {
      margin-left: auto;
    }
  }

  @media (min-width: $sb-breakpoint-md) {
    max-width: 75%;
  }
}
