.sbx-cs-region-archive-hero__content {
  margin-top: calc(var(--spacing-48) * -1);
  padding-inline: var(--spacing-24);
  position: relative;

  @media (min-width: 992px) {
    margin-top: calc(var(--spacing-128) * -1);
  }
}

.sbx-cs-region-archive-hero__content-inner {
  margin-inline: auto;
  max-width: var(--w-content-max);
}

.sbx-cs-region-archive-hero__media {
  width: 100%;
  aspect-ratio: 12 / 5;
  background-color: var(--color--starbucks-house-green);
  position: relative;
}

.sbx-cs-region-archive-hero__img {
  width: 100%;
  aspect-ratio: 12 / 5;
  object-fit: cover;
  object-position: center center;
}

.sbx-cs-region-archive-hero__card {
  background-color: var(--color--white);
  max-width: 626px;
  padding: var(--spacing-24) var(--spacing-32);

  @media (min-width: 992px) {
    padding: var(--spacing-32) var(--spacing-48);
  }
}

.sbx-cs-region-archive-hero__meta {

  @include eyebrowText;

  margin: 0;
}

.sbx-cs-region-archive-hero__meta a {
  color: var(--color--black);
}

.sbx-cs-region-archive-hero__meta strong a {
  color: var(--color--starbucks-green);
}

.sbx-cs-region-archive-hero__meta strong {
  color: var(--color--starbucks-green);
}

.sbx-cs-region-archive-hero__title {
  font-weight: var(--font-weight--regular);
  margin: 0;
}

.sbx-cs-region-archive-hero__card > * + *,
.sbx-cs-region-archive-hero__excerpt > * + * {
  margin-top: var(--spacing-24);
}

.sbx-cs-region-archive-hero__excerpt p {
  margin-bottom: 0;
}

.sbx-cs-region-archive-hero__pins {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-16);
}

.sbx-cs-region-archive-hero__pin {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
}

.sbx-cs-region-archive-hero__pin svg {
  flex-shrink: 0;
}