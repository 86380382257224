.sbx-stacked-img-cta {
  padding: var(--spacing-60) 0;
}

.sbx-stacked-img-cta .wp-block-group__inner-container {
  margin-bottom: 0;
}

.sbx-stacked-img-cta__column--media {
  margin-bottom: var(--spacing-40) !important;

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: 0 !important;
  }
}

.sbx-stacked-img-cta__column--media .column-content {
  position: relative;
  display: flex;

  @media (min-width: $sb-breakpoint-lg) {
    padding: 0 10%;
  }
}

.sbx-stacked-img-cta__image-one {
  margin: 10% -5% 0 0;
  flex: 0 0 55%;
}

.sbx-stacked-img-cta__image-two {
  margin: 0 0 0 -5%;
  flex: 0 0 55%;
}

.sbx-stacked-img-cta__title {
  margin-top: 0 !important;
  margin-bottom: var(--spacing-20) !important; 
}

.sbx-stacked-img-cta__paragraph {
  margin-bottom: var(--spacing-40) !important;
}