.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-8);
}

.wp-block-gallery__item {
  min-width: 0;
  position: relative;
}

.wp-block-gallery__item-link {
  display: block;
  width: 100%;
}

.wp-block-gallery__item-caption {
  margin-block-start: var(--spacing-8);
  overflow: hidden;

  .is-cropped & {
    background-color: var(--color--white);
    bottom: 0;
    left: 0;
    margin-block-start: 0;
    max-height: 100%;
    position: absolute;
    right: 0;
  }

  @include imageCaption;
}

.wp-block-gallery.has-2-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8)) / 2);
  }
}

.wp-block-gallery.has-3-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8) * 2) / 3);
  }
}

.wp-block-gallery.has-4-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8) * 3) / 4);
  }
}

.wp-block-gallery.has-5-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8) * 4) / 5);
  }
}

.wp-block-gallery.has-6-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8) * 5) / 6);
  }
}

.wp-block-gallery.has-7-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8) * 6) / 7);
  }
}

.wp-block-gallery.has-8-columns .wp-block-gallery__item {
  @media (min-width: 768px) {
    flex: 1 0 calc((100% - var(--spacing-8) * 7) / 8);
  }
}

.wp-block-gallery.is-style-single-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.wp-block-gallery.is-style-carousel {
  display: block;
  transition: opacity 1s;
}

.wp-block-gallery.is-style-carousel .wp-block-gallery__item {
  flex: 0 0 100%;
  width: 100%;
}

.wp-block-gallery.is-style-carousel.is-fullscreen .wp-block-gallery__item {
  max-width: var(--w-content-max);
  margin-inline-end: var(--spacing-24);

  img {
    object-fit: contain !important;
  }
}

.wp-block-gallery.is-style-carousel .flickity-prev-next-button {
  border-radius: var(--spacing-8);
  padding-block: var(--spacing-32);
}