.article-gallery {

  @media (min-width: $sb-breakpoint-md) {
    display: grid;
    gap: var(--spacing-20);
  }
}

.article-gallery__item {
  margin-bottom: var(--spacing-20);
  
  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: 0;
  }
}

.article-gallery__item img {
  display: block;
  margin-bottom: 0;
  object-fit: cover;
  object-position: center;
}

.article-gallery__item--1 {
  grid-area: a;
}

.article-gallery__item--2 {
  grid-area: b;
}

.article-gallery__item--3 {
  grid-area: c;
}

.article-gallery__item--4 {
  grid-area: d;
}

// Three Images.
.article-gallery.is-style-three-images {

  @media (min-width: $sb-breakpoint-md) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-areas:
      "a a a a b b b ."
      "a a a a b b b ."
      "a a a a b b b ."
      "a a a a c c c c"
      "a a a a c c c c"
      "a a a a c c c c";
  }
}

.article-gallery.is-style-three-images .article-gallery__item--1 {
  align-self: center;
}

.article-gallery.is-style-three-images .article-gallery__item--1 img {
  aspect-ratio: 3 / 4;
}

.article-gallery.is-style-three-images .article-gallery__item--2 img {
  aspect-ratio: 3 / 4;
}

.article-gallery.is-style-three-images .article-gallery__item--3 img {
  aspect-ratio: 16 / 9;
}

// Four Images.
.article-gallery.is-style-four-images {

  @media (min-width: $sb-breakpoint-md) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-areas:
      "a a a a a b b b"
      "a a a a a b b b"
      "a a a a a b b b"
      "c c c d d d d d"
      "c c c d d d d d"
      "c c c d d d d d";
  }
}

.article-gallery.is-style-four-images .article-gallery__item--1 img,
.article-gallery.is-style-four-images .article-gallery__item--4 img {
  aspect-ratio: 16 / 9;
}

.article-gallery.is-style-four-images .article-gallery__item--2 img,
.article-gallery.is-style-four-images .article-gallery__item--3 img {
  aspect-ratio: 1;

  @media (min-width: $sb-breakpoint-md) {
    width: calc(100% - var(--spacing-40));
  }
  
  @media (min-width: $sb-breakpoint-xl) {
    width: calc(100% - var(--spacing-80));
  }
}

.article-gallery.is-style-four-images .article-gallery__item--2 {
  align-self: end;
}

.article-gallery.is-style-four-images .article-gallery__item--2 > * {
  margin-right: auto;
}

.article-gallery.is-style-four-images .article-gallery__item--3 > * {
  margin-left: auto;
}