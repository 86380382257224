.newsletter-press-center {
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media (min-width: $sb-breakpoint-md) {
    flex-direction: row;
  }

  &__newsletter {
    @media (min-width: $sb-breakpoint-md) {
      margin-right: var(--spacing-10);
    }

    @media (min-width: $sb-breakpoint-lg) {
      margin-right: var(--spacing-20);
    }

    .sb-signup__input {
      border: none;
      border-bottom: 1px solid black;
      background: transparent;
      padding-left: var(--spacing-10);
      font-family: var(--font-family--sodo-sans);
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: -0.07px;
      color: rgba(0, 0, 0, 0.87);
      opacity: 0.55;
    }

    .sb-signup__submit {
      margin-top: var(--spacing-7);
      height: var(--spacing-40);
      padding: var(--spacing-7);
      width: var(--spacing-40);
    }
  }

  &__press-center {
    margin-top: var(--spacing-20);
    padding: 0 !important;

    .wp-block-button {
      display: flex;
      text-align: left;
      margin-bottom: 0;

      .wp-block-button__link {
        background: none;
        color: rgba(0, 130, 72, 0.9);
        border: none;
        padding: 0;
        font-family: var(--font-family--sodo-sans);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 4px;
        text-transform: uppercase;
        text-align: left;
      }

      svg {
        margin-left: var(--spacing-5);
        fill: rgba(0, 130, 72, 0.9);
      }
    }
    @media (min-width: $sb-breakpoint-md) {
      margin-top: 0;
      margin-left: var(--spacing-10);
    }

    @media (min-width: $sb-breakpoint-lg) {
      margin-left: var(--spacing-20);
    }

    &:hover,
    &:focus {
      .wp-block-button__link {
        text-decoration: underline;
      }
    }
  }

  &__newsletter,
  &__press-center {
    background: #f9f9f9;
    display: flex;
    padding: var(--spacing-50) var(--spacing-20);
    align-items: flex-start;
    flex-direction: row;
    position: relative;

    @media (min-width: $sb-breakpoint-md) {
      padding: var(--spacing-30) var(--spacing-20);
      left: initial;
      transform: none;
      width: 50%;
    }

    @media (min-width: $sb-breakpoint-lg) {
      padding: var(--spacing-60) var(--spacing-40);
    }

    .newsletter-press-center__press-center-link {
      color: var(--color--shuttle-gray);
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      height: 100%;
      padding: var(--spacing-50) var(--spacing-20);

      @media (min-width: $sb-breakpoint-md) {
        padding: var(--spacing-30) var(--spacing-20);
      }

      @media (min-width: $sb-breakpoint-lg) {
        padding: var(--spacing-60) var(--spacing-40);
      }

      &:hover,
      &:focus {
        text-decoration: none;

        .newsletter-press-center__title {
          color: var(--color--starbucks-green);
        }
      }
    }

    .newsletter-press-center__title {
      margin-top: 0;
      font-family: Lander;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 133%;
      letter-spacing: -0.14px;
    }

    p,
    label {
      font-family: var(--font-family--sodo-sans);
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 150%;
      letter-spacing: -0.14px;

      @media (min-width: $sb-breakpoint-md) {
        font-size: 15px;
      }

      @media (min-width: $sb-breakpoint-lg) {
        font-size: 18px;
      }

      @media (min-width: $sb-breakpoint-xl) {
        font-size: 20px;
      }
    }

    img {
      max-width: 100%;
    }
  }

  .newsletter-press-center__press-center--fullwidth {
    margin-left: 0;

    @media (min-width: $sb-breakpoint-md) {
      width: 100%;

      .newsletter-press-center__content {
        width: 80%;
      }

      .newsletter-press-center__image {
        width: 20%;
      }
    }

    .newsletter-press-center__press-center-link {
      align-items: center;
      flex-basis: 100%;
      justify-items: center;
    }

    .newsletter-press-center__image {
      img {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    width: 60%;
  }

  &__image {
    width: 40%;
    margin-left: var(--spacing-20);
    align-self: center;
  }
}

.lang-ja {
  .newsletter-press-center {
    &__newsletter {
      .sb-signup__input {
        font-family: var(--font-family--japan);
      }
    }

    &__press-center {
      .wp-block-button {
        .wp-block-button__link {
          font-family: var(--font-family--japan);
        }
      }
    }

    &__newsletter,
    &__press-center {
      .newsletter-press-center__title {
        font-family: var(--font-family--japan);
      }

      p,
      label {
        font-family: var(--font-family--japan);
      }
    }
  }
}
