
.sbx-cs-cta-section__card {
  padding: var(--spacing-24);
}

.sbx-cs-cta-section__card h3 {
  font-weight: var(--font-weight--regular) !important;
}

.sbx-cs-cta-section__card p {
  font-size: var(--font-size--16) !important;
  font-weight: var(--font-weight--regular);
}

.sbx-cs-cta-section__card p a {
  font-size: var(--font-size--16);
  text-transform: uppercase;
  font-weight: var(--font-weight--regular);
  letter-spacing: 0.25em;

  // reseting the default link styles
  text-decoration: none;
  border: none;

  &:hover {
    text-decoration: underline;

    // reseting the default link styles
    border: none;
    background: none;
  }

  &::after {
    background-size: var(--spacing-16) var(--spacing-16);
    background: url('{{basePath}}/images/chevron-right-thin-green.svg') no-repeat center center;
    content: '';
    display: inline-block;
    height: var(--spacing-16);
    line-height: 1;
    margin-left: var(--spacing-8);
    width: var(--spacing-16);
    vertical-align: -1.9px;
  }
}

.sbx-cs-cta-section .wp-block-column {
  margin-bottom: var(--spacing-24);

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: 0;
  }
}