*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
