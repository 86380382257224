.sb-checkbox-group > * + * {
  margin-top: var(--spacing-8);
}

.sb-checkbox-group__label {
  line-height: 1.5;
  margin-bottom: 0;
}

.sb-checkbox-control {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.sb-checkbox-control__input {
  appearance: none;
  border: 1px solid var(--color--starbucks-green);
  color: var(--color--starbucks-green);
  cursor: pointer;
  flex: 0 0 $sb-form-checkbox-checkmark-size;
  height: $sb-form-checkbox-checkmark-size;
  padding: 0.125rem;
  width: $sb-form-checkbox-checkmark-size;
  position: relative;
  background-color: var(--color--white);

  &:checked::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23006241' d='m7.64 17.32-5.73-5.73L0 13.5l7.64 7.64L24 4.77l-1.91-1.91Z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: $sb-form-checkbox-checkmark-icon-size;
    height: $sb-form-checkbox-checkmark-size;
    inset: 0;
    position: absolute;
    width: $sb-form-checkbox-checkmark-size;
  }
}

.sb-checkbox-control__label {
  cursor: pointer;
  flex: 1 1 calc(100% - var(--spacing-24));
  font-size: var(--font-size--16);
  line-height: 1.5;
  padding-left: var(--spacing-16);
}

.sb-checkbox-control__description {
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--light);
  padding-left: var(--spacing-40);
}
