// Variables
$sb-layout-2col-breakpoint-flex: 830px;
$sb-layout-2col-breakpoint: 1300px;
$sb-layout-2col-breakpoint-max: $sb-max-width;
$sb-layout-mid-sidebar-width: 23.75rem;
$sb-layout-mid-sidebar-width-adjust: $sb-layout-mid-sidebar-width + var(--spacing-20);

@mixin sb-layout-item-flex($width: 100%, $gutter: var(--spacing-base)) {
  flex: 1 1 calc(#{$width} - #{$gutter});
  max-width: calc(#{$width} - #{$gutter});
  width: calc(#{$width} - #{$gutter});
}

.sb-layout {
  display: flex;
  flex-flow: column nowrap;
}

.sb-layout--2col {
  margin-left: calc(-1 * var(--spacing-halfx));
  margin-right: calc(-1 * var(--spacing-halfx));

  @media (min-width: $sb-breakpoint-sm) {
    flex-flow: row nowrap;
  }
}

.sb-layout__block {
  @include sb-layout-item-flex;
  background-color: var(--color--white);
  display: block;
  margin-bottom: var(--spacing-base);
  margin-left: var(--spacing-halfx);
  margin-right: var(--spacing-halfx);
  padding: var(--spacing-10);

  @media (min-width: $sb-breakpoint-sm) {
    @include sb-layout-item-flex(50%);
    padding: var(--spacing-base);
  }

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-2x);
  }

  @media (min-width: $sb-layout-2col-breakpoint-max) {
    padding: var(--spacing-2halfx);
  }
}

.sb-layout__main {
  @media (min-width: $sb-layout-2col-breakpoint-flex) {
    flex: 1 1 calc(100% - #{$sb-layout-mid-sidebar-width-adjust});
    max-width: calc(100% - #{$sb-layout-mid-sidebar-width-adjust});
    width: calc(100% - #{$sb-layout-mid-sidebar-width-adjust});
  }

  @media (min-width: $sb-layout-2col-breakpoint) {
    @include sb-layout-item-flex(66.6666%);
  }

  @media (min-width: $sb-layout-2col-breakpoint-max) {
    padding: var(--spacing-2halfx) var(--spacing-3x);
  }
}

.sb-layout__sidebar {
  @media (min-width: $sb-layout-2col-breakpoint-flex) {
    @include sb-layout-item-flex($sb-layout-mid-sidebar-width);
  }

  @media (min-width: $sb-layout-2col-breakpoint) {
    @include sb-layout-item-flex(33.3333%);
  }
}

.is-grid {
  display: grid;
  grid-gap: var(--spacing-base);
}