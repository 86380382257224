.disclaimer {
  border-top: 1px solid var(--color--gray-56);
  font-size: var(--font-size--12);
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-40);
  margin-left: auto;
  margin-right: auto;
  max-width: 53.75rem;
  padding-top: var(--spacing-30);

  > p {
    font-size: var(--font-size--14);
    line-height: 1.5;
    margin: 0;
    max-width: none;
    padding: 0;

    a {
      border-bottom: 1px solid var(--color--link);

      &:hover,
      &:active,
      &:focus {
        border-bottom: 2px solid var(--color--link-hover);
      }
    }
  }
}
