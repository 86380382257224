.sbx-cs-slider {
  --color--text: var(--color--starbucks-house-green);
  --color--indicator: var(--color--white);
  --color--indicator-active: var(--color--starbucks-accent-green);
  background-color: var(--color--starbucks-light-green);
  padding-block: var(--spacing-64);
  padding-inline: var(--site-gutter);
  
  @media (min-width: $sb-breakpoint-md) {
    padding-block: var(--spacing-96);
  }
}

.sbx-cs-slider.is-style-with-coffee-leaves-background {
  background-image: url(/images/cs-floral-bg-01.svg), url(/images/cs-floral-bg-02.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
}

.sbx-cs-slider.has-text-color.has-background-color {
  --color--button-text: var(--color--background);
  --color--button-bg: var(--color--text);
  --color--button-hover-bg: var(--color--text);
  --color--button-hover-text: var(--color--background);
  --color--indicator: var(--color--text);
  --color--indicator-active: var(--color--text);
}

.sbx-cs-slider.has-text-color.has-background-color .sbx-cs-slider__indicator::before {
  opacity: 0.3;
}

.sbx-cs-slider__indicators {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-24);
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: $sb-breakpoint-md) {
    gap: var(--spacing-32);
  }
}

.sbx-cs-slider__indicator {
  position: relative;
}

.sbx-cs-slider__indicator button {
  @include eyebrowText;

  appearance: none;
  background: none;
  border: none;
  color: var(--color--text);
  cursor: pointer;
  display: block;
  padding: var(--spacing-12);
}

.sbx-cs-slider__indicator::before,
.sbx-cs-slider__indicator::after {
  border-radius: 5px;
  bottom: 0;
  content: '';
  display: block;
  height: 5px;
  left: var(--spacing-12);
  position: absolute;
  width: calc(100% - var(--spacing-24));
}

.sbx-cs-slider__indicator::before {
  background-color: var(--color--indicator);
}

.sbx-cs-slider__indicator::after {
  background-color: var(--color--indicator-active);
  width: 0;
  transition: transform 0.5s cubic-bezier(0.22, 0.89, 0.57, 1);
}

.sbx-cs-slider__indicator.is-active::after {
  animation: 'sbx-cs-slider__indicator' 5s cubic-bezier(0.22, 0.89, 0.57, 1) forwards;
}

.sbx-cs-slider__inner {
  max-width: var(--w-content-max);
  margin-inline: auto;
  margin-top: var(--spacing-48);
}

.sbx-cs-slider__item {
  width: 100%;
  margin-right: var(--spacing-48);
}

// Slide
.sbx-cs-slide {
  padding-block: var(--spacing-24);

  @media (min-width: $sb-breakpoint-md) {
    display: grid;
    grid-gap: 90px;
    grid-template-columns: 5fr 3fr;
  }
}

.sbx-cs-slide__eyebrow {
  @include eyebrowText;

  margin-top: 0;
  margin-bottom: 0;
}

.sbx-cs-slide__title {
  color: var(--color--text);
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--font-weight--regular);
}

.sbx-cs-slide__content {
  color: var(--color--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sbx-cs-slide__content > * + * {
  margin-top: var(--spacing-24);
}

.sbx-cs-slide__media {
  margin-top: var(--spacing-24);
  
  @media (min-width: $sb-breakpoint-md) {
    margin-top: 0;
  }
}

.sbx-cs-slide__media-wrap {
  aspect-ratio: 4 / 3;
  transform: rotate(-4.2deg);
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    transform: rotate(4.2deg) translateX(calc(-1 * var(--spacing-24)));
  }
}

.sbx-cs-slide__cta a {
  @include sb-button;
}

@keyframes sbx-cs-slider__indicator {
  0% {
    width: 0;
  }
  100% {
    width: calc(100% - var(--spacing-24));
  }
}