img,
video,
object,
.wp-caption {
  height: auto;
  max-width: 100%;
}

img,
video,
iframe,
embed,
object {
  display: block;
  max-width: 100%;
  margin: 0;
}

figure {
  margin: 0;
}

.aligncenter video {
  margin-left: auto;
  margin-right: auto;
}
