.sb-p2a {
  padding-left: 10%;
  padding-right: 10%;
  position: relative;
  text-align: center;

  @media (min-width: $sb-breakpoint-md) {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 13%;
    padding-right: 13%;
  }

  @media (min-width: $sb-breakpoint-xl) {
    margin-left: auto;
    margin-right: auto;
    max-width: 71.25rem;
  }
}

.sb-p2a__main {
  background: var(--color--starbucks-light-green);
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.18);
  color: var(--color--starbucks-house-green);
  padding: var(--spacing-60) var(--spacing-10) var(--spacing-70);
  position: relative;
  z-index: 10;

  @media (min-width: $sb-breakpoint-sm) {
    padding: var(--spacing-60) var(--spacing-20) var(--spacing-70);
  }

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-60) var(--spacing-40) var(--spacing-70);
  }

  & > *:last-of-type {
    margin-bottom: 0;
  }
}

.sb-p2a__inner-wrap {
  margin: 0 auto;
  max-width: 31.25rem;
}

.sb-p2a__heading {
  color: var(--color--starbucks-house-green);
  font-size: var(--font-size--28);
  font-weight: var(--font-weight--medium);
  line-height: 1.33;
  margin: 0;
}

.sb-p2a__form {
  margin: 0 auto;
  max-width: 25.5rem;

  form {
    display: flex;
    flex-flow: row wrap;
    margin: var(--spacing-28) 0;
  }
}

.sb-p2a__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--color--starbucks-house-green);
  color: var(--color--starbucks-house-green);
  flex: 1;
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--light);
  padding: var(--spacing-6) var(--spacing-10) var(--spacing-6) 0;

  @media (min-width: $sb-breakpoint-sm) {
    font-size: 0.938rem;
  }

  &:focus {
    border-bottom: 2px solid var(--color--starbucks-house-green);
    margin-bottom: -1px;
    outline: none;
  }
}

.sb-p2a__submit {
  color: var(--color--white);
  font-size: var(--font-size--12);
  letter-spacing: 0;
  margin-left: var(--spacing-10);
  padding: var(--spacing-15) var(--spacing-30);
  text-align: center;

  @media (min-width: $sb-breakpoint-sm) {
    font-size: var(--font-size--16);
  }
}

.sb-p2a__legal {
  color: var(--color--starbucks-house-green);
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--light);
  text-align: center;

  a {
    color: var(--color--starbucks-house-green);
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.sb-p2a__bg {
  bottom: var(--spacing-40);
  left: 0;
  position: absolute;
  right: 0;
  top: var(--spacing-40);
  z-index: 0;

  @media (min-width: $sb-breakpoint-md) {
    bottom: var(--spacing-60);
    top: var(--spacing-60);
  }

  img {
    display: block;
    height: 100%;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include object-fit(cover, center);
  }
}

.article__content .sb-p2a {
  padding-left: 5%;
  padding-right: 5%;

  @media (min-width: $sb-breakpoint-sm) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (min-width: $sb-breakpoint-md) {
    padding-left: 13%;
    padding-right: 13%;
  }
}
