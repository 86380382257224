.starbucks-collage {
  > a {
    &:hover {
      text-decoration: none;
      color: var(--color--starbucks-accent-green);
      cursor: pointer;

      * {
        color: var(--color--starbucks-accent-green);
      }
    }
  }

  .starbucks-collage__type {
    font-family: var(--font-family--sodo);
    font-weight: var(--font-weight--regular);
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: var(--color--starbucks-accent-green);
    letter-spacing: 2px;
    font-size: 0.938rem;
  }

  .starbucks-collage__title {
    text-align: center;
    font-family: var(--font-family--lander);
    margin-top: var(--spacing-halfx);
    transition: $sb-base-transition;
    @include responsive-text-size(var(--spacing-22), var(--spacing-28));

    @media (min-width: $sb-breakpoint-md) {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.lang-ja {
  .starbucks-collage {
    .starbucks-collage__type {
      font-family: var(--font-family--japan);
    }

    .starbucks-collage__title {
      font-family: var(--font-family--japan);
    }
  }
}
