.animated-hero {
    padding: var(--spacing-40) 0;

    @media (min-width: $sb-breakpoint-sm) {
        padding: var(--spacing-80) 0 var(--spacing-130);
    }
}

.animated-hero__content {

    @media (min-width: $sb-breakpoint-sm) {
        display: grid;
        grid-gap: var(--spacing-100);
        grid-template-columns: 3fr 2fr;
    }

    h1 {
        @include responsive-text-size(var(--spacing-24), var(--spacing-30));
    }

    .split-text {
        display: inline-block;
        line-height: 1.2;
        overflow: hidden;
    }

    .split-text__inner {
        display: inline-block;
        position: relative;
    }

    a {
        align-items: center;
        color: var(--color--shuttle-gray);
        display: flex;
        font-size: var(--font-size--14);
        text-transform: uppercase;
        font-weight: var(--font-weight--medium);
        justify-content: flex-start;
        letter-spacing: 2px;

        &:hover {
            text-decoration: underline;
        }

        &::after {
            animation: bounce 2s infinite;
            background: url('/images/arrow-down-lg.svg') no-repeat center center;
            background-size: 20px 20px;
            content: '';
            display: inline-block;
            height: 20px;
            line-height: 1;
            margin-left: 0.5em;
            width: 20px;
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-6px);
    }
    60% {
        transform: translateY(-3px);
    }
}

.animated-hero__image-container {
    margin-top: var(--spacing-30);
    order: 2;
    padding: 0 var(--spacing-20);
    position: relative;

    @media (min-width: $sb-breakpoint-sm) {
        margin-top: 0;
        padding: 0;
    }
}

.animated-hero__image-wrap {
    background: var(--color--starbucks-light-green);
    overflow: hidden;

    @media (min-width: $sb-breakpoint-sm) {
        margin-top: var(--spacing-100);
    }
}

.animated-hero__badge {
    bottom: 10px;
    left: -5px;
    position: absolute;
    width: 70px;

    @media (min-width: $sb-breakpoint-sm) {
        bottom: var(--spacing-100);
        left: -71px;
        width: 143px;
    }
}

.animated-hero__illustration {
    bottom: -10px;
    position: absolute;
    right: -10px;
    width: 150px;

    @media (min-width: $sb-breakpoint-sm) {
        bottom: -50px;
        right: -70px;
        width: 274px;
    }

    svg {
        height: auto;
        width: 100%;
    }
}

.animated-hero__text {
    @media (min-width: $sb-breakpoint-lg) {
        padding-right: var(--spacing-100);
    }
}
