.navigating-covid {

  h3 {
    font-family: var(--font-family--lander);
    font-size: var(--font-size--22);
    color: var(--color--black);
    font-weight: var(--font-weight--medium);
    line-height: 1.3;
    margin-bottom: var(--spacing-30);
    margin-top: 0;
    text-align: left;
    transition: color $sb-base-transition;
  }

  .wp-block-column {
    p {
      font-size: var(--font-size--16);
      font-weight: normal;
      line-height: 1.3;
      margin: 0 0 var(--spacing-26);
    }

    a {
      color: var(--color--black);
      transition: color 0.2s ease-out;

      &:hover {
        color: var(--color--starbucks-green);
        text-decoration: none;
      }
    }
  }

  @media (min-width: $sb-breakpoint-md) {
    h3 {
      font-size: var(--font-size--28);
      margin-bottom: var(--spacing-40);
    }

    .wp-block-column {
      p {
        padding: 0 1em;
      }

      a {
        font-size: var(--font-size--22);
      }
    }
  }
}

.lang-ja {
  .navigating-covid {
    h3 {
      font-family: var(--font-family--japan);
    }
  }
}
