.article-hero {
  display: block;
  margin-bottom: $article-hero-bottom-margin;
  margin-left: calc(-1 * var(--spacing-10));
  margin-right: calc(-1 * var(--spacing-10));
  overflow: hidden;
  position: relative;

  @media (min-width: $sb-breakpoint-md) {
    margin-left: -50px;
    margin-right: -50px;
  }
}

.article-hero--single {
  margin-bottom: $article-hero-single-bottom-margin;

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: $article-hero-single-bottom-margin-lg;
  }
}

.article-hero--original {
  margin-bottom: $article-hero-single-bottom-margin;

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: $article-hero-single-bottom-margin-lg;
  }

  @media (min-width: $sb-breakpoint-lg) {
    &:not(.article-hero--video) {
      margin-bottom: $article-hero-original-bottom-margin-xl;
    }
  }
}

.article-hero--home {
  margin-bottom: var(--spacing-base);

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: 0;
  }

  @media (min-width: $sb-breakpoint-md) {
    margin-left: calc(-1 * var(--spacing-10));
    margin-right: calc(-1 * var(--spacing-10));
  }
}

.article-hero__image-contain {
  display: block;
  padding-top: $article-hero-ratio;
  position: relative;

  @media (min-width: $sb-breakpoint-md) {
    padding-top: $article-hero-ratio-lg;
  }
}

.article-hero__image {
  @include object-fit(cover, center);
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.article-hero__image--bg {
  img {
    display: none;
  }
}

.article-hero--single-bottom {
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;

  [class^='header-background--'].header-with--image &,
  [class*=' header-background--'].header-with--image & {
    margin-top: -6.875rem;
  }
}

.article-hero-top {
  background: var(--color--starbucks-house-green);
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
.article-hero--green {
  margin: 0 auto;
  max-width: 1440px;
  padding: 2em var(--spacing-10) 0;

  @media (min-width: $sb-breakpoint-md) {
    padding-left: var(--spacing-50);
    padding-right: var(--spacing-50);
  }
}
