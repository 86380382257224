// To do: remove when the button (.sb-button) is refactored to support custom colors.
.sbx-red-cups-cta :is(.wp-block-button__link, .sb-button) {
  background-color: var(--color--white);
  color: var(--color--starbucks-green);
  border-color: var(--color--white);
}

.sbx-red-cups-cta :is(.wp-block-button__link, .sb-button):hover,
.sbx-red-cups-cta :is(.wp-block-button__link, .sb-button):focus {
  opacity: 0.8;
}

// To do: remove when custom gaps are supported.
.wp-block-columns.sbx-red-cups-columns {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-48);

  @media (min-width: $sb-breakpoint-lg) {
    flex-direction: row;
    gap: var(--spacing-96);
  }
}
