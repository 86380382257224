.sbx-icon-link {
  font-size: var(--font-size--16);
  line-height: 1.5;
}

.sbx-icon-link__icon {
  height: var(--font-size--16);
  margin-inline-end: var(--spacing-8);
  vertical-align: -2.5px;
  width: var(--font-size--16);
}