.sb-search-filters__wrap {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-20);

  @media (min-width: $sb-breakpoint-lg) {
    flex-direction: row;
  }
}

.sb-search-filters__group {
  flex: 1 1 100%;
}

.sb-search-filters__actions {
  margin-top: var(--spacing-20);
  display: flex;
  column-gap: var(--spacing-10);
}