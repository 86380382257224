$border-size: 0.062rem;
$menu-mobile-padding-left: var(--spacing-52);
$menu-mobile-padding-right: 2.812rem;
$nav-drawer-bg: 576px;
$nav-drawer-width: 24.375rem;
$is-desktop: 1100px;
$index-bottom: 2000;
$index-top: 3000;

.language-picker {
  align-items: center;
  background: var(--color--white);
  border-top: $border-size solid var(--color--gray-89);
  display: flex;
  flex: 0 0 var(--spacing-60);
  margin-left: -#{$menu-mobile-padding-left};
  margin-right: -#{$menu-mobile-padding-right};
  overflow: hidden;
  width: calc(100% + 6.062rem);

  @media (min-width: $sb-breakpoint-xl) {
    flex: 0 0 auto;
    margin-right: var(--spacing-32);
  }

  .site-navigation--desktop & {
    @if $use-large-screen == true {
      flex: 0 0 var(--spacing-40);
      margin: 0 0 0 1.172rem;
      overflow: visible;
      padding: 0;
      width: auto;

      @media (min-width: $sb-breakpoint-lg) {
        border-top: none;
      }

      @media (min-width: $sb-breakpoint-xl) {
        flex: 0 0 auto;
      }
    }
  }
}

.language-picker__modal {
  background: var(--color--white);
  bottom: 0;
  left: 0;
  position: fixed;
  top: var(--spacing-70);
  transform: translateX(-100%);
  transition: visibility 0s linear 0.3s;
  transition: transform 0.3s ease-out;
  width: 100%;
  will-change: transform;
  z-index: $index-top;

  @media (min-width: $nav-drawer-bg) {
    max-width: $nav-drawer-width;
  }

  @media (min-width: $is-desktop) {
    bottom: auto;
    box-shadow: 0 var(--spacing-4) var(--spacing-20) rgba(0, 0, 0, 0.1);
    margin: 3.875rem auto 0;
    max-width: 45rem;
    padding: 2.812rem 4.25rem;
    position: fixed;
    right: 0;
    transform: translateX(0);
  }

  &.language-picker__modal--open {
    overflow-x: scroll;
    transform: translateX(0);
  }
}

.language-picker__modal_screen {
  @media (min-width: $is-desktop) {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $index-bottom;
  }
}

.language-picker__modal_body {
  padding-left: $menu-mobile-padding-left;
  padding-right: $menu-mobile-padding-right;
  padding-top: var(--spacing-30);

  @media (min-width: $is-desktop) {
    padding: 0;
    position: relative;
  }
}

.language-picker__toggle {
  align-items: center;
  background: var(--color--white);
  /* Reset border */
  border: 0;
  border-bottom: 5px solid transparent;
  color: var(--color--shuttle-gray);
  display: flex;
  flex-flow: row wrap;
  font-size: var(--font-size--14);
  height: 100%;
  justify-content: flex-start;
  padding-bottom: var(--spacing-10);
  padding-left: $menu-mobile-padding-left;
  padding-right: $menu-mobile-padding-right;
  padding-top: var(--spacing-10);
  width: 100%;

  &:focus {
    text-decoration: underline;

    @media (min-width: $is-desktop) {
      outline: $sb-border-width-focus solid var(--color--gray-25);
      text-decoration: none;
    }
  }

  &:hover {
    .language-picker__globe-icon svg,
    .language-picker__current_region svg {
      fill: var(--color--starbucks-accent-green);
    }
  }

  .language-picker__globe-icon svg {
    display: none;
    fill: var(--color--black);
    height: auto;
    width: var(--spacing-20);
    margin: 0;

    @media (min-width: $is-desktop) {
      display: block;
    }
  }

  .language-picker--active & {
    color: var(--color--starbucks-green);
  }

  .site-navigation--desktop & {
    border-top: 0;
    padding: 0;
    width: auto;

    @media (min-width: $sb-breakpoint-xl) {
      padding-left: var(--spacing-6);
      padding-right: var(--spacing-20);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.language-switcher {
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: $is-desktop) {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .sb-site-footer & {
    align-items: baseline;
    margin-top: var(--spacing-40);

    @media (min-width: $sb-breakpoint-md) {
      flex-flow: row wrap;
      justify-content: center;
      padding: 0 var(--spacing-60);
    }
  }
}

.language-picker__current_region {
  position: relative;

  .site-navigation--desktop & {
    @media (min-width: $sb-breakpoint-xl) {
      margin-top: 2px;
    }
  }

  svg {
    color: var(--color--black);
    fill: currentColor;
    height: 0.281rem;
    margin-left: var(--spacing-8);
    position: absolute;
    top: calc(50% - 0.156rem);
    transform: rotate(-90deg);
    width: 0.556rem;

    @media (min-width: $is-desktop) {
      transform: none;
    }

    .language-picker--active & {
      color: var(--color--starbucks-green);
    }
  }
}

.language-picker__current_region-label {
  .site-navigation--desktop & {
    display: none;
  }
}

.language-picker__close {
  align-items: center;
  background: transparent;
  border: 0;
  color: var(--color--starbucks-accent-green);
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size--14);
  justify-content: flex-start;
  margin: 0;
  padding: var(--spacing-10) 0;
  width: 100%;

  @media (min-width: $is-desktop) {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }

  svg {
    border: $border-size solid var(--color--starbucks-accent-green);
    border-radius: 50%;
    color: var(--color--starbucks-accent-green);
    fill: var(--color--starbucks-accent-green);
    height: var(--spacing-24);
    margin-left: var(--spacing-14);
    transform: rotate(45deg);
    width: var(--spacing-24);
  }
}

.language-picker__heading {
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--regular);
  letter-spacing: 0.28em;
  line-height: 1.4;
  margin-bottom: var(--spacing-30);
  margin-top: 0;
  text-transform: uppercase;

  @media (min-width: $is-desktop) {
    margin-bottom: var(--spacing-50);
    margin-right: var(--spacing-100);
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }
}
