.sbx-icon-container {
	position: relative;
}

.sbx-icon-container__icons {
	color: var(--color--white);
}

.sbx-icon-container__icon {
	position: absolute;
	z-index: 2;
}

.sbx-icon-container__icon--one {
	bottom: 100%;
	right: 100%;
	transform: translate3d(25%, 25%, 0);
}

.sbx-icon-container__icon--two {
	top: 100%;
	left: 100%;
	transform: translate3d(-25%, -25%, 0);
}
