.sb-xscroller {
  --color--text: #{var(--color--starbucks-house-green)};
  --color--graphics: #{var(--color--gray-74)};
  --color--background: transparent;
  --image-aspect-ratio: auto;

  color: var(--color--text);
  background: var(--color--background);
  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);

  @media (min-width: $sb-breakpoint-sm) {
    padding-inline: 33px;
  }
}

.sb-xscroller__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sb-xscroller__image {
  max-width: 250px;
}

.sb-xscroller__title {
  @include responsive-text-size(var(--font-size--36), var(--font-size--40));
  color: var(--color--text);
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--semibold);
}

.sb-xscroller h1,
.sb-xscroller h2,
.sb-xscroller h3,
.sb-xscroller h4,
.sb-xscroller h5,
.sb-xscroller h6 {
  color: var(--color--text);
}

.sb-xscroller__subtitle {
  font-size: var(--font-size--22);
  margin-left: auto;
  margin-right: auto;
  max-width: $sb-article-content-wide-width;
  text-align: center;
}

.sb-xscroller__divider {
  border: none;
  width: 100%;
  height: 1px;
  background-color: var(--color--graphics);
  margin-bottom: var(--spacing-40);
  margin-top: var(--spacing-40);

  @media (min-width: $sb-breakpoint-sm) {
    margin-bottom: var(--spacing-80);
  }
}

.sb-xscroller__wrap {
  max-width: 1260px;
  margin: 0 auto;

  @media (min-width: $sb-breakpoint-md) {
    overflow-x: hidden;
  }
}

.sb-xscroller__items {
  @media (min-width: $sb-breakpoint-md) {
    display: flex;
    column-gap: var(--spacing-80);
  }
}

.sb-xscroller__item {
  width: 100%;
  flex-shrink: 0;
}

.sb-xscroller__item + .sb-xscroller__item {
  margin-top: var(--spacing-40);

  @media (min-width: $sb-breakpoint-md) {
    margin-top: 0;
  }
}

// Color Themes
.sb-xscroller--has-background-color {
  padding-block: var(--spacing-40);

  @media (min-width: $sb-breakpoint-sm) {
    padding-block: var(--spacing-60);
  }
}

.sb-xscroller--jade {
  --color--text: #{var(--color--white)};
  --color--graphics: #{var(--color--white)};
  --color--background: #{var(--color--jade)};
}

.sb-xscroller--house-green {
  --color--text: #{var(--color--white)};
  --color--graphics: #{var(--color--white)};
  --color--background: #{var(--color--starbucks-house-green)};
}

// Clean up styles
.sb-xscroller .content {
  padding: 0;
  background-color: transparent;
}

.sb-xscroller .content > *:first-child {
  margin-top: 0;
}

.sb-xscroller .wp-block-columns,
.sb-xscroller .wp-block-column,
.sb-xscroller .content > *:last-child {
  @media (min-width: $sb-breakpoint-sm) {
    margin-bottom: 0 !important;
  }
}

// Image Aspect Ratio
.sb-xscroller.has-aspect-ratio .wp-block-image img {
  aspect-ratio: var(--image-aspect-ratio);
  object-fit: cover;
  width: 100%;
}