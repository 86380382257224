.sbx-cs-region-hero {
  margin-bottom: -40px !important;
  padding-block: var(--spacing-40) 80px;

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: -120px !important;
    padding-block: var(--spacing-80) 160px;
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: -230px !important;
    padding-block-end: 300px;
  }
}

.sbx-cs-region-hero__eyebrow {
  @include eyebrowText;

  margin-block: 0 !important;
}

.sbx-cs-region-hero__eyebrow strong {
  color: var(--color--starbucks-accent-green);
}

.sbx-cs-region-hero__title {
  font-family: var(--font-family--sodo);
  font-weight: var(--font-weight--regular);
  max-width: 800px !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 1.562rem !important;
}

.sbx-cs-region-hero__copy {
  max-width: 800px;
  max-width: 800px !important;
  margin-left: 0 !important;
}

.sbx-cs-regions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);

  @media (min-width: $sb-breakpoint-lg) {
    flex-direction: row;
  }
}

.sbx-cs-regions .sbx-overlay-card,
.sbx-cs-regions .sbx-overlay-card__image {
  aspect-ratio: 7 / 6;
}

.sbx-cs-regions .sbx-overlay-card__content {
  height: auto;
}

.sbx-cs-regions .sbx-overlay-card__cta {
  margin-top: var(--spacing-12);
}