.sb-link-animate {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--medium);
  letter-spacing: 0.28em;
  line-height: 1.4;
  overflow: hidden;
  position: relative;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
}

.sb-link-animate__icon {
  margin-left: 0.25em;
}

.sb-link-animate__text {
  display: block;
  padding: var(--spacing-6) 0;
  transition: $related-title-transition;

  .sb-link-animate:hover & {
    transform: translateY(-100%);
  }
}

.sb-link-animate__text--dummy {
  left: 0;
  position: absolute;
  top: 100%;
}

.sb-link-animate__underline {
  background-color: var(--color--starbucks-green);
  bottom: 0;
  height: 0.188rem;
  left: 0;
  position: absolute;
  transform: translateX(-100%);
  transition: $related-title-transition;
  width: 100%;

  .sb-link-animate:hover & {
    transform: translateX(0);
  }
}
