// Variables
$sb-signup-max-width-footer: 26.25rem;
$sb-button-size: 3.438rem;

.sb-signup {
  display: block;
  width: 100%;
}

.sb-signup__form {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
}

.sb-signup__form--footer {
  margin: 0 auto;
  max-width: $sb-signup-max-width-footer;
  text-align: center;
}

.sb-signup__label {
  @include responsive-text-size(var(--spacing-16), var(--spacing-18));
  display: block;
  font-weight: var(--font-weight--light);
  line-height: 1.5555;
  margin-bottom: var(--spacing-16);
  width: 100%;
}

.sb-signup__submit {
  background: var(--color--link);
  color: var(--color--white);
  display: block;
}

.sb-signup__input {
  color: var(--color--link);
  margin-bottom: var(--spacing-16);
  width: calc(100% - #{$sb-button-size});

  &:focus {
    outline-offset: 0;
  }
}

.sb-signup__input--footer {
  color: var(--color--text);
  margin-bottom: 0;
  margin-right: var(--spacing-5);
  width: calc(100% - #{$sb-button-size} - var(--spacing-5));
}

.sb-signup__submit {
  height: $sb-button-size;
  padding: var(--spacing-16);
  text-align: center;
  width: $sb-button-size;

  .sb-icon {
    margin: 0 auto;
  }
}

.sb-signup--block .sb-form__error,
.sb-signup--block .sb-form__loading,
.sb-signup--block .sb-form__result {
  margin-bottom: 1em;
  margin-top: calc(-1 * var(--spacing-10));
}
