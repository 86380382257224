$site-header-elevation: 0px 1px 3px rgba(0, 0, 0, 0.1),
  0px 2px 2px rgba(0, 0, 0, 0.06), 0px 2px 0px rgba(0, 0, 0, 0.07);
$site-header-z-index: 10;
$site-header-container-spacing: 0 var(--spacing-3halfx);
$site-header-container-spacing-lg: 0 var(--spacing-2x);
$site-header-logo-width: 6.75rem;
$site-header-logo-width-lg: 7.438rem;

.sb-site-header {
  background-color: var(--color--white);
  box-shadow: $site-header-elevation;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.sb-site-header__container {
  align-items: center;
  background-color: var(--color--white);
  display: flex;
  height: var(--spacing-3halfx);
  justify-content: center;
  margin: 0 auto;
  max-width: $sb-max-width;
  padding: $site-header-container-spacing;
  position: relative;
  z-index: $site-header-z-index;

  @media (min-width: $sb-breakpoint-lg) {
    height: var(--spacing-4halfx);
    justify-content: flex-start;
    padding: $site-header-container-spacing-lg;

    .sb-site-header__logo {
      margin-right: var(--spacing-2x);
      max-width: $site-header-logo-width-lg;

      &--ca-fr {
        max-width: $site-header-logo-width-lg + var(--spacing-20);
        margin-right: var(--spacing-base);
      }
    }
  }
}

.sb-site-header__logo {
  min-width: $site-header-logo-width;
  max-width: $site-header-logo-width;
  width: 100%;

  &:focus {
    outline-offset: $sb-border-outline-offset;
  }

  &--ca-fr {
    max-width: $site-header-logo-width + var(--spacing-20);
  }

  img {
    margin: 0;
    width: 100%;
    height: auto;
  }
}