.sb-button {
  @include sb-button;
}

.sb-button--download {
  font-size: var(--font-size--16);
  letter-spacing: 0;
  line-height: 1.7;
  padding: var(--spacing-7) var(--spacing-23) var(--spacing-8);
  text-transform: capitalize;

  .sb-icon {
    bottom: -0.031rem;
    display: inline-block;
    height: var(--spacing-15);
    margin-left: calc(-1 * var(--spacing-8));
    margin-right: var(--spacing-5);
    position: relative;
    width: var(--spacing-15);
  }

  span {
    font-weight: var(--font-weight--light);
  }
}

.sb-button--transparent {
  background: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    background: transparent;
    border-color: var(--color--white);
  }
}

.sb-button--outline {
  background: transparent;
  border-color: transparent;
  color: var(--color--link);

  &:hover,
  &:focus {
    background: var(--color--link);
    border-color: var(--color--link);
    color: var(--color--white);
  }
}

.sb-button--white {
    background: var(--color--white);
    border: none;
    color: var(--color--link);

    &:hover,
    &:focus {
        background: rgba(255, 255, 255, 0.8);
        color: var(--color--link);
    }
}

.sb-button.jade {
  background-color: var(--color--jade);
  border-color: var(--color--jade);
}

.wp-block-button {
  display: block;
  text-align: center;

  &.is-style-outline {
    .wp-block-button__link {
      background-color: var(--color--white);
      border-color: var(--color--starbucks-accent-green);
      color: var(--color--starbucks-accent-green);

      &:hover,
      &:active,
      &:focus {
        background-color: var(--color--starbucks-light-green);
      }
    }
  }

  &.is-style-black-background {
    .wp-block-button__link {
      background-color: var(--color--black);
      border-color: var(--color--black);
      color: var(--color--white);

      &:hover,
      &:active,
      &:focus {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  &.is-style-black-outline {
    .wp-block-button__link {
      background-color: var(--color--white);
      border-color: var(--color--black);
      color: var(--color--black);

      &:hover,
      &:active,
      &:focus {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}

.wp-block-button__link {
  margin-bottom: 0;
  @include sb-button;

  &:hover {
    text-decoration: none;
  }
}
