.sb-search-header {
  margin-bottom: var(--spacing-60);
}

.sb-search-header__top {
  align-items: center;
  border-bottom: 1px solid var(--color--starbucks-accent-green);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-32);
  padding-bottom: var(--spacing-20);
}

.sb-search-header__total {
  width: 100%;

  @media (min-width: $sb-breakpoint-md) {
    width: auto;
  }
}

.sb-search-header__total strong {
  color: var(--color--starbucks-accent-green);
}

.sb-search-header__toggle {

  @media (min-width: $sb-breakpoint-md) {
    margin-left: auto;
  }
}

.sb-search-header__toggle-button {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: var(--color--starbucks-accent-green);
  cursor: pointer;
  display: flex;
  gap: var(--spacing-8);
  line-height: 1;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.sb-search-header__toggle-icon {
  align-items: center;
  display: flex;
  height: var(--spacing-18);
  justify-content: center;
  width: var(--spacing-18);
}

.sb-search-header__toggle-icon svg {
  display: block;
  height: 10px;
  width: 16px;
}

.sb-search-header__toggle-button[data-is-toggled="true"] svg {
  transform: rotate(180deg);
}

.sb-search-header__sorting {
  align-items: center;
  display: flex;
  gap: var(--spacing-8);
}

.sb-search-header__filters {
  background-color: var(--color--gray-97);
  padding: var(--spacing-base);

  &[aria-hidden="true"] {
    display: none;
  }
}

.sb-search-header__filters-title {
  text-align: left;
  margin: 0;
}

.sb-search-header__filters > * + * {
  margin-top: var(--spacing-20);
}
