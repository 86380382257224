$sb-input-bg: var(--color--white);
$sb-input-border-size: $sb-border-xs;
$sb-input-border-radius: 0;
$sb-form-checkbox-checkmark-size: var(--spacing-24);
$sb-form-checkbox-checkmark-icon-size: 1.1rem;
$sb-form-checkbox-focus-border: 0.062rem;
$sb-form-checkbox-focus-border-negative: -0.062rem;

button {
  cursor: pointer;
}

.sb-input, .search-field {
  background-color: $sb-input-bg;
  border: $sb-border-xs solid var(--color--starbucks-green);
  border-radius: $sb-input-border-radius;
  display: block;
  line-height: 1.2;
  margin: 0;
  max-width: none;
  padding: var(--spacing-16);
  text-shadow: none;
  text-transform: none;
  width: 100%;

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
    outline-offset: $sb-border-outline-offset;
  }
}

// PLACEHOLDER TEXT
form {
  [placeholder]::-webkit-input-placeholder {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  [placeholder]:-moz-placeholder {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  [placeholder]::-moz-placeholder {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  [placeholder]:-ms-input-placeholder {
    font-family: inherit;
    font-weight: inherit;
  }
}

// SB Form
// Fieldset
.sb-form__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Checkbox @todo refactor to use Checkbox component.
.sb-form__checkbox-label {
  cursor: pointer;
  display: block;
  margin-bottom: var(--spacing-10);
  padding-left: calc(#{var(--spacing-18)} + #{var(--spacing-24)});
  position: relative;
}

.sb-form__checkbox-label-text {
  color: var(--color--text);
  display: block;
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--medium);
  line-height: 1.6;

  span {
    display: block;
    font-size: 0.688rem;
    font-weight: var(--font-weight--light);
    line-height: 18px;
  }
}

.sb-form__checkbox-checkbox {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.sb-form__checkbox-checkmark {
  border: 1px solid var(--color--starbucks-green);
  color: var(--color--starbucks-green);
  cursor: pointer;
  height: $sb-form-checkbox-checkmark-size;
  left: 0;
  padding: 0.125rem;
  position: absolute;
  top: var(--spacing-4);
  width: $sb-form-checkbox-checkmark-size;

  .sb-icon {
    height: $sb-form-checkbox-checkmark-icon-size;
    opacity: 0;
    transition: opacity $sb-base-transition;
    width: $sb-form-checkbox-checkmark-icon-size;
  }
}

.sb-form__checkbox-checkbox:checked ~ .sb-form__checkbox-checkmark .sb-icon {
  opacity: 1;
}

.sb-form__checkbox-checkbox:focus ~ .sb-form__checkbox-checkmark {
  box-shadow: inset $sb-form-checkbox-focus-border
      $sb-form-checkbox-focus-border 0 var(--color--link-hover),
    inset $sb-form-checkbox-focus-border-negative
      $sb-form-checkbox-focus-border-negative 0 var(--color--link-hover);
}

.sb-form__checkbox-checkbox:focus ~ .sb-form__checkbox-label-text {
  color: var(--color--starbucks-green);
}

.sb-form__error {
  color: var(--color--danger);
  font-size: 0.8em;
  margin-top: var(--spacing-10);
}

.sb-form__loading {
  color: var(--color--success);
  font-size: 0.8em;
  margin-top: var(--spacing-10);
}

.sb-form__loading:after {
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.sb-form__result {
  font-size: 0.8em;
  margin-top: var(--spacing-10);
}

.sb-form__result--success {
  color: var(--color--success);
}

.sb-form__result--failure {
  color: var(--color--danger);
  width: 100%;
}

.sb-form__result--pending {
  width: 100%;
}
