// Variables
$article_cat_image_max_width: 12.5rem;
$article_cat_image_max_height: var(--spacing-80);

.article-category {
  display: block;
  font-weight: var(--font-weight--regular);
  letter-spacing: 0.3em;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
}

.article-category__name {
  font-size: var(--font-size--14);
  margin-bottom: 0;
}

.article-category__type {
  font-size: 2rem;
}

.article-category__link {
  display: block;
  text-align: center;
  color: var(--color--text);

  .image-to-svg--replaced,
  svg {
    @include svg-fill-override(currentColor);
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: var(--color--link);
  }
}

.article-category__image {
  margin: 0 auto;
  max-width: $article_cat_image_max_width;
  max-height: $article_cat_image_max_height;

  &:not(.image-to-svg--replaced) {
    filter: brightness(0%);
  }
}

.article-category.article-category--story-cat {
  margin-top: 0;

  .article-category__type {
    color: var(--color--white);
    font-size: 0.938rem;
    letter-spacing: 2px;
  }
}
