// variables
$sb-footer-nav-letter-spacing: 0.125em;
$sb-footer-link-padding: 0.969rem;

.sb-footer-nav {
  display: block;
}

.sb-footer-nav__ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  letter-spacing: $sb-footer-nav-letter-spacing;
  list-style: none;
  margin: var(--spacing-2x) 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-md) {
    flex-flow: row wrap;
  }
}

.sb-footer-nav__link {
  display: block;
  padding: $sb-footer-link-padding;
}
