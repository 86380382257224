.pullquote-share {
  &.alignright {
    blockquote:before {
      background: var(--color--starbucks-accent-green);
      content: '';
      display: block;
      height: 3px;
      margin-bottom: var(--spacing-base);
      margin-left: auto;
      margin-right: 0;
      width: var(--spacing-1halfx);
    }

    .pullquote-share__blockquote {
      margin-top: var(--spacing-halfx);

      p,
      cite {
        text-align: right;
      }

      p {
        @include responsive-text-size(
          $base-size: var(--spacing-16),
          $large-size: var(--spacing-20)
        );
        font-family: var(--font-family--sodo);
        font-style: normal;
        font-weight: var(--font-weight--medium);
        line-height: 1.5;
        letter-spacing: 0.12em;
        text-transform: uppercase;
      }

      cite {
        margin-top: var(--spacing-halfx);
      }
    }

    .sb-social-share {
      margin-top: var(--spacing-quarterx);
      padding-right: var(--spacing-2x);
    }

    .sb-social-share__wrapper {
      margin-left: auto;
      margin-right: 0;
      width: var(--spacing-35);
    }

    .sb-social-share .sb-social-share__button {
      height: var(--spacing-35);
      width: var(--spacing-35);
    }
  }

  .pullquote-share__blockquote {
    margin-bottom: 0;

    & > p {
      @include responsive-text-size(
        $base-size: var(--spacing-22),
        $large-size: var(--spacing-28)
      );
      color: var(--color--black);
      display: block;
      font-family: var(--font-family--lander);
      font-size: 1.438rem;
      font-weight: var(--font-weight--light);
      letter-spacing: normal;
      line-height: 1.3;
      max-width: calc(#{$sb-breakpoint-lg} - #{var(--spacing-4x)});
      margin: 0 auto;
      text-align: center;
      text-transform: none;
      position: relative;
      z-index: 5;
    }

    & > cite {
      color: var(--color--starbucks-accent-green);
      display: block;
      font-family: var(--font-family--sodo);
      font-size: var(--font-size--12);
      font-style: normal;
      font-weight: var(--font-weight--regular);
      letter-spacing: 2px;
      line-height: 1.5;
      margin-top: var(--spacing-1halfx);
      text-align: center;

      @media (min-width: $sb-breakpoint-md) {
        font-size: var(--font-size--16);
      }

      &:before {
        content: '-';
      }
    }
  }
}

.pullquote-share,
.shareable-highlight {
  .sb-social-share {
    margin-top: 0;
  }

  .sb-social-share--open {
    .sb-social-share__list {
      z-index: 9;
    }
  }

  .sb-social-share__list {
    bottom: calc(100% + var(--spacing-5));
    flex-flow: row nowrap;
    padding: 0.781rem var(--spacing-5);
    right: calc(50% - var(--spacing-50));

    &:after {
      right: calc(50% - 10px);
    }
  }

  .sb-social-share__wrapper {
    margin: 0 auto;
  }

  .sb-social-share__link {
    margin: 0 var(--spacing-10);
    padding: 0;
  }

  .sb-icon {
    height: 1.562rem;
    width: 1.562rem;
  }

  .sb-social-share__button {
    background: transparent;
    border: none;
    box-shadow: none;

    &:focus,
    &:hover {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
}

.lang-ja {
  .pullquote-share {
    &.alignright {
      .pullquote-share__blockquote {
        p {
          font-family: var(--font-family--japan);
        }
      }
    }

    .pullquote-share__blockquote {
      & > p {
        font-family: var(--font-family--japan);
      }

      & > cite {
        font-family: var(--font-family--japan);
      }
    }
  }
}
