.wp-block-embed.is-type-video {

  &.alignleft,
  &.alignright {
    @media (min-width: $sb-breakpoint-md) {
      display: block;
      float: none;

      .wp-block-embed__wrapper {
        display: inline;
        max-width: calc(50% - 1.5em);
      }
    }
  }

  &.alignleft {
    @media (min-width: $sb-breakpoint-md) {
      .wp-block-embed__wrapper {
        float: left;
        margin-right: 1.5em;
      }
    }
  }

  &.alignright {
    @media (min-width: $sb-breakpoint-md) {
      .wp-block-embed__wrapper {
        float: right;
        margin-left: 1.5em;
      }
    }
  }

  &.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(50% - 1.5em);
  }

  figcaption {
    margin-block-start: 1em;

    @include contentNormal;
    @include imageCaption;
  }
}

.wp-block-embed .wp-block-embed__wrapper {
  position: relative;
}

.wp-block-embed iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.wp-embed-aspect-21-9 .wp-block-embed__wrapper {
  aspect-ratio: 21 / 9;
}

.wp-embed-aspect-18-9 .wp-block-embed__wrapper {
  aspect-ratio: 18 / 9;
}

.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
  aspect-ratio: 16 / 9;
}

.wp-embed-aspect-4-3 .wp-block-embed__wrapper {
  aspect-ratio: 4 / 3;
}

.wp-embed-aspect-1-1 .wp-block-embed__wrapper {
  aspect-ratio: 1 / 1;
}

.wp-embed-aspect-9-6 .wp-block-embed__wrapper {
  aspect-ratio: 9 / 6;
}

.wp-embed-aspect-1-2 .wp-block-embed__wrapper {
  aspect-ratio: 1 / 2;
}

.wp-embed-aspect-9-16 .wp-block-embed__wrapper {
  aspect-ratio: 9 / 16;
  max-width: 25rem;
}

.wp-embed-aspect-9-16,
.wp-embed-aspect-1-2,
.wp-block-embed.is-style-portrait {
  max-width: 25rem;
}

.wp-block-embed.is-type-rich.alignleft {
  @media (min-width: $sb-breakpoint-lg) {
    clear: right;
    float: left;
  }
}

.wp-block-embed.is-type-rich.alignright {
  @media (min-width: $sb-breakpoint-lg) {
    clear: left;
    float: right;
  }
}

.wp-block-embed.is-type-rich.aligncenter .wp-block-embed__wrapper {
  display: flex;
  justify-content: center;
}

.wp-block-embed.is-type-rich.alignwide .wp-block-embed__wrapper {
  background-color: var(--color--white);
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacing-base);
  padding-top: var(--spacing-base);
}

.wp-block-embed.is-type-rich.alignfull {
  background-color: var(--color--white);
}

.wp-block-embed.is-type-rich.alignfull .wp-block-embed__wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacing-base);
  padding-top: var(--spacing-base);
}
