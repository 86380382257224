@mixin sb-button($size: large, $style: false) {
  appearance: none;
  background-color: var(--color--button-bg);
  border-radius: 50px;
  border: 1px solid var(--color--button-bg);
  color: var(--color--button-text);
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size--16);
  font-weight: var(--font-weight--medium);
  padding: var(--spacing-10) var(--spacing-32) var(--spacing-14);
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition-property: background-color border-color;
  transition: $sb-base-transition;

  @if $size == small {
    padding: var(--spacing-8) var(--spacing-27);
  }

  &:hover,
  &:active,
  &:focus {
    background: var(--color--button-hover-bg);
    border-color: var(--color--button-hover-bg);
    color: var(--color--button-text);
    text-decoration: none;
  }

  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }

  @if $style == white {
    background-color: var(--color--button-text);
    border-color: var(--color--button-bg);
    color: var(--color--button-bg);

    &:hover,
    &:active,
    &:focus {
      background: var(--color--button-hover-bg);
      color: var(--color--button-bg);

      a {
        color: var(--color--button-bg);
      }
    }
  }

  > a {
    text-decoration: none;
  }
}
