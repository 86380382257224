.animated-media-text {
  margin-bottom: var(--spacing-40);

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: var(--spacing-100);
  }

  .split-text {
    display: inline-block;
    line-height: 1.2;
    overflow: hidden;
  }

  .split-text__inner {
    display: inline-block;
    position: relative;
  }

}

.animated-media-text__wrap {

  @media (min-width: $sb-breakpoint-md) {
    align-items: center;
    display: flex;
  }
}

.animated-media-text__image-container {
  position: relative;

  @media (min-width: $sb-breakpoint-md) {
    flex-basis: 60%;
    margin-left: -5.625rem;
    order: 1;

    .is-layout-image-right & {
      flex-basis: 50%;
      order: 2;
    }

    .is-layout-image-left & {
      order: 1;
    }
  }
}

.animated-media-text__image-wrap {
  background: var(--color--starbucks-light-green);
  overflow: hidden;
}

.animated-media-text__image-wrap--transparent {
  background: none;
}

.animated-media-text__illustration {

  .is-layout-image-right & {
    bottom: calc(-1 * var(--spacing-40));
    left: calc(-1 * var(--spacing-10));
    position: absolute;
    width: 50%;

    @media (min-width: $sb-breakpoint-md) {
      left: calc(-1 * var(--spacing-80));
    }
  }

  .is-layout-image-left & {
    bottom: calc(-1 * var(--spacing-20));
    right: calc(-1 * var(--spacing-20));
    position: absolute;
    width: 40%;

    @media (min-width: $sb-breakpoint-md) {
      bottom: calc(-1 * var(--spacing-30));
      right: calc(-1 * var(--spacing-100));
      width: 45%;
    }
  }
}

.animated-media-text__content {

  @media (min-width: $sb-breakpoint-md) {
    flex-basis: 40%;
    margin-left: var(--spacing-100);
    padding-left: var(--spacing-5);

    .is-layout-image-right & {
      flex-basis: 60%;
      margin-left: 0;
      margin-right: var(--spacing-100);
      padding-left: 0;
      padding-right: var(--spacing-60);
    }

    .is-layout-image-left & {
      order: 2;
    }
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: 12.5rem;

    .is-layout-image-right & {
      margin-right: 12.5rem;
      padding-right: var(--spacing-100);
    }

  }

  h3 {
    @include responsive-text-size(var(--spacing-24), var(--spacing-30));
    color: var(--color--starbucks-house-green);
    font-family: var(--font-family--lander);
    font-weight: var(--font-weight--regular);
    line-height: 1.38;
    margin-bottom: var(--spacing-20);
    text-align: left;
  }
}
