.next-prev {
  justify-content: space-between;

  @media screen and (min-width: $sb-breakpoint-md) {
    display: flex;
    margin-bottom: var(--spacing-50);
    margin-top: var(--spacing-40);
  }

  @media screen and (max-width: $sb-breakpoint-md + 1) {
    margin-left: calc(-1 * var(--spacing-15));
    width: 103%;
    width: calc(100% + var(--spacing-30));
  }

  &__title-text {
    font-size: var(--font-size--14);
    line-height: 1.68;
    margin-bottom: var(--spacing-14);
  }

  &__title {
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: var(--spacing-30) auto 0;
    max-width: 95%;
    padding: 0 0 var(--spacing-10);
    text-align: center;
    width: 219px;

    @media screen and (min-width: $sb-breakpoint-md) {
      margin-top: 0;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    svg {
      width: 100%;
    }
  }

  &__title-break-under {
    margin-bottom: var(--spacing-30);

    @media screen and (min-width: $sb-breakpoint-xl) {
      display: none;
    }
  }

  &__title-break-over {
    @media screen and (max-width: $sb-breakpoint-xl + 1) {
      display: none;
    }
  }

  .article-category__type {
    font-size: 1.562rem;
  }
}

.next-prev-button {
  background: white;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  min-height: 130px;
  position: relative;
  width: 100%;

  &--next {
    flex-direction: row-reverse;
  }

  @media screen and (min-width: $sb-breakpoint-md) {
    max-width: 550px;
    width: 36%;
  }

  @media screen and (min-width: $sb-breakpoint-xl) {
    width: 28%;
  }

  @media screen and (max-width: $sb-breakpoint-md - 1) {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    margin-bottom: var(--spacing-27);
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &__image {
    width: 45%;
    position: relative;
    background: var(--color--gray-89);

    &--previous {
      margin-left: auto;
    }

    &--next {
      margin-right: auto;
    }

    @media screen and (min-width: $sb-breakpoint-md) {
      position: absolute;
      height: 100%;

      &--previous {
        right: 40px;
        transform: translateX(100%);
      }

      &--next {
        left: 40px;
        transform: translateX(-100%);
      }
    }

    img {
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      position: absolute;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

  &__arrow-area {
    align-items: center;
    background: var(--color--fun-green);
    display: flex;
    justify-content: center;
    min-height: 100%;
    min-width: 40px;

    @media screen and (min-width: $sb-breakpoint-md) {
      position: absolute;

      &--previous {
        left: 0;
        transform: translateX(-100%);
      }

      &--next {
        right: 0;
        transform: translateX(100%);
      }
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $sb-breakpoint-md) {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    }

    &--previous {
      padding: 30px 30px 30px 20px;

      @media screen and (min-width: $sb-breakpoint-md) {
        padding-right: 45px;
      }

      @media screen and (min-width: $sb-breakpoint-lg) {
        padding-right: 70px;
      }

      * {
        text-align: left;
      }
    }

    &--next {
      padding: 30px 24px 30px 20px;

      @media screen and (min-width: $sb-breakpoint-md) {
        padding-left: 45px;
      }

      @media screen and (min-width: $sb-breakpoint-lg) {
        padding-left: 70px;
      }

      * {
        text-align: right;
      }
    }
  }

  &__direction {
    color: var(--color--fun-green);
    font-size: var(--font-size--12);
    font-weight: 400;
    letter-spacing: var(--spacing-4);
    line-height: var(--spacing-16);
    padding-bottom: var(--spacing-5);
    text-transform: uppercase;
  }

  &__direction,
  &__title {
    margin: 0;
  }

  &__title {
    color: #000000;
    font-size: 1.188rem;
    line-height: 1.31;

    @media screen and (min-width: $sb-breakpoint-lg) {
      font-size: 1.625rem;
      line-height: var(--spacing-34);
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 17px;

    @media screen and (min-width: $sb-breakpoint-md) {
      line-height: 25px;
    }

    margin: 0;
    margin-top: 7px;

    color: rgba(43, 43, 43, 0.8);
  }

  &__arrow {
    width: var(--spacing-30);
    height: var(--spacing-30);

    path {
      fill: white;
    }

    &--previous {
      transform: rotate(180deg);
    }
  }
}
