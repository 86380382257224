// Variables
$sb-card-grid-item-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
$sb-card-grid-item-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
$sb-card-grid-item-box-shadow-hover: 0 10px 35px rgba(0, 0, 0, 0.1);
$sb-card-grid-item-cat-transition: transform 600ms
  cubic-bezier(0.22, 0.89, 0.57, 1);
$sb-card-grid-image-ratio: 63.5%;

.sb-card-grid-item {
  background: var(--color--gray-97);
}

.sb-card-grid-item__link {
  box-shadow: $sb-card-grid-item-box-shadow;
  display: block;
  height: 100%;
  transition: box-shadow $sb-base-transition;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    box-shadow: $sb-card-grid-item-box-shadow-hover;
    text-decoration: none;

    .sb-card-grid-item__title {
      color: var(--color--starbucks-green);
    }
  }

  @media (min-width: $sb-breakpoint-sm) {
    display: flex;
    flex-direction: column;
  }
}

.sb-card-grid-item__title-contain {
  padding: var(--spacing-10) var(--spacing-10) var(--spacing-base) var(--spacing-10);

  @media (min-width: $sb-breakpoint-sm) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-base);
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-1halfx);
  }

  .post-grid-item--nomedia & {
    background: var(--color--white);
    border: 1px solid var(--color--gray-89);
    margin: var(--spacing-base);
    padding: var(--spacing-base);

    @media (min-width: $sb-breakpoint-xl) {
      margin: var(--spacing-1halfx);
      padding: var(--spacing-1halfx);
    }
  }
}

.sb-card-grid-item__title-contain--originals {
  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-base);
  }
}

.sb-card-grid-item__meta-cat {
  align-items: left;
  color: var(--color--text);
  display: flex;
  flex-flow: row wrap;
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--medium);
  justify-content: space-between;
  letter-spacing: 0.3em;
  margin-bottom: var(--spacing-10);
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
}

.sb-card-grid-item__meta-cat--narrow {
  letter-spacing: 0.12em;
}

.sb-card-grid-item__meta-cat-text {
  color: var(--color--starbucks-accent-green);
  display: block;
  padding: var(--spacing-6) 0;
  transition: $sb-card-grid-item-cat-transition;
  width: 100%;
}

.sb-card-grid-item__meta-cat-text-dummy {
  color: var(--color--link);
  left: 0;
  position: absolute;
  top: 100%;
}

.sb-card-grid-item__image-fit {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.sb-card-grid-item__image-contain {
  padding-top: $article-hero-ratio-lg;
  transform: scale(1);
  transition: $post-grid-item-image-transition;
  will-change: transform;

  .sb-card-grid-item__link:hover & {
    transform: scale(1.1);
  }
}

.is-style-1-col-page-header {
  .sb-card-grid-item__image-contain {
    padding-top: $sb-card-grid-image-ratio;
  }
}

.sb-card-grid-item__title {
  @include responsive-text-size(var(--spacing-20), var(--spacing-24));
  color: var(--color--black);
  font-family: var(--font-family--sodo);
  font-weight: var(--font-weight--medium);
  line-height: 1.33;
  margin: 0;
  text-align: left;
  transition: color $sb-base-transition;
}

.is-homepage {
  .sb-card-grid-item__title {
    font-family: var(--font-family--lander);
  }
}

.lang-ja {
  .sb-card-grid-item__title {
    font-family: var(--font-family--japan);
  }
  .is-homepage {
    .sb-card-grid-item__title {
      font-family: var(--font-family--japan);
    }
  }
}

.sb-card-grid-item__title--originals {
  @include responsive-text-size(var(--spacing-20), var(--spacing-24));
}

.sb-post-grid-item__published,
.post-grid-item__published {
  color: var(--color--gray-16);
  font-size: var(--font-size--14);
  line-height: 1.78;
  margin-bottom: 0;
  margin-top: auto;
  padding-top: var(--spacing-30);
  text-transform: capitalize;
}

// Title over image style variations
.is-style-2-col-title-over-image,
.is-style-3-col-title-over-image {
  .sb-card-grid-item__image-contain {
    position: relative;

    &:after {
      background: linear-gradient(transparent 30%, rgba(0, 0, 0, 0.6));
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .sb-card-grid-item {
    @media (max-width: $sb-breakpoint-sm) {
      margin-left: 0;
      margin-right: 0;
      width: calc(100% + 8px);
    }
  }

  .sb-card-grid-item__link {
    display: block;
    overflow: hidden;
    position: relative;
  }

  .sb-card-grid-item__title-contain {
    position: absolute;
    bottom: 0;
    padding: var(--spacing-20);
    text-align: center;
    top: auto;
    width: 100%;
  }

  .sb-card-grid-item__title {
    color: var(--color--white);
  }

  .sb-card-grid-item__meta-cat,
  .sb-post-grid-item__published {
    display: none;
  }
}

.is-style-2-col-title-over-image {
  .sb-card-grid-item__title-contain {
    @media (min-width: $sb-breakpoint-xl) {
      padding: var(--spacing-20) var(--spacing-20) var(--spacing-40);
    }
  }

  .sb-card-grid-item {
    @media (min-width: $sb-breakpoint-sm) {
      @include post-grid-item-flex(100%);
    }

    @media (min-width: $sb-breakpoint-lg) {
      @include post-grid-item-flex(50%, var(--spacing-12));
    }
  }
}

.is-style-3-col-title-over-image {
  .sb-card-grid-item {
    @media (min-width: $sb-breakpoint-sm) {
      @include post-grid-item-flex(100%);
    }

    @media (min-width: $sb-breakpoint-lg) {
      @include post-grid-item-flex(33.3333%, var(--spacing-20));
    }
  }

  .sb-card-grid-item__title {
    @media (min-width: $sb-breakpoint-lg) {
      font-size: var(--font-size--16);
    }
  }
}

.sb-card-grid.is-style-1-col-page-header {
  @media (min-width: $sb-breakpoint-md) {
    padding-top: var(--spacing-2x);
    margin-bottom: var(--spacing-3x);
  }

  .sb-card-grid-item {
    margin-bottom: 0;
    max-width: calc(100% - #{var(--spacing-halfx)});
    padding-left: var(--spacing-halfx);
    padding-right: var(--spacing-halfx);

    @media (min-width: $sb-breakpoint-md) {
      padding-left: var(--spacing-base);
      padding-right: var(--spacing-base);
      max-width: Min(
        calc(100% - #{var(--spacing-3x)}),
        calc(1440px - #{var(--spacing-4x)})
      );
    }
  }

  .sb-card-grid-item__link {
    &:hover,
    &:focus {
      box-shadow: none;
    }

    @media (min-width: $sb-breakpoint-md) {
      display: flex;
      flex-direction: row;
    }
  }

  .sb-card-grid-item__title-contain {
    padding: var(--spacing-10);

    @media (min-width: $sb-breakpoint-md) {
      padding-top: var(--spacing-40);
      margin-left: var(--spacing-20);
    }
  }

  .sb-card-grid-item__image-fit {
    max-width: 100vw;
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;

    @media (min-width: $sb-breakpoint-md) {
      max-width: 100%;
      left: 0;
      margin-left: 0;
      width: 50%;
      margin-top: var(--spacing-30);
      margin-bottom: calc(-1 * var(--spacing-40));
    }
  }

  .sb-card-grid-item__title {
    @include responsive-text-size(var(--spacing-30), var(--spacing-32));
    font-family: var(--font-family--lander);
    line-height: 1.5;
    margin-bottom: var(--spacing-halfx);
    text-align: center;

    @media (min-width: $sb-breakpoint-md) {
      text-align: left;
    }

    &:hover,
    &:focus {
      color: var(--color--starbucks-accent-green);
    }
  }

  .sb-post-grid-item__published {
    padding: 0;
    text-align: center;

    @media (min-width: $sb-breakpoint-md) {
      text-align: left;
    }
  }

  .sb-post-grid-item__excerpt {
    color: var(--color--black);
    font-size: var(--font-size--18);
    margin-bottom: var(--spacing-30);
    text-align: center;
    line-height: 1.5;

    @media (min-width: $sb-breakpoint-md) {
      text-align: left;
    }
  }

  .sb-post-grid-item__readmore {
    align-items: center;
    display: flex;
    font-size: var(--font-size--14);
    text-transform: uppercase;
    font-weight: var(--font-weight--medium);
    justify-content: center;
    letter-spacing: 4px;

    @media (min-width: $sb-breakpoint-md) {
      justify-content: flex-start;
    }

    &:hover {
      text-decoration: underline;
    }

    &::after {
      background: url('{{basePath}}/images/chevron-right-thin-green.svg')
        no-repeat center center;
      background-size: 16px 14px;
      content: '';
      display: inline-block;
      height: 16px;
      line-height: 1;
      margin-left: 0.5em;
      width: 14px;
    }
  }
}

.lang-ja {
  .sb-card-grid.is-style-1-col-page-header {
    .sb-card-grid-item__title {
      font-family: var(--font-family--japan);
    }
  }
}
