@mixin responsive-text-size(
  $base-size: var(--font-size--18),
  $large-size: var(--font-size--20),
  $breakpoint: $sb-breakpoint-lg
) {
  font-size: $base-size;
  @media screen and (min-width: $breakpoint) {
    font-size: $large-size;
  }
}

@mixin responsive-font-weight(
  $base-weight: var(--font-weight--light),
  $large-weight: var(--font-weight--light),
  $breakpoint: $sb-breakpoint-lg
) {
  font-weight: $base-weight;
  @media screen and (min-width: $breakpoint) {
    font-weight: $large-weight;
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin screen-reader-text {
  border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin svg-fill-override($target-color) {
  circle,
  ellipse,
  line,
  path,
  polygon,
  polyline,
  rect {
    fill: $target-color;
  }
}

@mixin imageCaption {
  color: var(--color-gray-49);
  font-size: var(--font-size--12);
  font-style: italic;
}

@mixin sb_link() {
  border-bottom: 2px solid var(--color--link);
  color: var(--color--link);
  transition: all 0.5s cubic-bezier(0.22, 0.89, 0.57, 1);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color--link-hover-bg);
    border-bottom: 2px solid var(--color--link-hover);
    color: var(--color--link-hover);
    text-decoration: none;
  }
}

@mixin contentNormal {
  margin: var(--spacing-base) auto var(--spacing-1halfx);

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: var(--spacing-3x);
  }
}

@mixin contentWide {
  max-width: $sb-article-content-wide-width;
}

@mixin contentFull {
  margin-left: 0;
  margin-right: 0;
  max-width: $sb-article-content-full-width;
  padding-left: 0;
  padding-right: 0;
}

// Typography
@mixin eyebrowText {
  font-family: var(--font-family--sodo);
  font-size: var(--font-size--14);
  font-style: normal;
  font-weight: var(--font-weight--semibold);
  letter-spacing: 1.5px;
  line-height: 1;
  text-transform: uppercase;
}