// utility variables
$skip-link-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
$skip-link-z-index: 1000;

.screen-reader-text,
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:active) {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.skip-link:focus {
  background-color: var(--color--white);
  border-radius: $sb-border-radius-s;
  box-shadow: $skip-link-box-shadow;
  clip: auto !important;
  color: var(--color--text);
  display: block;
  font-size: var(--font-size--16);
  font-weight: var(--font-weight--medium);
  height: auto;
  left: var(--spacing-10);
  line-height: normal;
  padding: var(--spacing-10);
  text-decoration: none;
  top: var(--spacing-10);
  width: auto;
  z-index: $skip-link-z-index;
}