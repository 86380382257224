.is-style-subheading {
  color: var(--color--gray-56);
  font-family: var(--font-family--sodo);
  font-size: 2rem;
  line-height: 1.38;
  text-align: center;
}

.lang-ja {
  .is-style-subheading {
    font-family: var(--font-family--japan);
  }
}
