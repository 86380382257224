.download-button {
  .download-image-button__button {
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #008248;
    cursor: pointer;
    height: var(--spacing-35);
    width: var(--spacing-35);
  }

  .download-image-button__wrapper {
    display: block;
    position: relative;
    width: $social-trigger-width;

    @media (min-width: $sb-breakpoint-md) {
      width: $social-trigger-width-lg;
    }
  }

  .download-image-button__download-icon {
    fill: none;
    height: var(--spacing-18);
    margin-right: var(--spacing-10);
    stroke: currentColor;
    width: var(--spacing-18);
  }

  .sb-social-share__list {
    bottom: calc(100% + var(--spacing-10));
    left: 0;
    right: auto;
    padding: var(--spacing-20) var(--spacing-26);
    z-index: 0;
    font-size: initial;

    @media (min-width: $sb-breakpoint-md) {
      left: -1.562rem;
    }

    &:after {
      left: 0.469rem;
      right: auto;

      @media (min-width: $sb-breakpoint-md) {
        left: var(--spacing-30);
      }
    }
  }

  .sbx-download-cta {
    white-space: nowrap;
  }

  .download-image-button__close svg {
    transform: rotate(45deg);
  }
}

.wp-block-image {

  .download-button {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .download-image-button {
    position: static;
  }
}

.wp-block-gallery {
  .download-image-button {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
