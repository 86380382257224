// Variables
$social-trigger-width: var(--spacing-2x);
$social-trigger-width-lg: var(--spacing-3x);
$social-share-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
$social-share-box-shadow-hover: 0 5px 15px rgba(0, 0, 0, 0.15);
$social-share-item-box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
$social-share-list-padding-sm: var(--spacing-10) var(--spacing-8) var(--spacing-13);
$social-share-list-padding-lg: var(--spacing-15) 1.062rem var(--spacing-18);
$social-share-contentwidth: calc(
  100vw - var(--spacing-80) - #{var(--spacing-4x)} - #{var(--spacing-32)}
);
$social-share-contentgutter: calc(
  ((100vw - #{var(--spacing-4x)} - #{var(--spacing-4x)} - #{var(--spacing-32)})) + #{var(--spacing-3halfx)}
);
$social-share-contentgutter-lg: calc(
  ((100vw - #{var(--spacing-4x)} - #{var(--spacing-4x)} - #{var(--spacing-32)})) + #{var(--spacing-3halfx)} -
    ((100vw - #{$sb-max-width}) * 0.5)
);

.sb-social-stick {
  left: calc(100% - #{$social-trigger-width} + var(--spacing-5));
  padding-bottom: var(--spacing-60);
  position: absolute;
  z-index: 5;

  @media (min-width: $sb-breakpoint-md) {
    left: calc(100% - #{$social-trigger-width} + var(--spacing-8));
  }
}

.sb-social-stick--sticky {
  left: calc(100% - #{$social-trigger-width} - #{var(--spacing-10)});
  @media (min-width: $sb-breakpoint-md) {
    left: $social-share-contentgutter;
  }

  @media (min-width: $sb-max-width) {
    left: $social-share-contentgutter-lg;
  }
}

.sb-social-share {
  margin-left: 0;
  right: 0;

  .sb-social-share__wrapper {
    display: block;
    position: relative;
    width: $social-trigger-width;

    @media (min-width: $sb-breakpoint-md) {
      width: $social-trigger-width-lg;
    }
  }

  .sb-social-share__button {
    align-items: center;
    background: var(--color--white);
    border: $sb-border-width-focus solid transparent;
    border-radius: 50%;
    box-shadow: $social-share-box-shadow;
    color: var(--color--link);
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-size: 0.625rem;
    height: $social-trigger-width;
    justify-content: center;
    transition: all $sb-base-transition;
    width: $social-trigger-width;

    &:hover,
    &:active,
    &:focus {
      box-shadow: $social-share-box-shadow-hover;
      color: var(--color--link-hover);
    }

    &:focus {
      border: $sb-border-width-focus solid var(--color--gray-25);
      outline: none;
    }

    .sb-icon {
      height: 3em;
      width: 3em;
    }

    @media (min-width: $sb-breakpoint-md) {
      height: $social-trigger-width-lg;
      width: $social-trigger-width-lg;
    }
  }

  .sb-social-share__list {
    align-items: center;
    background: var(--color--white);
    border-radius: var(--spacing-8);
    bottom: calc(100% + 1.562rem);
    box-shadow: $social-share-item-box-shadow;
    display: flex;
    flex-flow: column nowrap;
    font-size: var(--font-size--28);
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: $social-share-list-padding-sm;
    position: absolute;
    right: -2px;
    transition: opacity 350ms;
    z-index: -1;

    pointer-events: none;

    .article-sticky-social.-viewed & {
      animation: opacity-animation 4s;
      animation-iteration-count: 1;
    }

    &:after {
      border: var(--spacing-10) solid transparent;
      border-top: var(--spacing-10) solid var(--color--white);
      content: '';
      height: 0;
      position: absolute;
      right: 0.781rem;
      top: 100%;
      width: 0;
    }

    @media (min-width: $sb-breakpoint-md) {
      padding: $social-share-list-padding-lg;
      right: 0;

      &:after {
        right: var(--spacing-base);
      }
    }
  }

  .sb-social-share__item {
    display: block;
    opacity: 0;
    transform: translateY(75%);
    transition: opacity 0s 350ms linear, transform 0s 350ms,
      color $sb-base-transition;
  }

  .article-sticky-social.-viewed &,
  &.sb-social-share--open {
    .sb-social-share__item {
      transition: opacity 350ms linear,
        transform 500ms cubic-bezier(0.22, 0.89, 0.57, 1),
        color $sb-base-transition, color $sb-base-transition;

      &:nth-child(1) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 75ms;
      }

      &:nth-child(2) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 150ms;
      }

      &:nth-child(3) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 225ms;
      }

      &:nth-child(4) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 300ms;
      }

      &:nth-child(5) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 375ms;
      }

      &:nth-child(6) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 450ms;
      }

      &:nth-child(7) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 525ms;
      }

      &:nth-child(8) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 600ms;
      }

      &:nth-child(9) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 675ms;
      }

      &:nth-child(10) {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 750ms;
      }
    }
  }

  .sb-social-share__link {
    background: none;
    border: none;
    color: var(--color--link);
    cursor: pointer;
    display: block;
    outline: none;
    padding: var(--spacing-10) 0;
    padding-top: var(--spacing-6);
    transition: color $sb-base-transition;

    &:hover,
    &:active,
    &:focus {
      border: none;
      color: var(--color--link-hover);
      outline: none;
    }

    &:focus {
      .sb-icon {
        outline: $sb-border-width-focus solid var(--color--gray-25);
      }
    }

    .sb-icon {
      vertical-align: middle;
    }
  }

  .sb-social-share__link--facebook {
    color: var(--color--facebook);

    &:hover,
    &:active,
    &:focus {
      color: rgba(var(--color--facebook), 0.9);
    }
  }

  .sb-social-share__link--twitter {
    color: var(--color--twitter);

    &:hover,
    &:active,
    &:focus {
      color: rgba(var(--color--twitter), 0.9);
    }
  }

  .sb-social-share__link--linkedin {
    color: var(--color--linkedin);

    &:hover,
    &:active,
    &:focus {
      color: rgba(var(--color--linkedin), 0.9);
    }
  }

  &.sb-social-share--open {
    .sb-social-share__list {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
    pointer-events: auto;
  }

  75% {
    opacity: 1;
    pointer-events: auto;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}
