.earth-month {
  background: var(--color--cream) url("/images/em-background.png") no-repeat top center;
  background-size: 100%;

  .site-main {
    background-color: transparent;
  }

  .site-main {
    margin-bottom: 0;
    position: relative;
  }

  .sb-site-footer {
    background: #fff;
    z-index: 99;
    width: 100vw;
  }

  .article-hero,
  .article-header-separator {
    display: none;
  }
}

.earth-month__content {
  h1, h2, h3 {
    color: var(--color--starbucks-house-green);
    @include responsive-text-size(var(--spacing-24), var(--spacing-30));
    font-weight: var(--font-weight--regular);
  }

  p, li {
    color: var(--color--starbucks-house-green);
  }
}

.earth-month__small-print {
  background: var(--color--white);
  margin: 0 calc(50% - 50vw);
  padding: var(--spacing-20) var(--spacing-10) var(--spacing-40);
  position: relative;
  width: 100vw;

  p {
    font-size: var(--font-size--12);
    line-height: 1.3;
  }

}

.earth-month__smaller-text {
  font-size: var(--font-size--14);
  line-height: var(--spacing-20);
}

.earth-month__small-print-outer {
  max-width: 1440px;
  margin: 0 auto;
}

.earth-month__small-print-wrap {

  padding: 0 var(--spacing-10);
}

.earth-month__articles-wrap {
  max-width: 1440px;
  margin: 0 auto;
}

.earth-month__articles {
  background: var(--color--white);
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding: 1px var(--spacing-30) 40px;
  position: relative;
  z-index: 1;

  .article {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  .article-header {
    display: none;
  }

  .content--article {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 var(--spacing-10);
    width: 100%;
  }

  .article__content > * {
    padding-left: 0;
    padding-right: 0;
  }
}

.earth-month__wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: $sb-max-width;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
