// To do: remove negative margins once CardGrid is rewritten.
.sbx-cs-archive__cards .sb-card-grid {
  margin-left: -10px;
  margin-right: -10px;
}

.sbx-cs-archive__footer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-40);
}