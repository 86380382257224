.sb-input-autosuggest__input-wrap {
  position: relative;
}

.sb-input-autosuggest__label {
  display: inline-block;
  margin-bottom: var(--spacing-8);
}

.sb-input-autosuggest__input {
  appearance: none;
  background-color: var(--color--white);
  background-position: right 20px bottom 50%;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  border-radius: 100px;
  border: 1px solid var(--color--starbucks-green);
  color: var(--color--starbucks-green);
  justify-content: flex-start;
  max-width: 100%;
  padding: var(--spacing-15) var(--spacing-40) var(--spacing-15) var(--spacing-20);
  position: relative;
  width: 100%;
}

.sb-input-autosuggest__caret-icon {
  color: var(--color--starbucks-green);
  position: absolute;
  bottom: 50%;
  right: var(--spacing-16);
  transform: translateY(50%);
}


.sb-input-autosuggest__caret-icon,
.sb-input-autosuggest__caret-icon svg {
  display: block;
  height: var(--spacing-10);
  width: var(--spacing-16);
}

.sb-input-autosuggest .react-autosuggest__suggestions-container {
  background-color: var(--color--white);
  border: 2px solid var(--color--starbucks-green);
  display: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.sb-input-autosuggest .react-autosuggest__suggestions-container--open {
  display: block;
}

.sb-input-autosuggest .react-autosuggest__suggestions-list {
  list-style: none;
  margin-bottom: 0;
  padding: var(--spacing-8) 0;
}

.sb-input-autosuggest .react-autosuggest__suggestion button {
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  cursor: pointer;
  padding: var(--spacing-8) var(--spacing-16);
  text-align: left;
  width: 100%;
  color: var(--color--black);

  &:hover,
  &:focus {
    background-color: var(--color--starbucks-green);
    color: var(--color--white);
    outline: none;
  }
}

.sb-input-autosuggest .react-autosuggest__suggestion--highlighted button {
  background-color: var(--color--starbucks-green);
  color: var(--color--white);
  outline: none;
}
