.article--hingakawa .article-hero__image-contain {
  padding-top: 100%;

  @media (min-width: $sb-breakpoint-md) {
    padding-top: 63.6666667%;
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding-top: 51.6666667%;
  }
}

.hingakawa-production__content-hero {
  max-width: var(--w-content-max);
  margin-inline: auto;
}

.hingakawa-production__tabs {
  margin-bottom: 0;
  margin-top: var(--spacing-60);
}

.hingakawa-production__tab-controls {
  display: flex;
  justify-content: center;

  button {
    /* reset */
    border: 0;
  }

  button {
    background: transparent;
    border-bottom: 4px solid transparent;
    color: var(--color--black);
    font-size: 0.938rem;
    font-weight: var(--font-weight--medium);
    letter-spacing: 0.28em;
    line-height: 1.68;
    margin: 0 var(--spacing-16) var(--spacing-32) var(--spacing-16);
    padding: 0 0.125rem 0;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-bottom-color: var(--color--starbucks-green);
      color: var(--color--starbucks-green);
    }
  }
}

.hingakawa-production__hero-overlay {
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.hingakawa-production__content-wrap {
  margin-top: var(--spacing-40);
}

.hingakawa-production__video {
  aspect-ratio: 16 / 9;
}

.hingakawa-production__buttons {
  display: flex;
  flex-flow: column nowrap;
  margin: var(--spacing-10) auto;

  button + button {
    margin-top: var(--spacing-10);
  }

  .sb-button {
    padding: var(--spacing-12) var(--spacing-40);
  }
}

.lang-ja {
  .article--hingakawa {
    .article__content > h2 {
      font-family: var(--font-family--japan);
    }
  }
}