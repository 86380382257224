$sb-pullquote-margin: var(--spacing-base) + var(--spacing-quarterx);

.wp-block-pullquote.is-style-solid-background,
.wp-block-pullquote.is-style-with-icon {

  blockquote {
    margin-top: $sb-pullquote-margin;
    margin-bottom: $sb-pullquote-margin;
    padding: var(--spacing-10) var(--spacing-15);

    @media (min-width: $sb-breakpoint-md) {
      padding: var(--spacing-3x) 0 var(--spacing-1halfx);
    }

    &:before {
      background: none;
      color: var(--color--starbucks-accent-green);
      content: '“';
      display: block;
      font-family: var(--font-family--lander);
      font-size: 5rem;
      font-weight: var(--font-weight--regular);
      height: var(--spacing-40);
      line-height: 0.8;
      margin-bottom: var(--spacing-30);
      position: relative;
      text-align: center;
      width: 100%;
    }
  }

  &.alignleft blockquote,
  &.alignright blockquote {
    @media (min-width: $sb-breakpoint-lg) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  &.alignleft p,
  &.alignright p {
    font-size: 1.438rem;
  }

  &.alignleft cite,
  &.alignright cite {
    font-size: var(--font-size--12);
  }

  &.alignfull blockquote {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &.alignright {
    p {
      text-align: right;
    }
  }

  p {
    @include responsive-text-size(
      $base-size: var(--spacing-22),
      $large-size: var(--spacing-28)
    );
    color: var(--color--black);
    display: block;
    font-family: var(--font-family--lander);
    font-size: 1.438rem;
    font-weight: var(--font-weight--light);
    letter-spacing: normal;
    line-height: 1.4;
    margin: 0 auto;
    text-align: center;
    text-transform: none;
    position: relative;
    z-index: 5;
  }

  cite {
    color: var(--color--starbucks-accent-green);
    display: block;
    font-family: var(--font-family--sodo);
    font-size: var(--font-size--12);
    font-style: normal;
    font-weight: var(--font-weight--regular);
    letter-spacing: 2px;
    line-height: 1.5;
    margin-top: var(--spacing-1halfx);
    text-align: center;
    text-transform: none;

    &:before {
      content: '-';
    }

    @media (min-width: $sb-breakpoint-md) {
      font-size: var(--font-size--16);
    }
  }
}

.wp-block-column {
  .wp-block-pullquote.is-style-with-icon {
    blockquote {
      @media (min-width: $sb-breakpoint-md) {
        padding: 0 var(--spacing-2x) 0;
      }
    }
  }
}


.wp-block-pullquote.is-style-solid-background,
.wp-block-pullquote.is-style-with-icon {
  p,
  cite {
    font-family: var(--font-family--japan);
  }
}

