.video-heading {
  height: 40.375rem;
  margin-left: calc(-1 * var(--spacing-10));
  margin-right: calc(-1 * var(--spacing-10));
  margin-top: calc(-1 * var(--spacing-20));
  padding-bottom: 58%;
  position: relative;
  width: 100%;
  width: calc(100% + var(--spacing-20)) !important;

  @media (min-width: $sb-breakpoint-md) {
    margin-left: calc(-1 * var(--spacing-50));
    margin-right: calc(-1 * var(--spacing-50));
    padding-bottom: 62.5%;
    width: calc(100% + var(--spacing-100)) !important;
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding-bottom: 62.2%;
  }

  @media (min-width: $sb-breakpoint-xl) {
    padding-bottom: 60.4%;
  }
}

.video-heading .wistia_embed {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.video-heading + .content--article {
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.18);
  margin-top: -4%;
  z-index: 10;

  @media (min-width: $sb-breakpoint-md) {
    margin-top: -2.875rem;
  }
}
