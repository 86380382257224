.sbx-video-story {
  padding-block: var(--spacing-24);

  @media (min-width: $sb-breakpoint-lg) {
    padding-block: var(--spacing-40);
  }

  @media (min-width: $sb-breakpoint-xl) {
    padding-block: var(--spacing-64);
  }
}

.sbx-video-story--vertical .wp-block-group {
  padding-inline: 0;
}

.sbx-video-story__columns {

  @media (min-width: $sb-breakpoint-lg) {
    gap: var(--spacing-40);
  }
}

.sbx-video-story :is(h2, p, .wp-block-embed) {
  margin-block: 0;
}

.sbx-video-story__columns .wp-block-column + .wp-block-column {
  margin-block-start: var(--spacing-16);
}

.sbx-video-story :is(.wp-block-column, .column-content, .article__content) > * + * {
  margin-block-start: var(--spacing-16);
}

.sbx-video-story :is(.wp-block-column, .column-content, .article__content) > * + h2 {
  margin-block-start: var(--spacing-24);
}