.sbx-overlay-card {
  aspect-ratio: 3 / 4;
  background-color: var(--color--gray-97);
  cursor: pointer;
  position: relative;
}

.sbx-overlay-card__image {
  aspect-ratio: 3 / 4;
}

.sbx-overlay-card__content {
  background: rgba(30, 57, 50, 0.80);
  bottom: var(--spacing-24);
  display: flex;
  flex-direction: column;
  height: 170px;
  left: var(--spacing-24);
  max-height: calc(100% - var(--spacing-48));
  padding: var(--spacing-24);
  position: absolute;
  right: var(--spacing-24);
}

.sbx-overlay-card__title {
  color: var(--color--white);
  display: -webkit-box;
  font-family: var(--font-family--lander);
  font-size: var(--font-size--24);
  font-style: normal;
  font-weight: var(--font-weight--regular);
  line-height: 1.25;
  margin: 0;
  overflow: hidden;

  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 3;
}

.sbx-overlay-card__cta {
  margin-top: auto;
}

.sbx-overlay-card__cta a {
  color: var(--color--white);
}