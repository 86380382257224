$sb-single-row-breakpoint: $sb-breakpoint-md;

@supports (object-fit: cover) {
  // single row gallery does not play well in ie due to object-fit combined with display: flex;
  // this is an approach to minimize issues and only display the single row as designed if object-fit supported
  .wp-block-gallery.is-style-single-row .wp-block-gallery__inner {
    align-items: center;
    flex-flow: row wrap;
    width: 100%;

    @media (min-width: $sb-single-row-breakpoint) {
      display: flex;
      flex-flow: row nowrap !important;
    }
  }

  .wp-block-gallery.is-style-single-row.is-cropped {
    align-items: stretch;
  }

  .wp-block-gallery.is-style-single-row .wp-block-image:nth-child(n),
  .wp-block-gallery.is-style-single-row .blocks-gallery-item:nth-child(n) {
    margin-right: var(--spacing-10);
    width: auto;

    @media (max-width: $sb-single-row-breakpoint - 1) {
      margin-right: 0;
    }
  }

  .wp-block-gallery.is-style-single-row .wp-block-image:last-child,
  .wp-block-gallery.is-style-single-row .blocks-gallery-item:last-child {
    margin-right: 0;
  }

  .wp-block-gallery.is-style-single-row .wp-block-image figure,
  .wp-block-gallery.is-style-single-row .blocks-gallery-item figure {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .wp-block-gallery.is-style-single-row .wp-block-image img,
  .wp-block-gallery.is-style-single-row .blocks-gallery-item img {
    flex-grow: 1;
    height: 100%;
    max-width: 100%;
    min-width: 0;
    object-fit: fill;
    padding: 0;
    width: 100%;
  }

  .wp-block-gallery.is-style-single-row.is-cropped .wp-block-image img,
  .wp-block-gallery.is-style-single-row.is-cropped .blocks-gallery-item img {
    object-fit: cover;
  }

  .wp-block-gallery.is-style-single-row .wp-block-image figcaption,
  .wp-block-gallery.is-style-single-row .blocks-gallery-item figcaption {
    display: none;
  }

}
