// Text colors.
.has-base-color {
  --color--text: var(--color--white);
}

.has-contrast-color {
  --color--text: var(--color--black);
}

.has-gray-97-color {
  --color--text: var(--color--gray-97);
}

.has-starbucks-green-color {
  --color--text: var(--color--starbucks-green);
}

.has-starbucks-accent-green-color {
  --color--text: var(--color--starbucks-accent-green);
}

.has-starbucks-house-green-color {
  --color--text: var(--color--starbucks-house-green);
}

.has-starbucks-light-green-color {
  --color--text: var(--color--starbucks-light-green);
}

// Background colors.
.has-base-background-color {
  --color--background: var(--color--white);
}

.has-contrast-background-color {
  --color--background: var(--color--black);
}

.has-gray-97-background-color {
  --color--background: var(--color--gray-97);
}

.has-starbucks-green-background-color {
  --color--background: var(--color--starbucks-green);
}

.has-starbucks-accent-green-background-color {
  --color--background: var(--color--starbucks-accent-green);
}

.has-starbucks-house-green-background-color {
  --color--background: var(--color--starbucks-house-green);
}

.has-starbucks-light-green-background-color {
  --color--background: var(--color--starbucks-light-green);
}

.has-background-color {
  background-color: var(--color--background) !important;
}

.has-text-color {
  --color--heading: var(--color--text);
  --color--link-hover-bg: transparent;
  --color--link-hover: var(--color--text);
  --color--link: var(--color--text);
  color: var(--color--text) !important;
}