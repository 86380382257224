// Variables
$sb-container-max-width: 33.75rem;
$sb-container-gutter: var(--spacing-10);
$sb-content-narrow-max-width: 53.75rem;

@mixin sb-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: $sb-container-gutter;
  padding-right: $sb-container-gutter;
  width: 100%;
}

.sb-container {
  @include sb-container;
  @media (min-width: $sb-breakpoint-sm) {
    max-width: $sb-container-max-width;
  }
}

.sb-container--fluid {
  @include sb-container;
}

.sb-content-wrap {
  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(#{$sb-article-content-max-width} + #{var(--spacing-40)});
}

.sb-content-wrap--404 {
  display: block;
  overflow: hidden;
  padding-bottom: var(--spacing-5x);
  width: calc(100% - #{var(--spacing-2x)});

  @media (min-width: $sb-breakpoint-lg) {
    width: 66.6666%;
  }
}

.sb-content-wrap--narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: $sb-content-narrow-max-width;

  @media (min-width: $sb-breakpoint-md) {
    width: calc(100% - #{var(--spacing-4x)});
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.sb-content-wrap--mobile-narrow {
  @media (max-width: $sb-breakpoint-md - 1) {
    width: 100%;
  }
}

.sb-content-wrap--mobile-full {
  @media (max-width: $sb-breakpoint-md - 1) {
    margin-left: calc(-1 * var(--spacing-10));
    margin-right: calc(-1 * var(--spacing-10));
    width: calc(100% + #{var(--spacing-base)});
  }
}

.sb-content-wrap--mobile-full .sb-layout__block {
  @media (max-width: $sb-breakpoint-md - 1) {
    padding-left: var(--spacing-base);
    padding-right: var(--spacing-base);
  }
}

.sb-section-block {
  margin-bottom: var(--spacing-base);
  position: relative;

  @media (min-width: $sb-breakpoint-md) {
    margin-bottom: var(--spacing-2x);
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: var(--spacing-4x);
  }
}

.sb-section-block.multimedia-single {
  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: var(--spacing-3x);
  }
}

.page-container--404 {
  background-color: var(--color--white);
  background-position: 137% 10rem;
  background-repeat: no-repeat;
  height: 100%;
  margin-bottom: calc(-1 * var(--spacing-5x));
  margin-left: calc(-1 * var(--spacing-10));
  position: relative;
  width: calc(100% + #{var(--spacing-base)});

  &:after {
    // solve for container full height / width background in ie
    background-color: var(--color--white);
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  @media (max-width: $sb-breakpoint-md - 1) {
    background-image: none !important;
  }

  @media (min-width: $sb-breakpoint-lg) {
    background-position: 90% 10rem;
  }
}
