.shareable-highlight {
  background-color: #e4e4e4;
  box-shadow: 0 0 0 7px #e4e4e4;

  .sb-social-share .sb-social-share__wrapper {
    display: inline-block;
    width: auto;
  }

  .sb-social-share .sb-social-share__button {
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: var(--spacing-35);
    transform: translate3d(0, var(--spacing-5), 0);
    width: var(--spacing-35);
  }
}
