.press-photos-image {
  position: relative;
}

.press-photos-image__tool-link {
  width: var(--spacing-24);
  height: var(--spacing-24);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.press-photos-image__tool-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.press-photos-image__tool-link svg {
  display: block;
  height: var(--spacing-16);
  width: var(--spacing-16);
}

.press-photos-image__tool-link--facebook {
  color: #587cb9;
}

.press-photos-image__tool-link--linkedin {
  color: #1178b3;
}

.press-photos-image__wrap {
  display: block;
  position: relative;
  aspect-ratio: 1;
}

.press-photos-image__trigger {
  cursor: pointer;
  background: var(--color--white);
  border: none;
  bottom: 0;
  color: var(--color--starbucks-green);
  height: var(--spacing-32);
  left: 0;
  position: absolute;
  height: var(--spacing-32);
  z-index: 1;
  padding: 0;
  width: var(--spacing-32);
  display: flex;
  align-items: center;
  justify-content: center;
}

.press-photos-image__trigger svg {
  display: block;
  height: var(--spacing-16);
  width: var(--spacing-16);
  transition: transform 0.2s ease-in-out;
}

.press-photos-image__trigger--open svg {
  transform: rotate(45deg);
};

.press-photos-image__tools {
  align-items: center;
  background: var(--color--white);
  border-radius: 4px;
  bottom: var(--spacing-40);
  box-shadow: $sb-box-shadow;
  display: flex;
  gap: var(--spacing-8);
  left: calc(-1 * var(--spacing-4));
  margin: 0;
  padding: var(--spacing-12) var(--spacing-16);
  position: absolute;
  z-index: 1;

  &[aria-hidden='true'] {
    display: none;
  }

  &:after {
    background: var(--color--white);
    bottom: calc(-1 * var(--spacing-4));
    content: '';
    display: block;
    height: var(--spacing-12);
    left: var(--spacing-16);
    position: absolute;
    transform: rotate(45deg);
    width: var(--spacing-12)
  }
}

.press-photos-image__tool {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;

  &::before {
    display: none !important;
  }
}

.press-photos-image__tool .sbx-download-cta {
  color: var(--color--black);
}