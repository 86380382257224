.sbx-download-cta {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size--18);
  font-weight: var(--font-weight--regular);
  letter-spacing: 2px;
  padding: 0;
  text-transform: uppercase;
}

.sbx-download-cta__icon {
  height: 1em;
  margin-right: var(--spacing-8);
  vertical-align: -2px;
  width: 1em;
}