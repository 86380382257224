.article-meta {
  @include responsive-text-size(var(--spacing-16), var(--spacing-18));
  color: var(--color--gray-16);
  text-align: center;

  p {
    display: inline-block;
    margin: 0;
  }

  span {
    margin: 0 var(--spacing-10);
  }

    .article-meta__read-time {
        margin-left: 0;
    }
}

.lang-ja .article-meta__read-time {
    display: none;
}
