// Variables
$search-input-color: var(--color--gray-16);
$search-font-size: var(--font-size--28);
$search-input-border: 2px solid var(--color--gray-16);
$search-input-padding: var(--spacing-14) var(--spacing-48) var(--spacing-12) var(--spacing-48);
$search-icon-size: var(--spacing-23);
$search-icon-size-l: var(--spacing-12);
$search-form-nav-height-l: var(--spacing-base);
$search-form-nav-margin-l: var(--spacing-35) 0;
$search-input-padding-l: var(--spacing-12) var(--spacing-20) var(--spacing-10) var(--spacing-20);
$search-input-border-l: 1px solid var(--color--gray-16);
$search-results-max-width: 53.75rem;
$is-desktop: 1100px;

.sb-search {
  display: block;
}

.sb-search__form {
  display: flex;
  flex-flow: row nowrap;
  font-size: $search-font-size;
  line-height: 1.2;
  position: relative;
  width: 100%;
}

.sb-search__input, .search-field {
  border: none;
  color: $search-input-color;
  font-family: var(--font-family--lander);
  padding: $search-input-padding;
}

// autocomplete
.sb-search__form > div {
  > input {
    + div {
      box-shadow: 0 var(--spacing-4) var(--spacing-4) rgba(0, 0, 0, 0.25) !important;
      cursor: pointer;
      font-size: var(--font-size--16) !important;
      min-width: 100% !important;
      padding: var(--spacing-5) 0 !important;
      text-align: left !important;
      width: 100% !important;

      div {
        padding: var(--spacing-5) var(--spacing-10) var(--spacing-10) var(--spacing-10);
      }
    }
  }
}

.sb-search__form.-is-input-focus > div {
  > input {
    + div {
      height: auto;
      opacity: 1;
      overflow: auto !important;
    }
  }
}

/* Hide autosuggest for Multimedia search form */
.sb-search__form--multimedia-results.-is-input-focus > div {
  > input {
    + div {
      display: none;
      opacity: 0;
    }
  }
}

.sb-search__input-icon {
  height: $search-icon-size;
  left: 0;
  position: absolute;
  top: calc(50% - 0.719rem);
  width: $search-icon-size;
  z-index: 10;
}

.sb-search--navigation {
  .site-navigation--desktop & {
    @if $use-large-screen == true {
      display: flex;
      flex-flow: column nowrap;
      font-size: var(--font-size--16);
      height: 100%;
      justify-content: center;

      @media (min-width: $sb-breakpoint-lg) {
        max-width: 12.5rem;
      }
    }
  }
  .site-navigation__footer & {
    @media (max-width: $sb-breakpoint-lg) {
      margin-bottom: var(--spacing-36);
    }
  }
}

.sb-search__form--navigation {
  .site-navigation__inner & {
    border-bottom: $search-input-border;
    margin-bottom: 0;
    position: relative;
    transition: margin-bottom $sb-base-transition;
    will-change: transform;
    z-index: 10;

    input {
      font-weight: var(--font-weight--light);
      opacity: 0;
      position: relative;
      transform: translateX(calc(-1 * var(--spacing-20)));
      transition: transform 0.3s ease-out 0.2s, opacity 0.6s ease-out 0.29s;

      @media (min-width: $sb-breakpoint-lg) {
        font-size: var(--font-size--14);
        max-width: 12.5rem;
      }
    }

    &:after {
      background: var(--color--white);
      bottom: 0;
      bottom: -2px;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: 0;
      transition: width 0.4s ease-out 0.2s;
      width: 100%;
    }
  }

  &.-is-input-focus {
    margin-bottom: 13.75rem;

    .site-navigation__drawer & {
      margin-bottom: 0;
    }
  }

  .site-navigation__inner[aria-hidden='false'] & {
    input {
      opacity: 1;
      transform: translateX(0);
    }

    &:after {
      width: 0;
    }
  }

  .site-navigation--desktop & {
    @if $use-large-screen == true {
      border-bottom: none;
      font-size: var(--font-size--16);

      .sb-search__input-icon {
        height: $search-icon-size-l;
        top: var(--spacing-15);
        width: $search-icon-size-l;
      }

      .sb-search__input {
        border-bottom: $search-input-border-l;
        padding: $search-input-padding-l;
      }

      &.-is-input-focus {
        margin-bottom: 0;
      }
    }
  }
}

// Search submit
.sb-search-submit {
  background: transparent;
  border: none;
  border-radius: 0;
  bottom: 0;
  color: $search-input-color;
  padding: var(--spacing-10);
  position: absolute;
  right: 0;
  top: 0;

  .sb-icon {
    height: var(--spacing-20);
    position: relative;
    width: var(--spacing-12);
    z-index: 10;

    @media (min-width: $is-desktop) {
      height: var(--spacing-10);
      width: var(--spacing-6);
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: var(--color--black);
  }

  &:focus {
    outline: none;
    background: var(--color--black);
    color: var(--color--white);
  }
}

.sb-search--results,
.sb-search--multimedia-results {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-40);
}

.sb-search--results,
.sb-search--multimedia-results {
  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: var(--spacing-80);
  }
}

.sb-search__form--results,
.sb-search__form--multimedia-results {
  background: var(--color--white);
  box-shadow: 0 var(--spacing-4) var(--spacing-4) rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  max-width: $search-results-max-width;

  & > div:not(.sb-search-filters-legacy) {
    flex-basis: 100%;
    width: 100%;
  }
}

.sb-search__form--results {
  flex-wrap: wrap;
}

.sb-search__form--results .sb-search-submit--results,
.sb-search__form--multimedia-results .sb-search-submit--multimedia-results {
  background: transparent;
  flex-basis: var(--spacing-80);
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: var(--spacing-80);

  &:hover,
  &:active,
  &:focus {
    &:after {
      background-color: var(--color--link-hover);
    }
  }

  &:after {
    background-color: var(--color--starbucks-green);
    border: 0;
    left: 0;
    right: 0;
    width: var(--spacing-80);
  }
}

.sb-search__form--results .sb-search__input--results, .search-field,
.sb-search__form--multimedia-results .sb-search__input--multimedia-results {
  font-family: var(--font-family--sodo);
  font-size: var(--font-size--22);
  line-height: 1.4;
  padding: var(--spacing-18) 2.062rem;

  &:focus,
  &:active {
    outline-offset: -0.156rem;
  }
}

.sb-search__form--results .sb-search__input-icon,
.sb-search__form--multimedia-results .sb-search__input-icon {
  @include svg-fill-override(var(--color--black));
  height: var(--spacing-20);
  left: auto;
  margin: 0 auto;
  position: relative;
  top: auto;
  width: var(--spacing-20);
}

.search-results__filter-wrapper {
  margin-left: auto;
}

.search-results__heading {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;

  @media (min-width: $sb-breakpoint-lg) {
    align-items: flex-end;
    flex-direction: row;
  }
}

.search-results__total {
  font-weight: var(--font-weight--regular);
  font-size: var(--font-size--14);
  padding-bottom: var(--spacing-15);
}

div.ep-autosuggest {
  > ul > li.autosuggest-item {
    padding: var(--spacing-50) var(--spacing-10) var(--spacing-10) var(--spacing-10);
    list-style-type: none;

    &:hover,
    &:focus {
      background: lightgray;
    }
  }

  > ul {
    padding: 0;
  }

}

.site-navigation__footer {

  div.ep-autosuggest {
    background-color: var(--color--white);
    max-height: 200px;
    overflow: auto;
    position: absolute;
    z-index: 9;
  }

  .sb-search {
    @media (max-width: $sb-breakpoint-lg) {
      margin-bottom: var(--spacing-36);
    }
  }

  .search-field {
    border-bottom: 2px solid #292929;
    border-radius: 0;
    font-family: var(--font-family--lander);
    font-size: 100%;
    font-weight: 300;
    line-height: 1.4;
    padding: var(--spacing-14) var(--spacing-48) var(--spacing-12);
    -webkit-appearance: none;

    @media (min-width: $sb-breakpoint-lg) {
      border-width: 1px;
      font-size: var(--font-size--14);
      max-width: 12.5rem;
      padding: var(--spacing-12) var(--spacing-20) var(--spacing-10);
    }
  }

  .sb-icon {
    display: block;
    fill: currentColor;
    left: 0;
    position: absolute;
    z-index: 10;
  }

  .sb-search__input-icon {
    height: var(--spacing-23);
    left: 0;
    position: absolute;
    top: calc(50% - 0.719rem);
    width: var(--spacing-23);
    z-index: 10;

    @media (min-width: $sb-breakpoint-lg) {
      height: var(--spacing-12);
      top: 1.062rem;
      width: var(--spacing-12);
    }
  }

  .sb-search-submit {
    background: transparent;
    border: none;
    border-radius: 0;
    bottom: 0;
    color: rgba(0,0,0,.56);
    cursor: pointer;
    padding: var(--spacing-10);
    position: absolute;
    right: 0;
    top: 0;

    &:focus {
      outline: none;
      background: var(--color--black);
      color: var(--color--white);
    }

    & .sb-icon {
      height: var(--spacing-20);
      position: relative;
      width: var(--spacing-12);
      z-index: 10;

      @media (min-width: $sb-breakpoint-lg) {
        height: var(--spacing-10);
        width: var(--spacing-6);
      }
    }
  }

  .search-field {
    padding-right: calc(var(--spacing-20) + 10px);

    &:focus {
      outline: 2px solid #343a40;
      outline-offset: var(--spacing-10);
    }
  }
}

.sb-search--results {
  .sb-search__input-icon {
    position: absolute;
    right: 2.062rem;
    top: var(--spacing-22);
    z-index: 2;
  }

  .sb-search-submit {
    z-index: 5;

    .sb-search-submit-icon {
      display: none;
    }
  }

  .search-field {
    padding-right: calc(2.062rem + 40px);
  }
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}