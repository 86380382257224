// Using important to override center alignment from .article__content
// TO DO: refactor global content styles to use :where() selector and remove unnecessary specificity
.center,
.text-center,
.has-text-align-center {
  text-align: center !important;
}

.has-text-align-left {
  text-align: left !important;
}

.has-text-align-right {
  text-align: right !important;
}

.fw-thin { font-weight: var(--font-weight--thin) !important }
.fw-light { font-weight: var(--font-weight--light) !important }
.fw-regular { font-weight: var(--font-weight--regular) !important }
.fw-medium { font-weight: var(--font-weight--medium) !important }
.fw-semibold { font-weight: var(--font-weight--semibold) !important }
.fw-bold { font-weight: var(--font-weight--bold) !important }
.fw-black { font-weight: var(--font-weight--black) !important }

.is-style-serif-subhead {
  @include responsive-text-size(var(--spacing-24), var(--spacing-30));

  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--regular);
  line-height: 1.25;
}

.has-drop-cap:first-letter {
  float: left;
  font-size: 12ex;
  font-style: normal;
  line-height: 0.68;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
}
