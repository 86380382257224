$recipe-spacing: var(--spacing-2halfx);
$recipe-breakpoint: $sb-breakpoint-lg;
$recipe-border-color: var(--color--hint-of-red-mid);
$recipe-border-style: 2px solid rgba(0, 0, 0, 0.87);

@mixin recipe-divider {
  content: '';
  display: block;
  border-top: $recipe-border-style;
  padding-top: $recipe-spacing;
  padding-left: $recipe-spacing;
  padding-right: $recipe-spacing;
}

.recipe {
  box-shadow: $sb-box-shadow;
  padding: $recipe-spacing 0 var(--spacing-1halfx);
  display: flex;
  flex-flow: column;
}

.recipe__header,
.recipe__content {
  padding-left: $recipe-spacing;
  padding-right: $recipe-spacing;
}

.recipe__header {
  margin-bottom: var(--spacing-halfx);
  order: 0;
}

.recipe__header .recipe__title {
  color: var(--color--black);
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--light);
  margin-top: 0;
  margin-bottom: var(--spacing-halfx);
  text-align: left;
  line-height: 1.3125;
}

.lang-ja {
  .recipe__header .recipe__title {
    font-family: var(--font-family--japan);
  }
}

.recipe__image {
  background: var(--color--hint-of-red);
  margin-bottom: var(--spacing-2x);
  margin-left: 0;
  margin-right: 0;
  order: 1;
  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}

.recipe__content:before {
  @include recipe-divider;
  @media (min-width: $recipe-breakpoint) {
    flex-basis: 100%;
  }
}

.recipe__content {
  color: var(--color--gray-18);
  font-weight: normal;
  font-size: var(--font-size--18);
  line-height: 1.777;
  order: 2;
  @media (min-width: $recipe-breakpoint) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.recipe__servings,
.recipe .recipe__ingredients-title,
.recipe .recipe__directions-title {
  text-transform: uppercase;
  font-weight: var(--font-weight--medium);
  font-size: var(--font-size--14);
  letter-spacing: 0.12em;
  text-align: left;
  margin: 0 0 (var(--spacing-base) + var(--spacing-quarterx)) 0;
  line-height: 1;
}

.recipe__servings {
  color: var(--color--gray-49);
}

.recipe .recipe__ingredients-title,
.recipe .recipe__directions-title {
  color: var(--color--black);
}

.recipe .recipe__ingredients-group-heading {
  text-align: left;
  text-transform: none;
  margin-bottom: 0;
  letter-spacing: normal;
  font-weight: normal;
  margin-top: 0;
  line-height: 1.3;
  font-size: var(--font-size--14);
  &:after {
    content: ':';
  }
}

.recipe__ingredients {
  margin-bottom: var(--spacing-1halfx);
  @media (min-width: $recipe-breakpoint) {
    width: calc(50% - #{var(--spacing-base)});
  }
}

.recipe__ingredients-items {
  padding-left: 1em;
  margin-bottom: 1em;
}

.recipe__directions {
  @media (min-width: $recipe-breakpoint) {
    width: calc(50% - #{var(--spacing-base)});
  }
}
