// variables
$sb-site-footer-logo-size: 4.75rem;
$sb-site-footer-space-bottom: 5.25rem;

.sb-site-footer {
  display: block;
  margin-top: auto;
  padding-bottom: $sb-site-footer-space-bottom;
  position: relative;
  width: 100%;

  a {
    color: var(--color--black);

    &:hover,
    &:active,
    &:focus {
      color: var(--color--starbucks-accent-green);
    }
  }
}

.sb-site-footer__container {
  &:before {
    border-top: 1px solid var(--color--black);
    content: '';
    display: block;
    width: 100%;
  }
}

.sb-site-footer__logo-contain {
  display: block;
  height: $sb-site-footer-logo-size;
  margin-bottom: calc(#{$sb-site-footer-logo-size} * -0.5);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: calc(#{$sb-site-footer-logo-size} * -0.5);
  width: $sb-site-footer-logo-size;
}

.sb-site-footer__logo {
  height: auto;
  margin: 0;
  width: 100%;
}

.sb-right-reserved {
  .sb-site-header__container--desktop & {
    @media (min-width: $sb-breakpoint-md) {
      display: none;
    }
  }
}

.sb-right-reserved__content {
  font-size: 0.562rem;
  text-align: center;
}

.sb-right-reserved__content--footer {
  color: rgba(0, 0, 0, 0.56);
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--light);

  @media (min-width: $sb-breakpoint-md) {
    font-size: var(--font-size--14);
  }

  .site-navigation__drawer & {
    margin-bottom: var(--spacing-50);
  }
}
