.sb-block-link-contain {
  @include clearfix;
  display: block;
  margin: var(--spacing-base) var(--spacing-16);
  text-align: center;
}

.sb-block-link {
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--medium);
  letter-spacing: 0.3em;
  line-height: 1.2;
  margin-bottom: var(--spacing-16);

  .multimedia-single .sb-display-2 + &,
  .multimedia-archive .sb-display-2 + &,
  .search-results .sb-display-2 + & {
    display: block;
    margin-bottom: var(--spacing-24);
    margin-top: calc(-1 * var(--spacing-24));
    text-align: center;

    @media (min-width: $sb-breakpoint-md) {
      margin-bottom: var(--spacing-36);
    }
  }
}

.sb-block-link__icon {
  display: inline-block;
  font-weight: var(--font-weight--medium);
  margin-bottom: -0.1em;
  margin-left: 0.25em;
}

.sb-block-link__icon--left {
  transform: rotate(180deg);
  margin-right: var(--spacing-8);
}

.sb-block-link--all {
  align-items: center;
  display: inline-flex;

  .sb-icon {
    height: var(--spacing-12);
    width: var(--spacing-12);
  }
}
