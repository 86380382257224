// Variables
$sb-hr-block-width: 7.625rem;
$sb-hr-block-height: 0.125rem;
$sb-hr-block-margin: 4.688rem auto;
$sb-hr-block-color: #cbcbcb;

.sb-hr--block {
  background: $sb-hr-block-color;
  border: none;
  display: block;
  height: $sb-hr-block-height;
  margin: $sb-hr-block-margin;
  width: $sb-hr-block-width;
}
