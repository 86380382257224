.header-p2a {
  background: var(--color--starbucks-light-green);
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-height: 600px;
  overflow: hidden;
  position: relative;
  right: 50%;
  transition: max-height 300ms linear;
  width: 100vw;

  &.-hidden {
    max-height: 0;
  }
}

.header-p2a__row {
  color: var(--color--starbucks-house-green);
  display: flex;
  flex-direction: column;
  margin: var(--spacing-32) auto var(--spacing-30);
  max-width: $sb-max-width;
  padding: var(--spacing-30) var(--spacing-20) 0;
  position: relative;

  @media (min-width: $sb-breakpoint-lg) {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    padding: 0 var(--spacing-100);
  }
}

.header-p2a__close {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: var(--spacing-20);
  top: calc(-1 * var(--spacing-10));
  width: auto;

  @media (min-width: $sb-breakpoint-lg) {
    right: var(--spacing-30);
    top: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.header-p2a__close svg {
  border: 0.062rem solid var(--color--starbucks-house-green);
  border-radius: 50%;
  fill: var(--color--starbucks-house-green);
  height: var(--spacing-24);
  margin-left: var(--spacing-14);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: var(--spacing-24);
}

.header-p2a__legal {
  color: var(--color--starbucks-house-green);
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--light);
  text-align: left;

  a {
    color: var(--color--starbucks-house-green);
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .header-p2a & {
    margin-top: var(--spacing-23);
  }
}

.header-p2a .sb-p2a__heading {
  color: var(--color--starbucks-house-green);
  text-align: center;

  @media (min-width: $sb-breakpoint-lg) {
    text-align: left;
    width: 60%;
    padding-right: var(--spacing-30);
  }
}

.header-p2a .sb-p2a__form {
  font-size: 1.062rem;
  margin-top: 0;
  max-width: 31.25rem;
  width: 100%;

  @media (min-width: $sb-breakpoint-lg) {
    margin-top: auto;
    width: 35%;
  }
}

.header-p2a .sb-p2a__form form {
  margin-bottom: var(--spacing-12);
  position: relative;

  @media (min-width: $sb-breakpoint-lg) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.header-p2a .sb-form__result {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  padding-top: 0.125rem;
}
