.flyout-post {
  background: var(--color--white);
  border-top: var(--spacing-4) solid var(--color--starbucks-green);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: var(--spacing-16) var(--spacing-16) var(--spacing-24) var(--spacing-16);
  position: relative;
  transform: translate3d(150px, 0, 0);
  width: 320px;
  gap: var(--spacing-16);
  will-change: transform;
  opacity: 0;

  @media (min-width: $sb-breakpoint-sm) {
    width: 460px;
  }

  .-viewed & {
    animation: slideIn 400ms ease-in-out forwards;
  }
}

.flyout-post__thumb {
  flex-shrink: 0;
}

.flyout-post__thumb img {
  width: var(--spacing-96);
  height: var(--spacing-96);
  object-fit: cover;
}

.flyout-post__title {
  font-family: var(--font-family--lander);
  font-weight: 600;
  font-size: var(--font-size--22);
  line-height: 1.2;
  margin-block: var(--spacing-16) 0;
}

.flyout-post__title a {
  color: var(--color--black);

  &::after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.flyout-post__label {
  color: var(--color--starbucks-green);
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--medium);
  letter-spacing: var(--spacing-4);
  text-transform: uppercase;
  margin: 0;
}

.flyout-post__close {
  align-items: center;
  background: transparent;
  border-radius: 50%;
  border: 0.062rem solid var(--color--starbucks-accent-green);
  color: var(--color--starbucks-accent-green);
  cursor: pointer;
  display: flex;
  height: var(--spacing-24);
  justify-content: center;
  padding: 0;
  position: absolute;
  right: var(--spacing-16);
  top: var(--spacing-16);
  width: var(--spacing-24);

  &:hover {
    cursor: pointer;
  }
}

.flyout-post__close svg {
  display: block;
  height: var(--spacing-8);
  width: var(--spacing-8);
}

.flyout-post--visible {
  opacity: 1;
}

.flyout-post--hidden {
  display: none !important;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate3d(150px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
