$sb-border-width-focus: 2px;
$sb-border-outline-offset: 10px;

// LAYOUT
$sb-max-width: 1440px;
$sb-article-content-normal-width: 700px;
$sb-article-content-wide-width: 940px;
$sb-article-content-wider-width: 1028px;
$sb-article-content-max-width: 1360px;
$sb-article-content-full-width: 100%;

// BREAKPOINTS
$sb-breakpoint-sm: 576px;
$sb-breakpoint-md: 768px;
$sb-breakpoint-lg: 992px;
$sb-breakpoint-xl: 1200px;

// Interactive
$sb-base-transition: 0.2s ease-out;

// Borders
$sb-border-xs: 2px;
$sb-border-radius-s: 3px;

// Article Hero
$article-hero-ratio: 93%; // ratio calculated from home page
$article-hero-ratio-lg: 48.6%;
$article-hero-bottom-margin: 0;
$article-hero-single-bottom-margin: -30px;
$article-hero-single-bottom-margin-lg: -100px;
$article-hero-original-bottom-margin-xl: -200px;

// Box Shadow
$sb-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 4px 50px rgba(127, 99, 99, 0.1);

// To do: migrate all variables to native CSS props.

:root {
  // Named Colors
  --color--white: #fff;
  --color--black: #000;
  
  --color--limegreen: #3cc429;
  --color--max-green-yellow: #e0df4e;
  --color--jade: #00a67d;
  --color--fun-green: #008248;
  --color--hint-of-red: #faf7f7;
  --color--hint-of-red-mid: #e1dfd8;
  --color--shuttle-gray: rgba(0, 0, 0, 0.87);
  --color--cream: #f9f8f5;
  --color--holiday-red: #d50032;
  --color--light-peach: rgba(251, 199, 176, 0.3);

  --color--facebook: #507cbe;
  --color--twitter: #63cdf1;
  --color--linkedin: #0077b5;

  // Brand Colors: https://creative.starbucks.com/color/
  --color--starbucks-green: #006241;
  --color--starbucks-accent-green: #00754a;
  --color--starbucks-house-green: #1e3932;
  --color--starbucks-light-green: #d4e9e2;

  // Shades of Gray
  --color--gray-97: #f7f7f7;
  --color--gray-89: #e4e4e4;
  --color--gray-74: #bdbdbd;
  --color--gray-56: #707070;
  --color--gray-49: #7f7f7f;
  --color--gray-34: #575757;
  --color--gray-25: #343a40;
  --color--gray-18: #2f2f2f;
  --color--gray-16: #292929;

  // State Colors
  --color--success: var(--color--starbucks-accent-green);
  --color--danger: #dc3545;

  // Design Token Colors
  --color--text: var(--color--shuttle-gray);
  --color--heading: var(--color--black);

  --color--button-text: var(--color--white);
  --color--button-bg: var(--color--starbucks-accent-green);
  --color--button-hover-bg: #008248;

  --color--link: var(--color--starbucks-accent-green);
  --color--link-hover: var(--color--starbucks-green);
  --color--link-hover-bg: var(--color--starbucks-light-green);

  // Spacing
  --spacing-4: 0.25rem;
  --spacing-5: 0.3125rem;
  --spacing-6: 0.375rem;
  --spacing-7: 0.4375rem;
  --spacing-8: 0.5rem;
  --spacing-9: 0.5625rem;
  --spacing-10: 0.625rem;
  --spacing-12: 0.75rem;
  --spacing-13: 0.8125rem;
  --spacing-14: 0.875rem;
  --spacing-15: 0.9375rem;
  --spacing-16: 1rem;
  --spacing-18: 1.125rem;
  --spacing-20: 1.25rem;
  --spacing-22: 1.375rem;
  --spacing-23: 1.4375rem;
  --spacing-24: 1.5rem;
  --spacing-26: 1.625rem;
  --spacing-27: 1.6875rem;
  --spacing-28: 1.75rem;
  --spacing-30: 1.875rem;
  --spacing-32: 2rem;
  --spacing-34: 2.125rem;
  --spacing-35: 2.1875rem;
  --spacing-36: 2.25rem;
  --spacing-38: 2.375rem;
  --spacing-40: 2.5rem;
  --spacing-42: 2.625rem;
  --spacing-48: 3rem;
  --spacing-50: 3.125rem;
  --spacing-52: 3.25rem;
  --spacing-54: 3.375rem;
  --spacing-57: 3.5625rem;
  --spacing-60: 3.75rem;
  --spacing-64: 4rem;
  --spacing-66: 4.125rem;
  --spacing-70: 4.375rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-100: 6.25rem;
  --spacing-128: 8rem;
  --spacing-130: 8.125rem;

  // Named Spacing. To do: rethink the naming after refactoring to rem units.
  --spacing-base: 1.25rem;
  --spacing-quarterx: 0.3125rem;
  --spacing-halfx: 0.625rem;
  --spacing-1halfx: 1.875rem;
  --spacing-2x: 2.5rem;
  --spacing-2halfx: 3.125rem;
  --spacing-3x: 3.75rem;
  --spacing-3halfx: 4.375rem;
  --spacing-4x: 5rem;
  --spacing-4halfx: 5.625rem;
  --spacing-5x: 6.25rem;
  --spacing-6x: 7.5rem;
  --spacing-8x: 10rem;
  --spacing-9x: 11.25rem;

  // Font Family
	// Handled by Next.js
  // --font-family--lander: 'Lander', serif;
  // --font-family--sodo: 'SoDo Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family--mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --font-family--japan: Arial, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック';

  // Font Sizes
  --font-size--12: 0.75rem;
  --font-size--14: 0.875rem;
  --font-size--16: 1rem;
  --font-size--18: 1.125rem;
  --font-size--20: 1.25rem;
  --font-size--22: 1.375rem;
  --font-size--28: 1.75rem;
  --font-size--36: 2.25rem;
  --font-size--40: 2.5rem;
  --font-size--42: 2.625rem;
  --font-size--52: 3.25rem;

  // Font Weights
  --font-weight--thin: 100;
  --font-weight--light: 300;
  --font-weight--regular: 400;
  --font-weight--medium: 500;
  --font-weight--semibold: 600;
  --font-weight--bold: 700;
  --font-weight--black: 900;

  // Layout
  --h-header: 70px;
  --h-header-sticky-audio: 80px;

  @media screen and (min-width: 768px) {
    --h-header-sticky-audio: 54px;
  }

  --site-gutter: var(--spacing-24);
  --w-content-normal: 700px;
  --w-content-wide: 940px;
  --w-content-wider: 1028px;
  --w-content-max: 1360px;
  --w-content-full: 100%;

  @media screen and (min-width: 992px) {
    --h-header: 90px;
  }
}
