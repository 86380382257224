.sbx-scrollable-sections {
  position: relative;
  contain: paint;
}

.sbx-scrollable-sections__backgrounds {
  position: sticky;
  top: var(--h-header);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--h-header));
}

.sbx-scrollable-sections__items {
  margin-top: calc(-1 * (100vh - var(--h-header)));
  position: relative;
}

.sbx-scrollable-sections__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease;
}

.sbx-scrollable-sections__item {
  position: relative;
  min-height: calc(100vh - var(--h-header));
  padding-top: var(--spacing-40);
  padding-bottom: var(--spacing-40);

  @media (min-width: $sb-breakpoint-md) {
    padding-top: var(--spacing-80);
    padding-bottom: var(--spacing-80);
  }
}

.sbx-scrollable-sections__item-inner {
  padding-inline: var(--spacing-20);
}
