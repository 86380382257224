.sb-connect {
  &.sb-without-aside {
    .sb-layout__main--connect {
      max-width: 100%;
    }

    .sb-headline--section-lg {
      text-align: center;
    }

    .sb-posts-list {
      @media (min-width: $sb-breakpoint-lg) {
        margin-left: var(--spacing-8x);
        margin-right: var(--spacing-8x);
      }
    }
  }
}
