@import '~flickity/css/flickity.css';

// Variables
$grid-gap: var(--spacing-halfx);
$sb-carousel-cell-width: 230px;
$sb-carousel-slider-margin-right: 20px;
$sb-carousel-bp-slides-2: 600px;
$sb-carousel-bp-slides-3: 900px;
$sb-carousel-bp-slides-4: 1200px;
$sb-carousel-bp-slides-5: 1440px;
$sb-carousel-cell-zoom-scale: 0.9;
$gallery-item-image-ratio: 63.5%;

// SB Carousel
@mixin original-slider-width($slides: 5) {
  $slide-width: $sb-carousel-cell-width + 20px;

  width: ($slide-width * $slides) - 21px;
}

.sb-carousel-wrap {
  @include original-slider-width(1);
  display: block;
  margin: var(--spacing-3x) auto;
  position: relative;

  @media (min-width: $sb-carousel-bp-slides-2) {
    @include original-slider-width(2);
  }

  @media (min-width: $sb-carousel-bp-slides-3) {
    @include original-slider-width(3);
  }

  @media (min-width: $sb-carousel-bp-slides-4) {
    @include original-slider-width(4);
  }

  @media (min-width: $sb-carousel-bp-slides-5) {
    @include original-slider-width(5);
  }
}

.sb-carousel {
  .flickity-prev-next-button {
    background-color: var(--color--link);
    border-radius: 0;
    color: var(--color--white);
    height: var(--spacing-100);
    top: 11rem;
    transition: background-color $sb-base-transition;
    width: var(--spacing-50);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color--link-hover);
    }

    &:focus {
      box-shadow: none;
      outline: $sb-border-width-focus solid var(--color--gray-25);
    }
  }

  .flickity-prev-next-button.previous {
    left: auto;
    right: calc(100% + #{var(--spacing-10)});
  }

  .flickity-prev-next-button.next {
    left: calc(100% + #{var(--spacing-10)});
    right: auto;
  }

  .flickity-button-icon {
    fill: var(--color--white);
    height: var(--spacing-24);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: var(--spacing-24);
  }
}

.sb-carousel-wrap--zoom {
  margin-left: calc(-1 * var(--spacing-base));
  margin-right: calc(-1 * var(--spacing-base));
  width: calc(100% + #{var(--spacing-2x)});

  .flickity-prev-next-button.previous {
    left: var(--spacing-10);
    right: auto;
  }

  .flickity-prev-next-button.next {
    left: auto;
    right: var(--spacing-10);
  }

  @media (min-width: $sb-breakpoint-md) {
    margin-left: calc(-1 * var(--spacing-2halfx));
    margin-right: calc(-1 * var(--spacing-2halfx));
    width: calc(100% + #{var(--spacing-5x)});
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-left: -#{var(--spacing-4halfx)};
    margin-right: #{var(--spacing-4halfx)};
    width: calc(100% + #{var(--spacing-9x)});
  }

  .sb-content-wrap--mobile-narrow & {
    @media (max-width: $sb-breakpoint-md - 1) {
      margin-left: calc(-1 * var(--spacing-16));
      margin-right: calc(-1 * var(--spacing-16));
      width: calc(100% + #{var(--spacing-32)});
    }
  }
}

.sb-carousel-cell {
  display: block;
  margin-right: $sb-carousel-slider-margin-right;
  overflow: hidden;
  width: $sb-carousel-cell-width;
}

.sb-carousel-cell--zoom {
  margin-right: 0;
  transform: scale($sb-carousel-cell-zoom-scale);
  transition: transform $sb-base-transition;
  will-change: transform;

  &.is-selected {
    transform: scale(1);
  }

  @media (min-width: $sb-breakpoint-lg) {
    margin-right: $sb-carousel-slider-margin-right;
    transform: scale(1);
  }
}

.sb-carousel-cell--originals {
  margin-bottom: var(--spacing-40);
  margin-left: var(--spacing-20);
  margin-right: var(--spacing-20);
  transform: scale(1);
  width: calc(100% - #{var(--spacing-40)});

  @media (min-width: $sb-breakpoint-sm) {
    /* @include intentionally at bottom to override resets */
    @include post-grid-item-flex(50%, var(--spacing-40));
    max-width: none;
    width: auto;
  }

  @media (min-width: $sb-breakpoint-md) {
    @include post-grid-item-flex(33.3333%, var(--spacing-40));
    margin-bottom: 4.062rem;
  }

  @media (min-width: $sb-breakpoint-xl) {
    @include post-grid-item-flex(25%, var(--spacing-40));
  }
}

.sb-carousel-cell__link {
  border: $sb-border-width-focus inset transparent;
  color: var(--color--text);
  display: block;
  font-size: var(--font-size--20);
  font-weight: var(--font-weight--medium);
  line-height: 1.3;
  overflow: hidden;

  .post-grid--originals & {
    font-size: var(--font-size--18);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--color--link);
    text-decoration: none;
  }

  &:focus {
    border-color: var(--color--gray-25);
    outline: none;
  }
}

.sb-carousel-cell__image-wrap {
  display: block;
  margin-bottom: var(--spacing-16);
  overflow: hidden;
  padding-top: 153%;
  position: relative;
  width: 100%;
}

.sb-carousel-cell__image-fit {
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform: scale(1);
  transition: $post-grid-item-image-transition;
  width: 100%;
  will-change: transform;

  .sb-carousel-cell__link:hover & {
    transform: scale(1.1);
  }
}

.sb-carousel-cell__image-contain {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sb-carousel-cell__count {
  font-size: var(--font-size--12);
  letter-spacing: 1px;
  line-height: 1.3333;
  margin-bottom: var(--spacing-10);
}

/* Flickity fullscreen Styles
------------------------- */
.is-style-carousel .is-fullscreen .flickity-viewport,
.is-style-lightbox .is-fullscreen .flickity-viewport {
  height: 100%;
}

.is-style-lightbox .flickity-viewport {
  width: 100%;
}

.wp-block-gallery.is-style-carousel .is-fullscreen .blocks-gallery-item figure,
.wp-block-gallery.is-style-lightbox .is-fullscreen .blocks-gallery-item figure {
  display: inline-block;
  width: auto;
  padding: 50px;
}

.wp-block-gallery.is-style-lightbox .blocks-gallery-item:hover {
  @media (min-width: $sb-breakpoint-md) {
    cursor: pointer;
  }
}

.wp-block-gallery.is-style-carousel .is-fullscreen .blocks-gallery-item img,
.wp-block-gallery.is-style-lightbox .is-fullscreen .blocks-gallery-item img {
  height: auto;
  margin: 0 auto;
  max-height: 70vh;
  position: relative;
  width: auto;
}

.is-style-carousel .is-fullscreen .blocks-gallery-item,
.is-style-lightbox .is-fullscreen .blocks-gallery-item {
  height: 100%;
  padding: 0 50px;
  text-align: center;
  width: 100%;
}

.wp-block-gallery.is-style-carousel .is-fullscreen .flickity-viewport .blocks-gallery-item:nth-child(n),
.wp-block-gallery.is-style-lightbox .is-fullscreen .flickity-viewport .blocks-gallery-item:nth-child(n) {
  width: 100%;
}

// Don't use ratio'd image containers in fullscreen mode
.wp-block-gallery.is-style-carousel .is-fullscreen .blocks-gallery-item__img-wrap,
.wp-block-gallery.is-style-lightbox .is-fullscreen .blocks-gallery-item__img-wrap {
  overflow: auto;
  padding: 0;
}

.wp-block-gallery.is-style-lightbox .is-fullscreen .image-caption {
  display: block;
  margin-right: var(--spacing-10);
}

.wp-block-gallery.is-cropped.is-style-lightbox .blocks-gallery-item figcaption {
  position: relative;
}

.wp-block-gallery.is-style-lightbox .is-fullscreen .image-credit,
.wp-block-gallery.is-style-lightbox .is-fullscreen .image-credit,
.wp-block-gallery.is-style-lightbox .is-fullscreen .image-caption,
.wp-block-gallery.is-style-lightbox .is-fullscreen
  .blocks-gallery-item
  figcaption {
  color: var(--color--white);
  padding-top: 0;
  text-align: left;
}

.wp-block-gallery.is-cropped.is-style-lightbox .is-fullscreen .blocks-gallery-item figure {
  padding: 50px;
}

.flickity-enabled.is-fullscreen {
  background: hsla(0, 0%, 0%, 0.9);
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  max-width: none;
  padding-bottom: 35px;
  padding-top: 100px;
  position: fixed;
  z-index: 20;
}

.flickity-enabled.is-fullscreen.is-style-lightbox {
  max-width: none;
  width: 100%;
}

.article__content > .alignwide .flickity-enabled.is-fullscreen,
.article__content > .alignfull .flickity-enabled.is-fullscreen {
  max-width: none;
}

.wp-block-gallery.is-cropped.is-fullscreen .blocks-gallery-item figcaption,
.wp-block-gallery.is-fullscreen .blocks-gallery-item figcaption {
  background: transparent;
  color: var(--color--white);
  text-align: left;
}

.wp-block-gallery.is-fullscreen .blocks-gallery-item figcaption .image-caption {
  flex: 5;
}

.wp-block-gallery.is-fullscreen .blocks-gallery-item figcaption .image-credit {
  color: var(--color--white);
  flex: 1;
}

.flickity-enabled.is-fullscreen .flickity-page-dots {
  bottom: 10px;
}

.flickity-enabled.is-fullscreen .flickity-page-dots .dot {
  background: white;
}

.flickity-enabled.is-fullscreen .flickity-prev-next-button {
  margin-top: calc(-1 * var(--spacing-24));
  transition: unset;
  transition-property: unset;
  top: 50%;
}

/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden;
}

/* Hovers */
.is-style-lightbox .blocks-gallery-item img {
  &:hover {
    @media (min-width: $sb-breakpoint-md) {
      cursor: pointer;
    }
  }
}

/* ---- flickity-fullscreen-button ---- */

.flickity-fullscreen-button {
  display: none;
  right: 10px;
  top: 10px;
  width: 34px;
  height: 34px;
  border-radius: 4px;

  @media (min-width: $sb-breakpoint-md) {
    display: block;
  }
}

/* right-to-left */
.flickity-rtl .flickity-fullscreen-button {
  right: auto;
  left: 10px;
}

.flickity-fullscreen-button-exit {
  display: none;
}

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-view {
  display: none;
}

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit {
  display: block;
  top: 100px;
}

.flickity-fullscreen-button .flickity-button-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 5px;
  top: 5px;
}
