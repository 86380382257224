.wp-block-image {
  position: relative;
}

.wp-block-image__caption {
  margin-block-start: 1em;

  @include imageCaption;
}

.wp-block-image.alignwide img,
.wp-block-image.alignfull img {
  width: 100%;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {

  @media screen and (min-width: 768px){
    max-width: min(50%, var(--w-content-normal) / 2);
  }
}

.wp-block-image.alignleft {

  @media screen and (min-width: 768px){
    margin-right: var(--spacing-16);
  }
}

.wp-block-image.alignright {

  @media screen and (min-width: 768px){
    margin-left: var(--spacing-16);
  }
}

.wp-block-image.alignfull .wp-block-image__caption {
  padding-inline: var(--spacing-16);
}

.wp-block-image.is-style-rounded img {
  border-radius: 9999px;
}
