// Variables
$related-title-originals-bp: 575px;
$related-title-letter-spacing: 0.3em;
$related-title-border-size: 1px;
$related-title-transition: transform 500ms cubic-bezier(0.22, 0.89, 0.57, 1);

.related-posts {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--spacing-32);
  width: 100%;
  max-width: $sb-article-content-max-width;

  &--interrupter {
    width: 100%;
    margin-top: var(--spacing-34);
    padding-bottom: var(--spacing-34);

    @media screen and (min-width: 991px) {
      border: 2px solid var(--color--starbucks-accent-green);
      border-left: none;
      border-right: none;
    }
  }
}

.related-posts + .related-posts {
  margin-top: 0;

  @media (min-width: $sb-breakpoint-md) {
    letter-spacing: 0.46875;
    margin-top: var(--spacing-50);
  }
}

.related-posts__header {
  display: block;
  overflow: hidden;
}

.related-posts__title {
  @include responsive-text-size(var(--spacing-15), var(--spacing-20), $sb-breakpoint-md);
  align-items: center;
  border-bottom: $related-title-border-size solid var(--color--black);
  color: inherit;
  display: flex;
  flex-flow: row wrap;
  font-family: var(--font-family--lander);
  font-weight: var(--font-weight--semibold);
  justify-content: space-between;
  letter-spacing: $related-title-letter-spacing;
  margin-bottom: var(--spacing-base);
  margin-top: var(--spacing-2halfx);
  padding-bottom: var(--spacing-15);
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-md) {
    letter-spacing: 0.46875;
    margin-top: var(--spacing-50);
  }

  &--interrupter {
    text-align: center;
    display: block;
    border: none;

    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 33%;
      border-bottom: 2px solid var(--color--fun-green);
      width: calc(50vw - 130px);

      @media screen and (min-width: 576px) {
        width: calc(50vw - 160px);
      }

      @media screen and (min-width: 768px) {
        width: calc(50vw - 270px);
      }

      @media screen and (min-width: 991px) {
        content: none;
        display: none;
      }
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}

.related-posts__title--originals {
  @include responsive-text-size(
    var(--spacing-20),
    var(--spacing-15),
    $related-title-originals-bp
  );

  @media (min-width: $sb-breakpoint-md) {
    font-size: var(--font-size--20);
  }
}

.related-posts__archive-link {
  align-items: center;
  background-color: transparent;
  color: var(--color--black);
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size--14);
  letter-spacing: $related-title-letter-spacing;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  &:after {
    border: var(--spacing-10) solid transparent;
    border-bottom-width: 0.406rem;
    border-left-color: var(--color--black);
    border-right-width: 0;
    border-top-width: 0.406rem;
    content: '';
    display: block;
    height: 0;
    margin-left: var(--spacing-10);
    width: 0;
  }

  &:hover {
    color: var(--color--black);
    text-decoration: none;
  }
}

.related-posts__archive-link-text {
  display: block;
  padding: var(--spacing-6) 0;
  transition: $related-title-transition;

  .related-posts__archive-link:hover & {
    transform: translateY(-100%);
  }
}

.related-posts__archive-link-text--dummy {
  left: 0;
  position: absolute;
  top: 100%;
}

.related-posts__archive-link-underline {
  background-color: var(--color--starbucks-green);
  bottom: 0;
  height: 0.188rem;
  left: 0;
  position: absolute;
  transform: translateX(-100%);
  transition: $related-title-transition;
  width: 100%;

  .related-posts__archive-link:hover & {
    transform: translateX(0);
  }
}
