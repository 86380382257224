.press-photos__top {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.press-photos__title {
  color: var(--color--black);
  font-size: var(--font-size--28);
  text-align: left;
}

.press-photos__view-all {
  margin: 0;
  text-align: left;
  text-transform: uppercase;
}

.press-photos__view-all a {
  margin-bottom: 0;
}

.press-photos__items {
  display: grid;
  gap: var(--spacing-12);

  @media (min-width: $sb-breakpoint-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: $sb-breakpoint-lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
