$sb-media-grid-rect-1up: 59.99%;
$sb-media-grid-rect-2up: 28%;
$sb-media-grid-square-2up: 37.56%;
$sb-media-grid-square-4up: 22.7%;

.multimedia-grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
}

.multimedia-grid__item {
  height: 0;
  margin: 0 var(--spacing-10) var(--spacing-20);
  overflow: hidden;
  position: relative;

  .media-item__image-fit {
    margin: 0;
  }

  &.multimedia-grid__item--landscape {
    .media-item__image-fit {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .media-item__image-contain {
      height: 100%;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    left: 0;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.multimedia-grid__item--landscape {
  &.post-grid-item-sm-50 {
    padding-bottom: $sb-media-grid-rect-1up;
    width: 100%;

    @media (min-width: $sb-breakpoint-sm) {
      padding-bottom: $sb-media-grid-rect-2up;
    }
  }
}

.multimedia-grid__item--square {
  &.post-grid-item-sm-25 {
    padding-bottom: $sb-media-grid-square-2up;

    @media (min-width: $sb-breakpoint-sm) {
      padding-bottom: $sb-media-grid-square-4up;
    }
  }
}
