.pagination {
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $sb-breakpoint-lg) {
    margin-top: var(--spacing-20);
  }

  // Previous and Next controls
  .previous,
  .next {
    display: inline-block;
    flex-basis: 100%;
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--medium);
    letter-spacing: 0.3em;
    margin: var(--spacing-8) 0;
    padding: 0 var(--spacing-8);
    transition: $sb-card-grid-item-cat-transition;
    vertical-align: middle;

    @media (min-width: $sb-breakpoint-sm) {
      flex-basis: auto;
      margin: 0;
    }

    &:hover {
      @include svg-fill-override(var(--color--link-hover));
    }

    svg {
      @include svg-fill-override(var(--color--link));
      display: inline-block;
      height: var(--spacing-12);
      margin-top: -0.219rem;
      width: var(--spacing-12);
      vertical-align: middle;
    }
  }

  .previous {
    @media (min-width: $sb-breakpoint-sm) {
      margin-right: var(--spacing-20);
    }

    @media (min-width: $sb-breakpoint-lg) {
      margin-right: var(--spacing-50);
    }

    svg {
      margin-right: var(--spacing-8);
      transform: rotate(180deg);
      position: relative;
    }
  }

  .next {
    @media (min-width: $sb-breakpoint-sm) {
      margin-left: var(--spacing-20);
    }

    @media (min-width: $sb-breakpoint-lg) {
      margin-left: var(--spacing-50);
    }

    svg {
      margin-left: var(--spacing-4);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    color: var(--color--gray-34);
    display: inline-block;
    padding: 0 var(--spacing-8);
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--medium);

    &::before {
      content: none !important;
    }

    &:hover {
      a {
        color: var(--color--link-hover);
      }
    }

    a {
      color: var(--color--link);
      position: relative;

      &:focus {
        text-decoration: none;
      }
    }
  }
}
