// Variables
$post-grid-item-transition: transform 500ms cubic-bezier(0.22, 0.89, 0.57, 1);
$post-grid-item-title-background: linear-gradient( to bottom, transparent 0, rgba(47, 47, 47, 0.68) 100% );
$post-grid-item-title-line-height: 1.32;
$post-grid-item-title-line-height-lg: 1.357;
$post-grid-item-image-transition: transform 1.5s cubic-bezier(0.22, 0.89, 0.57, 1);
$post-grid-item-landscape-ratio: 63.5%;

@mixin post-grid-item-flex($width: 50%, $gutter: 20px) {
  flex: 1 1 calc(#{$width} - #{$gutter});
  max-width: calc(#{$width} - #{$gutter});
  width: calc(#{$width} - #{$gutter});
}

.post-grid-item {
  background: var(--color--gray-97);
  display: block;
  margin-bottom: var(--spacing-base);
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - #{20px});

  .archive-page & {
    margin-bottom: var(--spacing-40);
    margin-left: var(--spacing-20);
    margin-right: var(--spacing-20);
    width: calc(100% - #{var(--spacing-40)});
  }
}

.post-grid-item__content {
  padding: var(--spacing-10) var(--spacing-10) var(--spacing-base) var(--spacing-10);

  @media (min-width: $sb-breakpoint-md) {
    padding: var(--spacing-base);
  }

  @media (min-width: $sb-breakpoint-lg) {
    padding: var(--spacing-1halfx);
  }
}

.post-grid-item-sm-50 {
  @media (min-width: $sb-breakpoint-sm) {
    @include post-grid-item-flex();
  }

  .archive-page & {
    @media (min-width: $sb-breakpoint-sm) {
      @include post-grid-item-flex(50%, var(--spacing-40));
    }
  }
}

.post-grid-item-sm-33 {
  @media (min-width: $sb-breakpoint-sm) {
    @include post-grid-item-flex(33.3333%);
  }

  .archive-page & {
    @media (min-width: $sb-breakpoint-md) {
      @include post-grid-item-flex(33.3333%, var(--spacing-40));
    }
  }
}

.post-grid-item-sm-25 {
  @include post-grid-item-flex(50%);

  @media (min-width: $sb-breakpoint-sm) {
    @include post-grid-item-flex(25%);
  }
}

.post-grid-item-md-33 {
  @media (min-width: $sb-breakpoint-md) {
    @include post-grid-item-flex(33.3333%);
  }

  .archive-page & {
    @media (min-width: $sb-breakpoint-md) {
      @include post-grid-item-flex(33.3333%, var(--spacing-40));
    }
  }
}

.post-grid-item-lg-33 {
  @media (min-width: $sb-breakpoint-lg) {
    @include post-grid-item-flex(33.3333%);
  }

  .archive-page & {
    @media (min-width: $sb-breakpoint-lg) {
      @include post-grid-item-flex(33.3333%, var(--spacing-40));
    }
  }
}

.post-grid-item--gutter-sm {
  margin-bottom: var(--spacing-12);
  margin-left: var(--spacing-6);
  margin-right: var(--spacing-6);
  width: calc(100% - #{var(--spacing-12)});
}

.post-grid-item--gutter-sm.post-grid-item-sm-50 {
  @media (min-width: $sb-breakpoint-sm) {
    @include post-grid-item-flex(50%, var(--spacing-12));
  }
}

.post-grid-item--gutter-sm.post-grid-item-lg-33 {
  @media (min-width: $sb-breakpoint-sm) {
    @include post-grid-item-flex(33.3333%, var(--spacing-12));
  }
}

.post-grid-item--related {
  &:nth-child(2),
  &:nth-child(3) {
    display: none;
    visibility: hidden;
  }

  @media (min-width: $sb-breakpoint-sm) {
    &:nth-child(2) {
      display: block;
      visibility: visible;
    }
  }

  @media (min-width: $sb-breakpoint-lg) {
    &:nth-child(3) {
      display: block;
      visibility: visible;
    }
  }
}

.post-grid-item__link {
  display: block;
  overflow: hidden;
  position: relative;
  transform: scale(1);
  will-change: transform;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: none;

    &:after {
      border-color: var(--color--gray-25);
    }
  }
}

.post-grid-item__image-fit {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.post-grid-item__image-contain {
  padding-top: $article-hero-ratio-lg;
  transform: scale(1);
  transition: $post-grid-item-image-transition;
  will-change: transform;

  .post-grid-item__link:hover & {
    transform: scale(1.1);
  }
}

.post-grid-item__title-contain {
  color: var(--color--text);
  margin: 0 0 var(--spacing-base);

  .post-grid-item__link:hover &,
  .post-grid-item__link:active &,
  .post-grid-item__link:focus & {
    color: var(--color--link);
  }
}

.post-grid-item__image-fit + .post-grid-item__title-contain,
.post-grid-item--video .post-grid-item__title-contain {
  background: $post-grid-item-title-background;
  bottom: 0;
  color: var(--color--white);
  display: flex;
  flex-flow: column nowrap;
  height: 75%;
  left: 0;
  margin: 0;
  padding: 0 7.5% var(--spacing-base);
  position: absolute;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  z-index: 1;

  .post-grid-item__link:hover &,
  .post-grid-item__link:active &,
  .post-grid-item__link:focus & {
    color: var(--color--white);
  }

  @media (min-width: $sb-breakpoint-md) {
    padding-bottom: var(--spacing-2x);
  }
}

.post-grid-item--video .post-grid-item__title-contain,
.post-grid-item--video .post-grid-item__link {
  &:hover,
  &:focus,
  &:active {
    .post-grid-item__title-contain {
      color: var(--color--white);
    }
  }
}

.post-grid-item__meta-cat {
  display: block;
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--semibold);
  letter-spacing: 0.29em;
  line-height: 1.36;
  margin-top: auto;
  text-align: center;
  text-transform: uppercase;
  transition: color $sb-base-transition;
  width: 100%;

  img,
  svg {
    display: block;
    margin: 0 auto;
    max-height: 1.562rem;
    max-width: var(--spacing-100);
    width: 100%;
  }

  img {
    filter: brightness(100);
  }

  .image-to-svg--replaced,
  svg {
    @include svg-fill-override(var(--color--white));
  }
}

.post-grid-item__meta-cat + .post-grid-item__title {
  margin-top: var(--spacing-10);
}

.post-grid-item__title {
  @include responsive-text-size(var(--spacing-20), var(--spacing-26));
  color: var(--color--black);
  font-weight: var(--font-weight--medium);
  line-height: normal;
  margin: 0;
  text-align: left;
  transition: color $sb-base-transition;

  .post-grid-item__link:hover &,
  .post-grid-item__link:active &,
  .post-grid-item__link:focus & {
    color: var(--color--link-hover);
  }
}

.post-grid-item__archive-link {
  background-color: transparent;
  color: var(--color--link);
  display: block;
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--medium);
  letter-spacing: 0.12em;
  margin-bottom: var(--spacing-10);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: var(--color--link-hover);
    text-decoration: none;
  }
}

.post-grid-item__archive-link-text {
  display: block;
}

.post-grid-item__archive-link-text--dummy {
  left: 0;
  position: absolute;
  top: 100%;
}

// Featured
.post-grid-item__link--featured {
  .post-grid-item__image-contain {
    @media (min-width: $sb-breakpoint-md) {
      padding-top: $post-grid-item-landscape-ratio;
    }
  }
}

// Originals
.post-grid-item__link--originals {
  display: flex;
  flex-flow: row nowrap;
  margin-left: -10px;
  margin-right: -10px;

  .post-grid-item__image-fit + .post-grid-item__title-contain {
    background: transparent;
    color: var(--color--black);
    display: flex;
    flex: 1 1 calc(50% - #{20px});
    height: auto;
    justify-content: flex-start !important;
    margin-left: 10px;
    margin-right: 10px;
    max-width: calc(50% - #{20px});
    padding: 0;
    position: relative;
    width: calc(50% - #{20px});

    .post-grid-item__title {
      font-size: var(--font-size--16);
      margin: 0;
      text-align: left;
    }
  }

  .post-grid-item__image-fit {
    flex: 1 1 calc(50% - #{20px});
    margin-left: 10px;
    margin-right: 10px;
    max-width: calc(50% - #{20px});
    width: calc(50% - #{20px});
  }

  .post-grid-item__image-contain {
    padding-top: 65%;
  }

  &:after {
    left: var(--spacing-10);
    right: var(--spacing-10);
  }

  &:hover,
  &:active,
  &:focus {
    .post-grid-item__image-fit + .post-grid-item__title-contain {
      color: var(--color--black);
    }
  }

  @media (min-width: $sb-breakpoint-sm) {
    display: block;
    margin-left: 0;
    margin-right: 0;
    position: relative;

    .post-grid-item__image-fit,
    .post-grid-item__image-fit + .post-grid-item__title-contain {
      flex: 0 1 100%;
      margin: 0;
      max-width: none;
      width: 100%;
    }

    .post-grid-item__image-fit + .post-grid-item__title-contain {
      background: $post-grid-item-title-background;
      bottom: 0;
      color: var(--color--white);
      display: flex;
      flex-flow: column nowrap;
      height: 75%;
      left: 0;
      padding: 0 7.5% var(--spacing-base);
      position: absolute;
      width: 100%;

      .post-grid-item__title {
        margin-top: auto;
        text-align: center;

        @media (min-width: $sb-breakpoint-md) {
          font-size: var(--font-size--18);
          line-height: 1.70859;
        }

        @media (min-width: $sb-breakpoint-xl) {
          font-size: var(--font-size--28);
          letter-spacing: 0.04375em;
          line-height: 1.357;
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      .post-grid-item__image-fit + .post-grid-item__title-contain {
        color: var(--color--white);
      }
    }

    &:after {
      left: 0;
      right: 0;
    }
  }
}

.post-grid-item--video > a:hover .video-thumbnail__controls {
  display: block;
}

.post-grid-item--video .video-thumbnail {
  overflow: hidden;
  position: relative;
  padding-top: 63.5%;
}

.related-posts .post-grid-item--video .video-thumbnail {
  padding-top: 100%;
}

.post-grid-item--video .video-thumbnail video {
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
}
