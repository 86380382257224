.sticky-sub-nav {
  margin: 0;
  max-width: 100%;
  padding: 0;

  &.sticky-sub-nav--is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--color--starbucks-house-green);
    width: 100%;
    z-index: 20;

    &:after {
      background: linear-gradient(
        90deg,
        rgba(30, 57, 50, 0) 0%,
        rgba(30, 57, 50, 1) 100%
      );
      content: '';
      height: 100%;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;

      @media (min-width: $sb-breakpoint-md) {
        display: none;
      }
    }

    .sticky-sub-nav__title {
      visibility: visible;
    }
  }

  .sticky-sub-nav__wrap {
    align-items: center;
    border-top: 1px solid var(--color--starbucks-accent-green);
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: calc(1440px - 4em);

    @media (min-width: $sb-breakpoint-md) {
      justify-content: center;
    }
  }

  .sticky-sub-nav__title {
    display: none;
    font-family: var(--font-family--lander);
    font-size: 1.5rem;
    font-weight: var(--font-weight--semibold);
    justify-self: flex-start;
    margin: 0 10px;
    visibility: hidden;

    @media (min-width: $sb-breakpoint-lg) {
      /*display: block;*/
    }
  }

  .sticky-sub-nav__items {
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sticky-sub-nav__item {
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--semibold);
    letter-spacing: 1.3px;
    list-style: none;
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: $sb-breakpoint-md) {
      font-size: var(--font-size--16);
    }
  }

  .sticky-sub-nav__item--current {
    position: relative;

    &:after {
      background: var(--color--starbucks-accent-green);
      bottom: 0;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .sticky-sub-nav__link {
    color: var(--color--white);
    display: block;
    padding: 25px 20px 25px 20px;
    transition: padding-left 0.5s;

    @media (min-width: $sb-breakpoint-md) {
      padding: 25px 20px 25px 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.lang-ja {
  .sticky-sub-nav {
    .sticky-sub-nav__title {
      font-family: var(--font-family--japan);
    }
  }
}
