.sb-posts-list {
  display: block;
  list-style: none;
  margin: var(--spacing-base) 0;
  padding: 0;

  @media (min-width: $sb-breakpoint-lg) {
    margin-top: var(--spacing-2x);
    margin-bottom: var(--spacing-2x);
  }

  @media (min-width: $sb-breakpoint-xl) {
    margin-left: var(--spacing-2halfx);
    margin-right: var(--spacing-2halfx);
    margin-top: var(--spacing-2halfx);
    margin-bottom: var(--spacing-2halfx);
  }
}
