/* Spacer Block styles */
.wp-block-spacer.is-style-space-sm {
  height: var(--spacing-15) !important;
  margin-bottom: var(--spacing-20);

  @media (min-width: $sb-breakpoint-md) {
    height: var(--spacing-20) !important;
  }
}

.wp-block-spacer.is-style-space-md {
  height: var(--spacing-30) !important;
  margin-bottom: var(--spacing-20);

  @media (min-width: $sb-breakpoint-md) {
    height: var(--spacing-40) !important;
  }
}

.wp-block-spacer.is-style-space-lg {
  height: 2.812rem !important;
  margin-bottom: var(--spacing-20);

  @media (min-width: $sb-breakpoint-md) {
    height: var(--spacing-60) !important;
  }
}

.wp-block-spacer.is-style-space-xl {
  height: var(--spacing-60) !important;
  margin-bottom: var(--spacing-20);

  @media (min-width: $sb-breakpoint-md) {
    height: var(--spacing-80) !important;
  }
}
