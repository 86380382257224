.animated-offset-bg-container {
  padding-bottom: var(--spacing-40);
  padding-top: var(--spacing-40);
  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);
  position: relative;
  z-index: 1;

  @media (min-width: $sb-breakpoint-md) {
    padding-top: var(--spacing-130);
    padding-bottom: var(--spacing-130);
  }
}

.animated-offset-bg-container__wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: $sb-article-content-wide-width;
  position: relative;
  z-index: 2;
}

.animated-offset-bg-container__wrap > *:last-child {
  margin-bottom: 0 !important;
}

.animated-offset-bg-container__bg {
  background-color: #eaf3f0;
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: right;
}
