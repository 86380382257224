.sbx-latest-news {

  > .wp-block-column:first-of-type {
    flex-grow: 0;
  }

  > .wp-block-column + .wp-block-column {
    margin-top: var(--spacing-24);
  
    @media screen and (min-width: 992px) {
      margin-top: 0;
    }
  }

  .wp-block-query .wp-block-post + .wp-block-post {
    border-top: 1px solid var(--color--gray-74);
    margin-top: var(--spacing-16);
    padding-top: var(--spacing-16);
  }
  
  .wp-block-query .wp-block-post .article__content {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-8);
  
    @media screen and (min-width: 992px) {
      column-gap: var(--spacing-16);
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  h3 {
    font-family: var(--font-family--sodo);
    font-size: var(--font-size--18);
    font-weight: var(--font-weight--regular);
    margin: 0;

    a {
      background-color: transparent !important;
      border-bottom: none !important;
      transition: none;
      color: var(--color--black);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .wp-block-post-date {
    font-family: var(--font-family--sodo);
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--light);
    margin: 0;
  }

}

.sbx-latest-news__title {
  font-family: var(--font-family--lander);
  font-size: var(--font-size--22);
  font-weight: var(--font-weight--medium);
  margin: 0;
}
