
.sbx-dialog,
.sbx-dialog__overlay {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.sbx-dialog {
	display: flex;
	z-index: 1001;
}

.sbx-dialog[aria-hidden="true"] {
	display: none;
}

.sbx-dialog__overlay {
	background-color: rgba(0, 0, 0, 0.5);
}

.sbx-dialog__content {
	background-color: var(--color--white);
	margin: auto;
	max-width: min(var(--w-content-normal), calc(100% - (var(--spacing-32) * 2)));
	position: relative;
	z-index: 2;
}

.sbx-dialog__content-inner {
	max-height: 80dvh;
	overflow-y: auto;
	overscroll-behavior: contain;
	padding: var(--spacing-32);
}

.sbx-dialog__close-button {
	align-items: center;
  background-color: transparent;
  border-radius: 999px;
  border: 1px solid var(--color--starbucks-accent-green);
  color: var(--color--starbucks-accent-green);
  display: flex;
  font-size: var(--font-size--22);
  height: var(--spacing-24);
  justify-content: center;
  line-height: 1;
  padding: 0;
  position: absolute;
  right: var(--spacing-16);
  top: var(--spacing-16);
  width: var(--spacing-24);
}
