// To Do: refactor this when the group block has support for flex layouts.
.sbx-cs-section-head > .wp-block-group__inner-container {
  display: flex;
  flex-direction: column;

  @media (min-width: $sb-breakpoint-md) {
    flex-direction: row;
    gap: var(--spacing-48);
    justify-content: space-between;
  }
}

.sbx-cs-section-head .wp-block-group,
.sbx-cs-section-head .article__content > * {
  max-width: none;
}

.sbx-cs-section-head__content {
  max-width: var(--w-content-wide) !important;
  margin-left: 0;
}

.sbx-cs-section-head__content .article__content > * + * {
  margin-top: var(--spacing-24);
}

.sbx-cs-section-head__eyebrow {
  @include eyebrowText;

  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sbx-cs-section-head__title {
  color: var(--color--text);
  text-align: left;
  margin-bottom: 0 !important;
  font-weight: var(--font-weight--regular);
}

.sbx-cs-section-head__cta {
  margin: 0;
  min-width: 160px;
}

.sbx-cs-section-head__cta .wp-block-group__inner-container {
  display: flex;
  justify-content: flex-start;

  @media (min-width: $sb-breakpoint-md) {
    justify-content: flex-end;
  }
}

.sbx-cs-section-head__cta .wp-block-button {
  margin: 0;
}