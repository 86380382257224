// To do: verify this is required. If not remove it.
.alignleft {
  @media (min-width: $sb-breakpoint-md) {
    display: inline;
    float: left;
    margin-right: 1.5em;
  }
}

.alignright {
  @media (min-width: $sb-breakpoint-md) {
    display: inline;
    float: right;
    margin-left: 1.5em;
  }
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

[class^='wp-block'] figure {

  &.alignleft {
    margin-top: 0;
    margin-left: 0;
  }

  &.alignleft {
    @media (min-width: $sb-breakpoint-md) {
      float: left;
      max-width: calc(50% - 1.5em);
      margin-left: 0;
      margin-top: 0;
      padding-right: 0;
    }
  }

  &.alignright {
    @media (min-width: $sb-breakpoint-md) {
      float: right;
      max-width: calc(50% - 1.5em);
      margin-right: 0;
      margin-top: 0;
      padding-left: 0;
    }
  }
}
