// Variables
$sb-social-nav-max-width: 15rem;
$sb-social-nav-max-width-footer-md: 13.125rem;
$sb-social-nav-link-icon-size: var(--spacing-24);
$sb-social-nav-link-icon-size-footer: var(--spacing-28);

.social-nav {
  display: block;
  width: 100%;
}

.social-nav__ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  margin-bottom: var(--spacing-base);
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--spacing-base);
  max-width: $sb-social-nav-max-width;
  padding: 0;
  width: 100%;

  .social-nav__li {
    margin: 0 2px;
  }
}

.social-nav__ul--2 {
  justify-content: center;

  .social-nav__li {
    min-width: 63px;
  }
}

.social-nav__link {
  background-color: transparent;
  display: block;
  padding: var(--spacing-4);

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.social-nav__icon {
  height: $sb-social-nav-link-icon-size;
  width: $sb-social-nav-link-icon-size;
}

.social-nav__ul--footer {
  margin: var(--spacing-2x) auto;

  .social-nav__li {
    margin: 0 5px;
    min-width: 0;
  }

  .social-nav__icon {
    background: transparent;
    color: var(--color--white);
    height: $sb-social-nav-link-icon-size-footer;
    padding: var(--spacing-5);
    width: $sb-social-nav-link-icon-size-footer;
  }

  .social-nav__link {
    background: var(--color--black);
    border-radius: 50%;
    transition: background-color $sb-base-transition;

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color--link-hover);
    }
  }

  @media (min-width: $sb-breakpoint-md) {
    max-width: $sb-social-nav-max-width-footer-md;
  }
}
