.sbx-cs-region-archive--africa,
.sbx-cs-region-archive--asia-pacific,
.sbx-cs-region-archive--europe-middle-east,
.sbx-cs-region-archive--latin-america,
.sbx-cs-region-archive--north-america {
  background-position: center clamp(7.938rem, 43.4965vw + -2.937rem, 66.25rem);;
  background-size: contain;
  background-repeat: no-repeat;
}

.sbx-cs-region-archive--africa {
  background-image: url('/images/region-backgrounds/africa.jpg');
}

.sbx-cs-region-archive--asia-pacific {
  background-image: url('/images/region-backgrounds/asia-pacific.jpg');
}

.sbx-cs-region-archive--europe-middle-east {
  background-image: url('/images/region-backgrounds/europe-middle-east.jpg');
}

.sbx-cs-region-archive--latin-america {
  background-image: url('/images/region-backgrounds/latin-america.jpg');
}

.sbx-cs-region-archive--north-america {
  background-image: url('/images/region-backgrounds/north-america.jpg');
}

.sbx-cs-region-archive__grid,
.sbx-cs-region-archive__more {
  margin-top: var(--spacing-48);

  @media (min-width: 992px) {
    margin-top: var(--spacing-96);
  }
}