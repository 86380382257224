.sbx-store-search__form {
  display: flex;
  gap: var(--spacing-16);
  align-items: flex-end;
}

.sbx-store-search__results {
  align-items: start;
  display: grid;
  gap: var(--spacing-32);
  margin-block-start: var(--spacing-32);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.sbx-store-search__map {
  aspect-ratio: 1;
  background-color: var(--color--gray-89);
}

// Main table
.sbx-store-search__table {
  border-collapse: collapse;
  font-size: var(--font-size--16);
}

.sbx-store-search__table tr {
  border-bottom: 1px solid var(--color--gray-89);

  &:hover {
    background-color: var(--color--gray-89);
  }
}

.sbx-store-search__table :is(td, th) {
  padding: var(--spacing-12) var(--spacing-8);
}

.sbx-store-search__table th {
  text-align: left;
}

.sbx-store-search__table :is(td, th):last-child {
  text-align: right;
}

// Store details table inside the modal
.sbx-store-search__store-details {
  border-collapse: collapse;
  font-size: var(--font-size--14);
}

.sbx-store-search__store-details tr + tr {
  border-top: 1px solid var(--color--gray-89);
}

.sbx-store-search__store-details :is(td, th) {
  padding: var(--spacing-12) var(--spacing-8);
}

.sbx-store-search__store-details th {
  text-align: left;
}

.sbx-store-search__details-button {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--color--link);
  line-height: 1.5;
  padding: 0;
  text-decoration: underline
}