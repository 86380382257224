.three-image-layout {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1;
  position: relative;

  img {
    flex-grow: 1;
    width: 100%;
    object-fit: cover;
    grid-row: 1;
    z-index: 0;
  }

  .left-image {
    grid-column: 1 / 5;
  }
  .center-image {
    grid-column: 4 / 10;
    z-index: 1;
  }
  .right-image {
    grid-column: 9 / -1;
  }
}
