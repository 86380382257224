.video-thumbnail {
  &.sb-card-grid-item__image-contain video {
    height: 100%;
    margin-bottom: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* Remove Scale from thumbnail hover */
.sb-card-grid-item__link:hover
  .video-thumbnail.sb-card-grid-item__image-contain {
  transform: scale(1);
}

.video-thumbnail__hero {
  padding-top: $article-hero-ratio;
  position: relative;

  @media (min-width: $sb-breakpoint-md) {
    padding-top: $article-hero-ratio-lg;
  }

  video {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.post-featured__link,
.sb-card-grid-item__link {
  &:hover,
  &:focus {
    .video-thumbnail__controls {
      display: block;
    }
  }
}

.video-thumbnail__controls {
  background: rgba(var(--color--starbucks-green), 0.8);
  border: 0;
  bottom: var(--spacing-20);
  display: none;
  height: var(--spacing-50);
  position: absolute;
  right: var(--spacing-50);
  width: var(--spacing-50);
  z-index: 100;

  &:hover {
    cursor: pointer;
  }
}

.sb-card-grid-item__link .video-thumbnail__controls {
  right: var(--spacing-20);
  bottom: var(--spacing-20);
}
