.product-recommend {
  position: relative;
  box-shadow: $sb-box-shadow;

  @media (min-width: $sb-breakpoint-lg) {
    display: flex;
  }
}

.product-recommend__image {
  aspect-ratio: 1 / 1;
  background: var(--color--hint-of-red);
  position: relative;

  @media (min-width: $sb-breakpoint-lg) {
    aspect-ratio: auto;
    flex-basis: 30%;
    flex-grow: 0;
    min-height: 320px;
    min-width: 320px;
  }
}

.product-recommend__title {
  font-family: var(--font-family--lander);
  margin: 0;
  text-align: left;
}

.product-recommend__title a {
  color: var(--color-black);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.product-recommend__content {
  padding: var(--spacing-32) var(--spacing-24);

  @media (min-width: $sb-breakpoint-lg) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--spacing-48);
  }
}

.product-recommend__content > * + * {
  margin-top: var(--spacing-32);
}

.product-recommend__description {
  position: relative;
}

.product-recommend__description:where(p) {
  margin: 0;
}

.product-recommend__description > * + * {
  margin-top: var(--spacing-24);
}
