.term-hero__image {
  margin: 0 auto;
  aspect-ratio: 16 / 9;
  position: relative;
}

.term-hero__main {
  margin: 0 auto;
  text-align: center;
}

.term-hero__title {
  color: var(--color--white);
  font-family: var(--font-family--lander);
  line-height: 1.18;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.term-hero__subtitle {
  color: var(--color--white);
  font-family: var(--font-family--lander);
  font-size: 1.5rem;
  font-weight: var(--font-weight--regular);
  line-height: 1.4;
  margin: var(--spacing-10) 0 0;
  text-align: center;
}

.term-hero__count {
  color: rgba(255, 255, 255, 0.56);
  font-weight: var(--font-weight--light);
  margin-top: var(--spacing-12);
}

.term-hero__tools {
  margin-top: var(--spacing-24);
}

.term-hero__description {
  @include responsive-text-size(var(--font-size--16), var(--spacing-22));
  color: rgba(255, 255, 255, 0.56);
  display: block;
  font-weight: var(--font-weight--light);
  line-height: 1.4;
  margin-top: var(--spacing-30);
  text-align: center;
  width: 100%;
}

.term-hero--blog {
  .term-hero__main {
    background: var(--color--white);
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.18);
    margin-top: calc(-1 * var(--spacing-20));
    padding-block: var(--spacing-50);
    position: relative;
    text-align: center;

    @media (min-width: $sb-breakpoint-sm) {
      margin-top: -12.5rem;
    }

    @media (min-width: $sb-breakpoint-md) {
      margin-inline: auto;
      padding-block: var(--spacing-48) var(--spacing-80);
    }

    @media (min-width: $sb-breakpoint-lg) {
      margin-top: calc(-1 * var(--spacing-100));
    }
  }

  .term-hero__title,
  .term-hero__subtitle {
    color: var(--color--black);
  }

  .term-hero__description {
    @include responsive-font-weight;

    color: var(--color--text);
    margin-bottom: 0;
    margin-inline: auto;
    max-width: var(--w-content-normal);
    padding-inline: var(--spacing-24);
    text-align: left;
    white-space: pre-line;
  }
}
