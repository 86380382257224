.post-featured {
  display: block;
  margin: 0 auto;
  max-width: $sb-max-width;
}

.post-featured__title-contain {
  display: block;
  overflow: hidden;
  text-align: center;
}

.post-featured__meta {
  display: block;
  font-size: var(--font-size--16);
  font-weight: var(--font-weight--semibold);
  letter-spacing: 0.29em;
  margin-bottom: calc(-#{var(--spacing-base)} + #{var(--spacing-14)});
  margin-top: var(--spacing-base);
  overflow: hidden;
  text-transform: uppercase;
  width: 100%;

  img,
  svg {
    display: block;
    margin: 0 auto;
    max-height: var(--spacing-30);
    max-width: 9.375rem;
    width: 100%;
  }

  img {
    filter: brightness(100);
  }

  .image-to-svg--replaced,
  svg {
    @include svg-fill-override(var(--color--white));
  }
}

.post-featured__link {
  display: block;
  position: relative;

  .article-hero {
    border: $sb-border-width-focus inset transparent;
  }

  &:focus {
    outline: none;

    .article-hero {
      border-color: var(--color--gray-25);
    }
  }
}

.post-featured__link .article-hero__image-contain {
  transform: scale(1);
  transition: $post-grid-item-image-transition;
  will-change: transform;
}

.post-featured .article-hero__image-contain {
  transform: scale(1.1);
}

.post-featured.-viewed .article-hero__image-contain {
  transform: scale(1);
}

.post-featured__link:hover .article-hero__image-contain {
  transform: scale(1.1);
}

.article-hero + .post-featured__title-contain,
.post-featured__video .post-featured__title-contain {
  background: transparent;
  bottom: 0;
  color: var(--color--white);
  left: 50%;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  transform: translateX(-50%);
}

.post-featured__title {
  font-weight: var(--font-weight--medium);
  @media (max-width: $sb-breakpoint-sm) {
    font-size: 1.5rem;
    margin-bottom: var(--spacing-2halfx);
  }
}

// List / Grid
.sb-posts-featured__grid-wrap {
  @media (min-width: $sb-breakpoint-lg) {
    padding-top: var(--spacing-base);
  }
}

.post-featured__video {
  display: block;
}
