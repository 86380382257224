html.no-scroll,
body.no-scroll {
  overflow: hidden;

  -ms-touch-action: none;
  touch-action: none;
}

html {
  line-height: 1.5;
}
