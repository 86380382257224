// Use this flag to toggle the CSS & JS for traditional "large screen" horizontal navigation
$use-large-screen: true !default;

// Variables
$nav-drawer-bg: 576px;
$nav-drawer-width: 24.375rem;
$nav-drawer-padding: var(--spacing-32) 2.812rem 0 var(--spacing-52);
$nav-primary-link-font-weight: var(--font-weight--medium);
$nav-primary-link-font-size: 2.125rem;
$nav-primary-link-color: var(--color--black);
$nav-primary-link-line-height: 1.2;
$nav-primary-link-border: 5px solid transparent;
$nav-primary-link-border-desktop: inset 5px solid transparent;
$nav-primary-item-margin: 0 0 var(--spacing-1halfx) 0;
$nav-subnav-width: 13.125rem;
$nav-subnav-margin: var(--spacing-24) 0 0 var(--spacing-14);
$nav-subnav-link-font-weight: var(--font-weight--light);
$nav-subnav-link-font-size: 1.625rem;
$nav-subnav-link-color: var(--color--gray-16);
$nav-subnav-item-margin: 0 0 var(--spacing-24) 0;
$nav-inner-background: rgba(0, 0, 0, 0.6);
$sb-subnav-box-shadow: 0 var(--spacing-4) var(--spacing-4) rgba(0, 0, 0, 0.1);

// After Break
$nav-primary-link-padding: var(--spacing-16) var(--spacing-1halfx) var(--spacing-16) var(--spacing-10);

// Keyframe Animations
@keyframes navBtnTopOpenTranslateMobile {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(4px);
  }
}

@keyframes navBtnBotOpenTranslateMobile {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

@keyframes navBtnTopCloseTranslateMobile {
  0% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes navBtnBotCloseTranslateMobile {
  0% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}

.site-navigation {
  &:focus {
    outline: $sb-border-width-focus solid var(--color--gray-25);
  }

  ul {
    list-style: none;
    padding: 0;
  }
}

.site-navigation--desktop {
  @if $use-large-screen == true {
    width: 100%;
  }
}

.site-menu-toggle {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--color--shuttle-gray);
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: var(--spacing-3halfx);
  justify-content: center;
  left: 0;
  line-height: 40px;
  margin: 0;
  padding: 0;
  position: absolute;
  text-decoration: none;
  top: 0;
  width: var(--spacing-3halfx);

  .site-menu-toggle__svg-wrap {
    display: block;
    height: 19px;
    margin: 0 auto;
    transform: rotate(0deg);
    transition: transform 250ms cubic-bezier(0.22, 0.89, 0.57, 1) 200ms;
    width: 20px;
  }

  .sb-icon {
    height: 5px;
    margin: 3px 0;
    max-width: 20px;
    transform-origin: center;
    transition: none;
    width: 100%;
  }

  .top-line {
    animation: 200ms cubic-bezier(0.22, 0.89, 0.57, 1) forwards
      navBtnTopCloseTranslateMobile;
  }

  .bottom-line {
    animation: 200ms cubic-bezier(0.22, 0.89, 0.57, 1) forwards
      navBtnBotCloseTranslateMobile;
  }

  &[aria-expanded='true'] {
    .site-menu-toggle__svg-wrap {
      transform: rotate(135deg);
    }

    .top-line {
      animation: 200ms cubic-bezier(0.22, 0.89, 0.57, 1) forwards
        navBtnTopOpenTranslateMobile;
    }

    .bottom-line {
      animation: 200ms cubic-bezier(0.22, 0.89, 0.57, 1) forwards
        navBtnBotOpenTranslateMobile;
    }
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  &:focus {
    border: $sb-border-width-focus solid var(--color--gray-25);
  }

  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      display: none;
    }
  }
}

.site-navigation__inner {
  bottom: 0;
  clear: both;
  display: block;
  height: calc(100% - var(--spacing-70));
  left: 0;
  overflow: hidden;
  position: fixed;
  transition: visibility 0s linear 0.3s;
  visibility: hidden;
  width: 100%;

  &[aria-hidden='false'],
  &:target {
    opacity: 1;
    transition: visibility 0s;
    visibility: visible;
    .site-navigation__drawer {
      transform: translateX(0%);
    }

    .site-navigation__close {
      opacity: 1;
      transition: opacity 0.3s ease-out, visibility 0s;
      visibility: visible;
    }
  }

  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      background: transparent;
      display: block;
      height: auto;
      overflow: visible;
      position: relative;
      top: 0;
      transform: translateX(0);
      visibility: visible;
      width: auto;
    }
  }
}

.site-navigation__drawer {
  background-color: var(--color--white);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: scroll;
  padding: $nav-drawer-padding;
  position: relative;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  width: 100%;
  will-change: transform;
  z-index: 10;

  -webkit-overflow-scrolling: touch;

  @media (min-width: $nav-drawer-bg) {
    max-width: $nav-drawer-width;
  }

  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      display: flex;
      flex-flow: row nowrap;
      max-width: 100%;
      overflow: visible;
      padding: 0;
      transform: translateX(0%);

    }
  }
}

.site-navigation__close {
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  height: calc(100% - #{var(--spacing-3halfx)});
  left: 0;
  opacity: 0;
  position: fixed;
  top: var(--spacing-3halfx);
  transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  visibility: hidden;
  width: 100%;

  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      display: none;
    }
  }
}

.site-navigation__global-search {
  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      margin-left: auto;
    }
  }
}

.site-navigation__footer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-top: auto;

  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      align-items: center;
      flex-flow: row nowrap;
      justify-content: flex-end;
      margin-left: auto;
      margin-top: 0;

      @media (min-width: $sb-breakpoint-xl) {
        width: 18.75rem;
      }
    }
  }
}

.primary-menu {
  margin: 0;
  margin-bottom: var(--spacing-10);
  text-align: left;

  .menu-item {
    margin: $nav-primary-item-margin;

    @media (min-width: $sb-breakpoint-lg) {
      @if $use-large-screen == true {
        display: inline-block;
        margin: 0;
        position: relative;
        text-align: left;
      }
    }

    button {
      display: none;
    }

    a {
      border-bottom: $nav-primary-link-border;
      color: $nav-primary-link-color;
      display: block;
      font-size: $nav-primary-link-font-size;
      font-weight: $nav-primary-link-font-weight;
      line-height: $nav-primary-link-line-height;
      position: relative;
      transition: border-bottom $sb-base-transition;
      z-index: 10;

      * {
        pointer-events: none;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none;
        text-decoration: none;
      }

      &:focus {
        border-bottom-color: transparent;
        outline: $sb-border-width-focus solid var(--color--gray-25);
        outline-offset: $sb-border-width-focus * -1;

        &::after {
          display: none !important;
        }
      }

      @media (min-width: $sb-breakpoint-lg) {
        @if $use-large-screen == true {
          align-items: center;
          background: var(--color--white);
          border-bottom: $nav-primary-link-border-desktop;
          display: flex;
          flex-flow: row nowrap;
          font-size: 0.812rem;
          font-weight: var(--font-weight--semibold);
          height: var(--spacing-4halfx);
          letter-spacing: 1.3px;
          margin: 0;
          padding: $nav-primary-link-padding;
          text-transform: uppercase;
          transition: color $sb-base-transition;
          z-index: 10;

          &:hover,
          &:active,
          &:focus {
            color: var(--color--starbucks-accent-green);
          }
        }
      }
    }

    .menu-item-link__icon-contain {
      bottom: -0.2em;
      display: inline-block;
      height: 1em;
      margin-left: 0.15em;
      position: relative;
      width: 1em;

      .site-navigation--desktop & {
        @if $use-large-screen == true {
          bottom: 0;
          height: 0.4em;
          margin-left: 0.594rem;
          width: 0.75em;
        }
      }
    }

    .menu-item-link__icon {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(0deg);
      transition: transform 0.3s ease-out, opacity 0.15s ease-out;
      width: 100%;

      @media (min-width: $sb-breakpoint-lg) {
        @if $use-large-screen == true {
          transition: transform 0s, opacity 0s;
        }
      }
    }

    .menu-item-link__icon--open {
      opacity: 1;
    }

    .menu-item-link__icon--close {
      opacity: 0;
    }

    &.child-has-focus {
      position: relative;

      .menu-item-link__icon {
        transform: rotate(180deg);
      }

      .menu-item-link__icon--open {
        opacity: 0;
      }

      .menu-item-link__icon--close {
        opacity: 1;
      }
    }

    &:hover,
    &:focus {
      .menu-item-link__icon {
        @media (min-width: $sb-breakpoint-lg) {
          @if $use-large-screen == true {
            fill: var(--color--link);
            transform: rotate(180deg);
          }
        }
      }

      > .sub-menu {
        @media (min-width: $sb-breakpoint-lg) {
          @if $use-large-screen == true {
            display: block;
            transition: max-height 0.2s ease-out, visibility 0s 0s;
            visibility: visible;
          }
        }
      }
    }
  }
  // .menu-item
  &.uses-click {
    .menu-item {
      &:hover {
        .sub-menu {
          display: none;

          &[aria-hidden='false'] {
            display: block;
          }
        }
      }
    }
  }

  .sub-menu {
    margin: 0;
    max-height: 0;
    min-width: $nav-subnav-width;
    overflow: hidden;
    position: relative;
    transition: max-height 0.2s ease-out, visibility 0s 0.2s;
    visibility: hidden;
    width: 100%;
    will-change: max-height;
    z-index: 10;

    .menu-item {
      margin: $nav-subnav-item-margin;
    }

    a {
      color: $nav-subnav-link-color;
      font-size: $nav-subnav-link-font-size;
      font-weight: $nav-subnav-link-font-weight;
    }

    @media (min-width: $sb-breakpoint-lg) {
      @if $use-large-screen == true {
        background: var(--color--white);
        box-shadow: $sb-subnav-box-shadow;
        display: none;
        left: calc(-1 * var(--spacing-22));
        margin: 0;
        max-height: none;
        padding-bottom: var(--spacing-16);
        position: absolute;
        width: $nav-subnav-width;
        z-index: -2;

        .menu-item {
          display: block;
          margin: 0;
        }

        a {
          border: none;
          font-weight: var(--font-weight--regular);
          font-size: var(--font-size--14);
          line-height: 1.3;
          height: auto;
          padding-left: var(--spacing-32);
          text-transform: none;

          &:after {
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            left: var(--spacing-32);
            position: absolute;
            right: var(--spacing-32);
          }
        }
      }
    }
  }
  // .sub-menu
  .submenu-content {
    padding: $nav-subnav-margin;

    @media (min-width: $sb-breakpoint-lg) {
      @if $use-large-screen == true {
        padding: 0;
      }
    }
  }

  .child-has-focus > .sub-menu {
    display: block;
    max-height: 800px;
    transition: max-height 0.2s ease-out, visibility 0s 0s;
    visibility: visible;
  }

  @media (min-width: $sb-breakpoint-lg) {
    @if $use-large-screen == true {
      display: flex;
      flex-flow: row nowrap;
      margin: 0;
    }
  }
}
