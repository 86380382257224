.story-stick {
  align-items: flex-end;
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: none;
  pointer-events: none;

  & .flyout-post--visible,
  & .sb-social-share {
    pointer-events: auto;
  }

  & > div,
  & .story-stick--sticky {
    width: 100%;
  }
}

.story-stick--sticky__wrap {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-inline: auto;
  max-width: $sb-article-content-max-width;
  width: 100%;
  padding-bottom: var(--spacing-24);
}