.sbx-translated-content__header {
  margin-bottom: var(--spacing-24);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
}

.sbx-translated-content__toggle[aria-hidden="true"],
.sbx-translated-content__item[aria-hidden="true"] {
  display: none;
}

.sbx-translated-content__header--center {
  display: flex;
  justify-content: center;
}

.sbx-translated-content__header[aria-hidden="true"],
.sbx-translated-content__inner[aria-hidden="true"] {
  display: none;
}