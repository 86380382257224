$article-z-index: 10;

.article {
  background-color: var(--color--white);
  margin: auto;
  z-index: $article-z-index;
}

.content {
  background-color: var(--color--white);
  padding-bottom: var(--spacing-3halfx);
  position: relative;
}

.content--article {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  width: 100%;
  z-index: 5;
}
